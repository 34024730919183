import { BaseModel } from '../core/_base/crud';


export class ActionLogModel extends BaseModel {
    actionLogId: number;
    userId: number;
    objectId: number;
    userName: number;
    objectActionName: string;
    objectName: number;
    objectType: number;
    actionType: number;
    logDate: string;
    isActive: number;
    notes: string;

    clear() {
        this.actionLogId = 0;        
        this.userId=0;
        this.objectId = 0;
        this.userName = 0;
        this.objectActionName = '';
        this.objectName = 0;
        this.objectType = 0;  
        this.actionType = 0;  
        this.logDate = '';
        this.isActive = 0;  
        this.notes = '';
    }
}
