import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../_reducers';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SendEmailModel } from '../../order-to-cash';
import { MessageTemplateModel } from '..';

// Real REST API
@Injectable()
export class MessageTemplateService extends MaytechService {
    API_URL_FOR_BLOCK: string = '';

    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null) {
            this.API_URL = tenantService.currentClient.apiurl + '/messagetemplate';
            this.API_URL_FOR_BLOCK = tenantService.currentClient.apiurl;
        }
    }

    getLatestId(objectType: string): Observable<any> {
        const url = this.API_URL + "/GetLatestId/" + objectType;

        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getLatestId", []))
        );
    }
    getListStoredProcedureName(): Observable<any> {
        const url = this.API_URL + "/GetListStoredProcedureName";

        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getListStoredProcedureName", []))
        );
    }
    getTemplateContent(messTplId: number, objId: number, isAttachmentContent: boolean = false): Observable<any> {
        const url = this.API_URL + "/GetTemplateContent/" + messTplId.toString() + "/" + objId.toString() + "/" + isAttachmentContent;

        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getTemplateContent", []))
        );
    }
    getAttachmentPdf(messTplId: number, objId: number): Observable<any> {
        const options = { responseType: 'blob' as 'json' };
        const url = this.API_URL + "/GetAttachmentPdf/" + messTplId.toString() + "/" + objId.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<Blob>(url, { headers: httpHeaders, observe: 'response', responseType: 'blob' as 'json' }).pipe( catchError(this.handleError("getPdfTemplateContent", [])));
       // return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getTemplateContent", []))   );
    }
    getOrderSummaryBlock(apiUrl: string, code: string): Observable<any> {
        const url = this.API_URL_FOR_BLOCK + apiUrl + "/" + code;
       // const options = { responseType: 'blob' as 'json' };
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<any>(url, { headers: httpHeaders}).pipe(catchError(this.handleError("getOrderSummaryBlock", []))
        );
    }

    // READ
    getBookingEmailTemplateSelectList(): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/GetBookingEmailTemplateSelectList`;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders
        }).pipe(catchError(this.handleError("getBookingEmailTemplateSelectList", []))
        );
    }

    postSendEmail(obj: SendEmailModel): Observable<SendEmailModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/PostSendEmail`;
        return this.http.post<string>(url, obj, { headers: httpHeaders })
            .pipe(catchError(this.handleError("postSendEmail", []))
            );
    }

    printBookingTemplateContent(messTplId: number, objId: number, isSavePdf: boolean): Observable<any> {
        const url = this.API_URL + "/PrintBookingTemplateContent/" + messTplId.toString() + "/" + objId.toString() + "/" + isSavePdf.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<any>(url, { headers: httpHeaders });
    }

    getProductCertificateTemplate(productId: number): Observable<any> {
        const url = this.API_URL + "/GetProductCertificateTemplate/" + productId.toString() ;

        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("GetProductCertificateTemplate", []))
        );
    }

  
    addUpdateProductCertificateTemplate(obj: MessageTemplateModel, productId: number, catchErr = true): Observable<number> {
        const url = this.API_URL + "/AddUpdateProductCertificateTemplate/" + productId.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        if (catchErr) {
            return this.http.post<number>(url, obj, { headers: httpHeaders }).pipe(catchError(this.handleError("createObject", []))
            );
        }
        return this.http.post<number>(url, obj, { headers: httpHeaders });
    }

}
