import { OnInit, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GroupPriceType } from '../../../../../core/_utils/define';
import { EditRequestModel } from '../../../../../models/editrequest.model';
import { EditRequestService, CurrencyModel, CurrencyService } from '../../../../../core/setting';
import { LayoutUtilsService, AlertMessageType } from '../../../../../core/_base/crud';
import { ObjectPriceGroupListModel } from '../../../../../models/objectpricegrouplist.model';


@Component({
    selector: 'mt-ref-prices-dialog',
    templateUrl: './ref-prices-dialog.component.html'
})
export class RefPricesDialogComponent implements OnInit {
    refPrices: ObjectPriceGroupListModel;
    currencyList: CurrencyModel[] = [];

    constructor(
        public dialogRef: MatDialogRef<RefPricesDialogComponent>,
        private translate: TranslateService,
        private editRequestService: EditRequestService,
        private layoutUtilsService: LayoutUtilsService,
        public objectFB: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private currencyService: CurrencyService) {
        this.refPrices = data.refPrices;
        this.currencyList = data.currencyList;
        //console.log("RefPricesDialogComponent");
        //console.log(data);
        //console.log(data.currencyList);
    }

    ngOnInit() {
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }
    get groupPriceType() { return GroupPriceType; }
}
