export class MenuConfig {
    public defaults: any = {
        header: {
            self: {},
            items: []
        },
        aside: {
            self: {},
            items: [
                {
                    title: 'Booking',
                    root: true,
                    icon: 'material-icons',
                    mat: 'book',
                    alignment: 'left',
                    page: '/order/booking',
                    permission: 'CanReadBooking',
                    translate: 'MENU.BOOKING',
                },
                {
                    title: 'Trip',
                    root: true,
                    icon: 'material-icons',
                    mat: 'tour',
                    alignment: 'left',
                    permission: 'CanAccessTripModule',
                    translate: 'MENU.TRIP',
                    submenu: [
                        //{
                        //    title: 'Trips',
                        //    page: '/schedule/trip',
                        //    permission: 'CanReadTrip',
                        //    translate: 'MENU.TRIP_LIST',
                        //},
                        {
                            title: 'Trips',
                            page: '/product-management/session',
                            permission: 'CanReadSession',
                            translate: 'MENU.SCHEDULE_SESSION',
                        },
                        {
                            title: 'Calendar',
                            page: '/schedule/calendar',
                            permission: 'CanReadCalendar',
                            translate: 'MENU.SCHEDULE_CALENDAR',
                        },
                        {
                            title: 'Trip Details',
                            page: '/schedule/manifest',
                            permission: 'CanReadSession',
                            translate: 'MENU.MANIFEST',
                        },
                        {
                            title: 'Certificate review',
                            page: '/thetrip/certificate-review',
                            permission: 'CanUpdateCertificate',
                            translate: 'MENU.CERTIFICATE_REVIEW',
                        },
                        {
                            title: 'Assign trip',
                            page: '/schedule/assign-trip',
                            permission: 'CanAccessAssignTripModule',
                            translate: 'MENU.ASSIGN_TRIP',
                        },
                        {
                            title: 'Approved schedule',
                            page: '/schedule/approved-schedule',
                            permission: 'CanAccessApprovedScheduleModule',
                            translate: 'MENU.APPROVED_SCHEDULE',
                        },
                        //{
                        //    title: 'Session',
                        //    page: '/product-management/session',
                        //    permission: 'CanReadSession',
                        //    translate: 'MENU.SCHEDULE_SESSION',
                        //},
                    ]
                },
                {
                    title: 'Customer',
                    root: true,
                    icon: 'material-icons',
                    mat: 'insert_emoticon',
                    alignment: 'left',
                    page: '/order/customer',
                    permission: 'CanReadCustomer',
                    translate: 'MENU.CUSTOMER',
                },
                {
                    title: 'Report',
                    root: true,
                    icon: 'material-icons',
                    mat: 'insert_chart',
                    alignment: 'left',
                    permission: 'CanAccessReportModule',
                    translate: 'MENU.REPORT',
                    submenu: [
                        {
                            title: 'Participant',
                            page: '/report/participant',
                            permission: 'CanReadReport',
                            translate: 'MENU.REPORT_PARTICIPANT'
                        },
                        {
                            title: 'AutoExport',
                            page: '/report/auto-export',
                            permission: 'CanReadAutoExport',
                            translate: 'MENU.REPORT_AUTOEXPORT'
                        }
                    ]
                },
                {
                    title: 'EditRequest',
                    root: true,
                    icon: 'material-icons',
                    mat: 'gavel',
                    alignment: 'left',
                    permissions: {
                        only: ['CanApproveEditBooking', 'CanApproveEditSession', 'CanApproveEditProduct', 'CanApproveEditResourceCat']
                    },
                    translate: 'MENU.APPROVE_EDIT',
                    submenu: [
                        {
                            title: 'RequestList',
                            page: '/setting/edit-request-list',
                            permissions: {
                                only: ['CanApproveEditBooking', 'CanApproveEditSession', 'CanApproveEditProduct', 'CanApproveEditResourceCat']
                            },
                            translate: 'MENU.APPROVE_LIST'
                        },
                        {
                            title: 'RequestHistory',
                            page: '/setting/edit-request-history',
                            //permission: ['CanApproveEditBooking'],
                            permissions: {
                                only: ['CanApproveEditBooking', 'CanApproveEditSession', 'CanApproveEditProduct', 'CanApproveEditResourceCat']
                            },
                            translate: 'MENU.APPROVE_HISTORY',
                        }
                    ]
                },
                //{
                //    title: 'EditRequest',
                //    root: true,
                //    icon: 'material-icons',
                //    mat: 'gavel',
                //    alignment: 'left',
                //    page: '/setting/edit-request',
                //    permission: ['CanApproveEditBooking'],
                //    //permission: ['CanApproveEditBooking', 'CanApproveEditSession', 'CanApproveEditProduct', 'CanApproveEditResourceCat'],
                //    translate: 'MENU.APPROVE_EDIT',
                //},
                {
                    section: 'Settings',
                    translate: 'MENU.SETTING',
                    permission: ['CanAccessProductModule', 'CanAccessResourceModule', 'CanReadWidget', 'CanReadLegal', 'CanReadMessageTpl', 'CanReadPaymentSetting'],
                },
                {

                    title: 'Products',
                    root: true,
                    icon: 'material-icons',
                    mat: 'landscape',
                    alignment: 'left',
                    permission: 'CanAccessProductModule',
                    translate: 'MENU.PRODUCT',
                    submenu: [
                        {
                            title: 'Products',
                            page: '/product-management/product',
                            permission: 'CanReadProduct',
                            translate: 'MENU.PRODUCT_LIST'
                        },
                        {
                            title: 'Categories',
                            page: '/product-management/category',
                            permission: 'CanReadProductCate',
                            translate: 'MENU.PRODUCT_CATEGORY',
                        },
                        {
                            title: 'Booking Fields',
                            page: '/product-management/bookingfield',
                            permission: 'CanReadBookingField',
                            translate: 'MENU.TOURBOOKINGFIELD',
                        },
                        {
                            title: 'Services',
                            page: '/product-management/service',
                            permission: 'CanReadService',
                            translate: 'MENU.SERVICE',
                        },
                        {
                            title: 'Widget',
                            page: '/setting/widget',
                            permission: 'CanReadWidget',
                            translate: 'MENU.WIDGET',
                        },
                        {
                            title: 'Certificate Template',
                            page: '/setting/certificatetemplate',
                            permission: 'CanEditCertificateTemplate',
                            translate: 'MENU.CERTIFICATE_TEMPLATE',
                        },
                        //{
                        //    title: 'Promotions',
                        //    permission: 'CanReadPromotion',
                        //    translate: 'MENU.PROMOTION',
                        //}
                    ]
                },
                {
                    title: 'Resources',
                    root: true,
                    icon: 'material-icons',
                    mat: 'account_balance',
                    alignment: 'left',
                    permission: 'CanAccessResourceModule',
                    translate: 'MENU.RESOURCE',
                    submenu: [
                        {
                            title: 'Person',
                            page: '/resource-management/person',
                            permission: 'CanReadResourceCat',
                            translate: 'MENU.PERSON',
                        },
                        {
                            title: 'Asset',
                            page: '/resource-management/asset',
                            permission: 'CanReadResourceCat',
                            translate: 'MENU.ASSET',
                        },
                        {
                            title: 'Resource Category',
                            page: '/resource-management/resourcecategory',
                            permission: 'CanReadResourceCat',
                            translate: 'MENU.RESOURCE_CATEGORY',
                        },
                        {
                            title: 'Resource Info Field',
                            page: '/resource-management/resourceinfofield',
                            permission: 'CanReadResourceCat',
                            translate: 'MENU.RESOURCE_INFO_FIELD',
                        },
                    ]
                },
                {
                    title: 'Legal',
                    root: true,
                    icon: 'material-icons',
                    mat: 'policy',
                    alignment: 'left',
                    page: '/setting/legal',
                    permission: 'CanReadLegal',
                    translate: 'MENU.LEGAL',
                },
                {
                    title: 'Message Template',
                    root: true,
                    icon: 'material-icons',
                    mat: 'email',
                    alignment: 'left',
                    page: '/setting/messagetemplate',
                    permission: 'CanReadMessageTpl',
                    translate: 'MENU.MESSAGE_TEMPLATE',
                },
                {
                    title: 'Payment Settings',
                    root: true,
                    icon: 'material-icons',
                    mat: 'receipt_long',
                    alignment: 'left',
                    page: '/setting/payment',
                    permission: 'CanReadPaymentSetting',
                    translate: 'MENU.PAYMENT_SETTING',
                },
                {
                    section: 'System',
                    translate: 'MENU.SYSTEM',
                    permission: ['CanAccessGeneralOptionModule', 'CanAccessUserManModule', 'CanAccessLogDetailModule'],
                },
                {
                    title: 'General option',
                    root: true,
                    icon: 'material-icons',
                    mat: 'settings',
                    permission: 'CanAccessGeneralOptionModule',
                    translate: 'MENU.GENERAL_OPTION',
                    submenu: [
                        {
                            title: 'General',
                            page: '/setting/general',
                            permission: 'CanReadGeneralSetting',
                            translate: 'MENU.GENERAL',
                        },
                        {
                            title: 'SupportTicket',
                            page: '/setting/supportticket',
                            permission: 'CanReadSupportTicket',
                            translate: 'MENU.SUPPORTTICKET',
                        },
                        {
                            title: 'Context Help',
                            page: '/setting/contexthelp',
                            permission: 'CanReadContextHelp',
                            translate: 'MENU.CONTEXTHELP',
                        },
                        {
                            title: 'ShortCodeBlock',
                            page: '/setting/shortcodeblock',
                            permission: 'CanReadShortcode',
                            translate: 'MENU.SHORTCODEBLOCK',
                        },
                        {
                            title: 'CronJob',
                            page: '/setting/cronjob',
                            permission: 'CanReadCronjob',
                            translate: 'MENU.CRONJOB',
                        },
                        // {
                        //     title: 'Currency',
                        //     page: '/setting/currency',
                        //     permission: 'CanReadCurrency',
                        //     translate: 'MENU.CURRENCY',
                        // },
                        {
                            title: 'TaxFee',
                            page: '/setting/taxfee',
                            permission: 'CanReadTaxFee',
                            translate: 'MENU.TAXFEE',
                        },
                        {
                            title: 'Tenant',
                            page: '/setting/tenant',
                            permission: 'CanReadTenant',
                            translate: 'MENU.TENANT',
                        },
                    ]
                },
                {
                    title: 'User Management',
                    root: true,
                    icon: 'material-icons',
                    mat: 'account_box',
                    //permission: 'CanAccessUserManModule',
                    translate: 'MENU.USER_MANAGEMENT',
                    submenu: [
                        {
                            title: 'Profile',
                            page: '/setting/profile',
                            translate: 'MENU.PROFILE',
                        },
                        {
                            title: 'Users',
                            page: '/user-management/users',
                            permission: 'CanReadUser',
                            translate: 'MENU.USER_LIST',
                        },
                        {
                            title: 'Roles',
                            page: '/user-management/roles',
                            permission: 'CanReadRole',
                            translate: 'MENU.ROLE',
                        },
                    ]
                },
                {
                    title: 'Log Management',
                    root: true,
                    icon: 'material-icons',
                    mat: 'restore',
                    permission: 'CanAccessLogDetailModule',
                    translate: 'MENU.LOG_DETAIL',
                    submenu: [
                        {
                            title: 'Action Log',
                            page: '/setting/actionlog',
                            permission: 'CanReadActionLog',
                            translate: 'MENU.ACTION_LOG',
                        },
                        {
                            title: 'Message Log',
                            page: '/setting/messagelog',
                            permission: 'CanReadMessageLog',
                            translate: 'MENU.MESSAGE_LOG',
                        },
                    ]
                },
                //{
                //    section: 'Operation',
                //    translate: 'MENU.OPERATION',
                //    permission: ['CanAccessTripModule', 'CanAccessPropertyModule', 'CanAccessStaffModule'],
                //},
                //{
                //    title: 'Property',
                //    root: true,
                //    icon: 'material-icons',
                //    mat: 'monetization_on',
                //    permission: 'CanAccessPropertyModule',
                //    translate: 'MENU.PROPERTY',
                //    submenu: [
                //    ]
                //},
                //{
                //    title: 'Location',
                //    root: true,
                //    icon: 'flaticon2-location',
                //    alignment: 'left',
                //    page: '/product-management/location',
                //    permission: 'CanReadLocation',
                //    translate: 'MENU.LOCATION',
                //},
                //{
                //    section: 'Schedule',
                //    permission: 'CanAccessScheduleModule',
                //    translate: 'MENU.SCHEDULE',
                //},
                //{
                //    title: 'Dictionary',
                //    page: '/setting/dictionary',
                //    permission: 'CanReadDictionary',
                //    translate: 'MENU.DICTIONARY',
                //},
            ]
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
