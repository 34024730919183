import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HttpUtilsService, BaseModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../_reducers';
import { ObjectType, HttpAPICallType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';

import { MetadataErrorAction } from '../_actions/metadata.actions';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Real REST API
@Injectable()
export class MetadataService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {
        super(http, httpUtils, route, store, tenantService);
        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/metadata';
    }

    getColumns(listname: string): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/GetColumns/${listname}`;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders
        }).pipe(catchError(this.handleError("getColumns", []))
        );

    }

    getMetaDataValue(key: string): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/GetMetaDataValue/${key}`;
        return this.http.get<any>(url, { headers: httpHeaders })
            .pipe(catchError(this.handleError("getMetaDataValue", []))
            );
    }

    saveColumns(listname: string, objectIds: number[] = []): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/SaveColumns/${listname}`;
        return this.http.post<QueryResultsModel>(url, { objectIds: objectIds }, { headers: httpHeaders }).pipe(catchError(this.handleError("saveColumns", []))
        );
    }

    getErrorDispatcher(error: any, objectType: ObjectType = ObjectType.Any, httpAPICallType: HttpAPICallType = HttpAPICallType.GetList): MaytechBaseAction {
        return new MetadataErrorAction(this.actionPrefix, {
            errorMessage: error.message,
            errorCode: error.status

        });
    }

    getShowBookingFieldColumns(): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/getShowBookingFieldColumns/`;
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
    }

    saveBookingFieldColumns(objectIds: any[] = []): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/saveBookingFieldColumns`;
        return this.http.post<QueryResultsModel>(url, objectIds, { headers: httpHeaders });
    }

    saveManifestGroupBy(groupBy: BaseModel): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/saveManifestGroupBy`;
        return this.http.post<QueryResultsModel>(url, groupBy, { headers: httpHeaders });
    }

    getColumnsInTripParticipant(): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/getColumnsInTripParticipant/`;
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
    }

    getGroupByInTripParticipant(): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/getGroupByInTripParticipant/`;
        return this.http.get<any>(url, { headers: httpHeaders });
    }

    saveColumnsInTripParticipant(objectIds: number[] = []): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/saveColumnsInTripParticipant`;
        return this.http.post<QueryResultsModel>(url, { objectIds: objectIds }, { headers: httpHeaders });
    }

    saveGroupByInTripParticipant(groupBy: string): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/saveGroupByInTripParticipant`;
        return this.http.post<QueryResultsModel>(url, { groupBy: groupBy }, { headers: httpHeaders });
    }

    getAutoReportExporting(): Observable<string> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + '/getAutoReportExporting';

        return this.http.get<string>(url, { headers: httpHeaders })
            .pipe(catchError(this.handleError("getAutoReportExporting", [])));
    }
}
