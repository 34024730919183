import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, BaseModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BookingProductSessionModel } from '../../../models/bookingproductsession.model';
import { ListObjectIDModel } from '../../../models/listobject.model';
// Real REST API
@Injectable()
export class BookingProductSessionService extends MaytechService{
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/bookingproductsession';
    }
}
