import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel, BaseModel } from '../../_base/crud';
import { DeviceActions, Userdevice, DeviceActionTypes } from '..';

export interface DeviceState extends EntityState<Userdevice> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Userdevice> = createEntityAdapter<Userdevice>({
    selectId: (obj: Userdevice) => obj._id,
});;


export const initialDeviceState: DeviceState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel(''),
    showInitWaitingMessage: true
});

export function devicesReducer(actionPrefix: string) {

    function theDeviceReducer(state = initialDeviceState, action: DeviceActions): DeviceState {
        switch (action.type) {
            case DeviceActionTypes.DevicesPageToggleLoading: return {
                ...state, listLoading: action.payload.isLoading
            };
            case DeviceActionTypes.DevicesActionToggleLoading: return {
                ...state, actionsloading: action.payload.isLoading
            };            
            case DeviceActionTypes.DeviceStatusUpdated: {
                const _partialRole: Update<Userdevice>[] = [];
                for (let i = 0; i < action.payload.objs.length; i++) {
                    _partialRole.push({
                        id: (<BaseModel>action.payload.objs[i])._id,
                        changes: {
                            _status: action.payload.status
                        }
                    });
                }
                return adapter.updateMany(_partialRole, state);
            }
            case DeviceActionTypes.DeviceLogout: return adapter.removeOne(action.payload.obj._id, state);
            case DeviceActionTypes.OneDeviceDeleted: return adapter.removeOne(action.payload.id, state);
            case DeviceActionTypes.ManyDevicesDeleted: return adapter.removeMany(action.payload.ids, state);
            case DeviceActionTypes.DevicesPageCancelled: return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel('')
            };
            case DeviceActionTypes.DevicesPageLoaded:
                return adapter.addMany(<Userdevice[]>action.payload.objs, {
                    ...initialDeviceState,
                    totalCount: action.payload.totalCount,
                    listLoading: false,
                    lastQuery: action.payload.page,
                    showInitWaitingMessage: false
                });
            case DeviceActionTypes.RemoveAllDeviceStore: {
                return adapter.removeAll(initialDeviceState);
            };
            default: return state;
        }
    }
    return (state = initialDeviceState, action: DeviceActions) => {
        return theDeviceReducer(state, action);
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
