import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessageType } from '../../../../../core/_base/crud';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { FeedbackDialogComponent } from './_sub/feedback-dialog.component';
import html2canvas from 'html2canvas';
import { KtDialogService } from '../../../../../core/_base/layout';

@Component({
    selector: 'mt-feedback',
    templateUrl: './feedback.component.html'
})
/** feedback component*/

export class FeedbackComponent {
    loading$: Observable<boolean>;
    subscriptions: Subscription[] = [];
    loadingSubject = new BehaviorSubject<boolean>(true);
    isLoading = false;
    /** feedback ctor */
    constructor(
        private ktDialogService: KtDialogService,
        private translate: TranslateService,
        private dialog: MatDialog,
    ) {

    }

    ngOnInit() {
        this.loading$ = this.loadingSubject.asObservable();
    }

    setLoading(isLoading: boolean) {
        if (this.isLoading != isLoading) {
            this.loadingSubject.next(isLoading);
            this.isLoading = isLoading
        }
    }

    toggleLoading(_incomingValue: boolean) {
        if (_incomingValue) {
            this.ktDialogService.show();
        }
        else{
            this.ktDialogService.hide();
        }
    }

    openDialog() {
        var screenshot;

        const dialogRef = this.dialog.open(FeedbackDialogComponent, { data: { screenshot: screenshot }, width: '555px' });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {

                return;
            }
            this.toggleLoading(false);

        });

    }
}
