// Angular
import { ChangeDetectorRef } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, timer } from 'rxjs';

import { NotificationService } from '../../../../../core/setting/_services/notification.service';
import { LayoutUtilsService, QueryParamsModel } from '../../../../../core/_base/crud';
import { MaytechSimpleListComponent } from '../../../../../core/_maytech/maytech-simple-list.component';
import { MaytechTenantService } from '../../../../../core/_maytech/maytech.tenant.service';
import { NotificationStatus, ObjectType } from '../../../../../core/_utils/define';
import { NotificationModel } from '../../../../../models/Notification.model';
import { HelpEventService } from '../../help/help-event.service';
import { NotificationDetailDialogComponent } from './notification-detail/notification-detail.dialog.component';

@Component({
	selector: 'kt-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['notification.component.scss']
})

export class NotificationComponent extends MaytechSimpleListComponent {
   
    notification_title = this.translate.instant("SETTING.ACTIONLOG.ACTION_LIST");
    notification: NotificationModel;
    unreadNotificationCounter: number = 0;
    constructor(
        router: Router,
        activatedRoute: ActivatedRoute,
        translate: TranslateService,
        layoutUtilsService: LayoutUtilsService,
        cdr: ChangeDetectorRef,
        dataService: NotificationService,
        tenantService: MaytechTenantService,
        helpEventService: HelpEventService,
        private dialog: MatDialog,
        permissionsService: NgxPermissionsService) {

        super(helpEventService, permissionsService, translate, activatedRoute, router, layoutUtilsService, cdr, dataService, tenantService);
        this.editUrl = '/setting/actionlog/edit'
        this.displayedColumns = ['createdDate', 'fromUserName', 'notes', 'objectName'];
    }

    /**
    * On init
    */
    ngOnInit() {

        //var objectType = Object.keys(ObjectType)
        //    .map(k => ObjectType[k])
        //    .filter(v => typeof v === "number") as number[];
        this.startTimer();
        super.ngOnInit();
        
       

    }
    startTimer() {
        //const now = new Date();
        //const initialDelay = 60 * 1000 - (now.getSeconds() * 1000 + now.getMilliseconds());
        //Observable
        //    .timer(initialDelay, 60000)
        //    .subscribe(console.log('Observer got a next value'));
        const source = timer(20000);
        //output: 0
        const subscribe = source.subscribe(val => {
            //console.log("Abc123");
            this.loadObjectsList();
            this.startTimer();
        });
        this.subscriptions.push(subscribe);
    }
    loadObjectsList() {
        const queryParams = new QueryParamsModel(this.getFilter());
        this.maytechDataService.findObjects(queryParams, false).toPromise().then(res => {
            if (res) {// success
                this.bindDataToList(res);
                this.unreadNotificationCounter = this.dataList.dataSource.data.filter(n => (n as NotificationModel).notificationStatus == NotificationStatus.JUST_CREATED).length;
            }
        }, err => {
           // this.onDataServiceError(err);
        });
    }
    getFilter(): any {
        const filter: any = {};
        //const searchText: string = this.searchInput.nativeElement.value;
        filter.keyword = "";
        return filter;
    }
    viewNotification(notificationId: number): any {
        (this.maytechDataService as NotificationService).readMessage(notificationId).toPromise().then(res => {
            //if (res) {// success
            //    this.bindDataToList(res);
            //}
        }, err => {
            this.onDataServiceError(err);
        });
        this.dialog.open(NotificationDetailDialogComponent, { data: { notificationId: notificationId } });
       
    }
	// Show dot on top of the icon
	@Input() dot: string;
    // Show badge on top of the icon
    @Input() badge: string;
	// Show pulse on icon
	@Input() pulse: boolean;

	@Input() pulseLight: boolean;

	// Set icon class name
	@Input() icon: string = 'flaticon2-bell-alarm-symbol';
	@Input() iconType: '' | 'success';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	// Set bg image path
	@Input() bgImage: string;

	// Set skin color, default to light
	@Input() skin: 'light' | 'dark' = 'light';

	@Input() type: 'brand' | 'success' = 'success';

	/**
	 * Component constructor
	 *
	 * @param sanitizer: DomSanitizer
	 */
   

	backGroundStyle(): string {
		if (!this.bgImage) {
			return 'none';
		}

		return 'url(' + this.bgImage + ')';
	}
}
