// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Services
import { AuthService } from '../_services';
// Actions
import {
    AllPermissionsLoaded,
    AllPermissionsRequested,
    PermissionActionTypes
} from '../_actions/permission.actions';
import { Permission } from '../../../models/permission.model';
import { QueryResultsModel } from '../../_base/crud';
// Models

@Injectable()
export class PermissionEffects {
    
    loadAllPermissions$ = createEffect(() => this.actions$
        .pipe(
            ofType<AllPermissionsRequested>(PermissionActionTypes.AllPermissionsRequested),
            mergeMap(() => this.auth.getAllPermissions()),
            map((result: QueryResultsModel) => {
                return  new AllPermissionsLoaded({
                    permissions: result.items
                });
            })
          ));

    //@Effect()
    //init$: Observable<Action> = defer(() => {
    //    return of(new AllPermissionsRequested());
    //});

    constructor(private actions$: Actions, private auth: AuthService) { }
}
