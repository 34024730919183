import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, BaseModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomerModel } from '../../../models/customer.model';
// Real REST API
@Injectable()
export class CustomerService extends MaytechService{
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/customer';
    }
    exportList(queryParams: QueryParamsModel): Observable<any> {
       

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = `${this.API_URL}/exportList`;
        return this.http.get<any>(url, {
            headers: httpHeaders,
            params: httpParams
        }).pipe(
            catchError(this.handleError("exportList", []))
        );

      
    }


    updateCustomerInfoFromCustomer(obj: BaseModel, catchErr = true): Observable<BaseModel> {
        const url = this.API_URL + "/PutCustomerInfoFromCustomer";
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        if (catchErr) {
            return this.http.put<BaseModel>(url, obj, { headers: httpHeaders })
                .pipe(catchError(this.handleError("updateCustomerInfoFromCustomer", []))
                );
        }
        return this.http.put<BaseModel>(url, obj, { headers: httpHeaders })
    }


    importList(formData: CustomerModel): Observable<any> {


        const httpHeaders = this.httpUtils.getHTTPHeaders();

        const url = this.API_URL + "/import";
        return this.http.post<any>(url, formData,  {
            headers: httpHeaders
        }).pipe(
            catchError(this.handleError("importList", []))
        );


    }

    // CREATE =>  POST: add a new product to the server
    importList2(obj: BaseModel): Observable<BaseModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<BaseModel>(this.API_URL, obj, { headers: httpHeaders }).pipe(catchError(this.handleError("createObject", []))
        );
    }


    import(fileXlsx: FormData): Observable<any> {


        const httpHeaders = this.httpUtils.getHTTPHeaders();

        const url = this.API_URL + "/Import";
        return this.http.post<any>(url, {
            headers: httpHeaders
        }).pipe(
            catchError(this.handleError("import", []))
        );


    }

    getBookingCustomerList(queryParams: QueryParamsModel, customerId: number): Observable<any> {
        const url = this.API_URL + "/GetBookingCustomerList/" + customerId.toString();

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders })
            .pipe(catchError(this.handleError("getBookingCustomerList", []))
        );
    }

    getCustomerInfoList(id: number): Observable<any> {
        const url = this.API_URL + "/GetCustomerInfo/" + id.toString();

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<any>(url, { headers: httpHeaders })
            .pipe(catchError(this.handleError("getCustomerInfoList", []))
        );
    }

    getCustomerByName(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const url = this.API_URL + "/GetCustomersByName";
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams
        }).pipe(
            catchError(this.handleError("getCustomerByName", []))
        );
    }

    getCustomerByBookingId(bookingId: number): Observable<CustomerModel> {
        const url = this.API_URL + `/GetCustomerByBookingId/${bookingId}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<CustomerModel>(url, {
            headers: httpHeaders
        }).pipe(
            catchError(this.handleError("getCustomerByBookingId", []))
        );
    }

    getCustomerEmailByBookingId(bookingId: number, messageTemplateId: number): Observable<any> {
        const url = this.API_URL + `/GetCustomerEmailByBookingId/${bookingId}/${messageTemplateId}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<any>(url, {
            headers: httpHeaders
        }).pipe(
            catchError(this.handleError("getCustomerEmailByBookingId", []))
        );
    }

    updateBookingCustomer(bookingId: number, customer: BaseModel): Observable<BaseModel> {
        const url = this.API_URL + `/UpdateBookingCustomer/${bookingId}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.put<BaseModel>(url, customer, {
            headers: httpHeaders
        });
    }


    getNewCustomerId(bookingProductSessionId: number): Observable<number> {
        const url = this.API_URL + "/getNewCustomerId/" + bookingProductSessionId;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<number>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getBookingProductCustomerList", []))
        );
    }

    getByBookingCustomerId(objId: number, catchErr = true): Observable<BaseModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/getByBookingCustomerId/${objId}`;
        if (catchErr) {
            return this.http.get<BaseModel>(url, { headers: httpHeaders })
                .pipe(catchError(this.handleError("getByBookingCustomerId", [])));
        }
        return this.http.get<BaseModel>(url, { headers: httpHeaders });

    }
}
