import { BaseModel, QueryParamsModel } from '../../_base/crud';
import { ObjectState } from '../../_maytech/maytech.reducers';
import { EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { BookingActions, BookingActionTypes } from '../_actions/booking.actions';
import { BookingForListModel } from '../../../models/bookingforlist.model';

export interface BookingState extends ObjectState<BaseModel> {

}

export const adapter: EntityAdapter<BaseModel> = createEntityAdapter<BaseModel>({
    selectId: (obj: BaseModel) => obj._id,
});

export const initialBookingState: BookingState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel(''),
    lastCreatedObjectId: undefined,
    lastUpdatedObjectId: undefined,
    lastDeletedObjectId: undefined,
    showInitWaitingMessage: true,
    httpAPICallType: undefined,
    objectType: undefined,
    errorMessage: '',
    errorCode: 0
});

export function bookingReducer(actionPrefix: string) {
    function theBookingReducer(state = initialBookingState, action: BookingActions): BookingState {
        switch (action.type) {
            case BookingActionTypes.BookingPageLoaded:
                return adapter.addMany(action.payload.objs, {
                    ...initialBookingState,
                    totalCount: action.payload.totalCount,
                    listLoading: false,
                    lastQuery: action.payload.page,
                    showInitWaitingMessage: false
                });
            case BookingActionTypes.BookingStatusUpdated: {
                let _partialBooking: Update<BookingForListModel> = { id: undefined, changes: new BookingForListModel() };
                _partialBooking.id = action.payload.obj._id;
                _partialBooking.changes.bookingStatus = action.payload.status;
                _partialBooking.changes._id = action.payload.obj._id;
                return adapter.updateOne(_partialBooking, state);
            }
            case BookingActionTypes.BookingWarningStatusUpdated: {
                let _partialBooking: Update<BookingForListModel> = { id: undefined, changes: new BookingForListModel() };
                _partialBooking.id = action.payload.obj._id;
                _partialBooking.changes.warningStatus = action.payload.status;
                _partialBooking.changes._id = action.payload.obj._id;
                return adapter.updateOne(_partialBooking, state);
            }
            case BookingActionTypes.OneBookingDeleted: return adapter.removeOne(action.payload.id, {
                ...state,
                lastDeletedObjectId: action.payload.id,
                httpAPICallType: HttpAPICallType.Delete,
                objectType: ObjectType.Booking,
                listLoading: false,
                actionsloading: false
            });
            case BookingActionTypes.BookingRepresentorUpdated: {
                let _partialBooking: Update<BookingForListModel> = { id: undefined, changes: new BookingForListModel() };
                _partialBooking.id = action.payload.obj._id;
                _partialBooking.changes.representedUser = action.payload.representedUser;
                _partialBooking.changes._id = action.payload.obj._id;
                return adapter.updateOne(_partialBooking, state);
            }
            case BookingActionTypes.BookingSellerUpdated: {
                let _partialBooking: Update<BookingForListModel> = { id: undefined, changes: new BookingForListModel() };
                _partialBooking.id = action.payload.obj._id;
                _partialBooking.changes.sellerUser = action.payload.sellerUser;
                _partialBooking.changes._id = action.payload.obj._id;
                return adapter.updateOne(_partialBooking, state);
            }
            default: return state;
        }
    }
    return (state = initialBookingState, action: BookingActions) => {
        return theBookingReducer(state, action);
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
