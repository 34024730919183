import { BaseModel } from '../core/_base/crud';
export class GeneralSearchModel extends BaseModel {

    searchDataId: number;
    createdDate: string;
    updatedDate: string;
    objectId: number;
    objectType: number;
    content: string;
    objectCreatedBy: number;
    objectStatus: number;

    title: string;
 
    url: string;

    clear() {
        this.searchDataId = 0;
        this.createdDate = "";
        this.updatedDate = "";
        this.objectId = 0;
        this.objectType = 0;
        this.content = "";
        this.objectCreatedBy = 0;
        this.objectStatus = 0;

        this.title = "";
 
        this.url = "";
    }
}
