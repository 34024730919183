import { OnInit, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { EditRequestType, PaymentType, ComboItem } from '../../../../../../core/_utils/define';

@Component({
    selector: 'kt-request-edit-dialog',
    templateUrl: './request-edit-dialog.component.html'
})
export class RequestEditDialogComponent implements OnInit {
    curValue = new FormControl('');
    newValue = new FormControl('');
    reasonNote = new FormControl();
    paymentTypeList: ComboItem[] = [];

    constructor(
        public dialogRef: MatDialogRef<RequestEditDialogComponent>,
        private translate: TranslateService,
        public objectFB: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.data = data
    }

    ngOnInit() {
        this.curValue.setValue(this.data.curValue);
        switch (this.data.inputType) {
            case EditRequestType.PaymentType:
                this.paymentTypeList = [];
                var paymentTypes = Object.keys(PaymentType)
                    .map(k => PaymentType[k])
                    .filter(v => typeof v === "number") as number[];
                for (let item of paymentTypes) {
                    this.paymentTypeList.push({ Value: item, Text: this.getPaymentTypeLabel(item) });
                }
                break;
            case EditRequestType.PaymentDate:
                break;
        }
    }

    onYesClick(): void {
        this.dialogRef.close({
            curValue: this.data.curValue,
            newValue: this.newValue.value,
            reasonNote: this.reasonNote.value
        });
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    get editRequestType() { return EditRequestType; }

    getListArray(strArr) {
        return JSON.parse(strArr);
    }

    getPaymentTypeLabel(paymentType: number): string {
        return this.translate.instant("ENUM.PaymentType." + PaymentType[(<PaymentType>paymentType)]);
    }
}
