import { BaseModel } from '../core/_base/crud';

export class OptionModel extends BaseModel {
    optionId: number;
    optionName: string;
    optionValue: string;
    createdBy: number;
    createdDate: Date;
    updatedBy: number;
    updatedDate: Date;

    clear(): void {
        this.optionId = undefined;
        this.optionName = '';
        this.optionValue = '';
        this.createdBy = undefined;
        this.createdDate = undefined;
        this.updatedBy = undefined;
        this.updatedDate = undefined;
    }
}
