// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { BaseModel } from '../../_base/crud';
import { Update } from '@ngrx/entity';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { ObjectType } from '../../_utils/define';

export enum RoleActionTypes {
    AllRolesRequested = '[Roles Home Page] All Roles Requested',
    AllRolesLoaded = '[Roles API] All Roles Loaded',
    RoleOnServerCreated = '[Edit Role Component] Role On Server Created',
    RoleOnServerUpdated = '[Edit Role Component] Role On Server Updated',
    RoleOnServerDeleted = '[Roles List Page] Role On Server Deleted',
    RoleCreated = '[Edit Role Component] Role Created',
    RoleUpdated = '[Edit Role Component] Role Updated',
    RolesStatusUpdated = '[Roles List Page] Roles Status Updated',
    OneRoleDeleted = '[Roles List Page] One Role Deleted',
    ManyRolesDeleted = '[Roles List Page] Many Selected Roles Deleted',
    RolesPageRequested = '[Roles List Page] Roles Page Requested',
    RolesPageLoaded = '[Roles API] Roles Page Loaded',
    RolesPageCancelled = '[Roles API] Roles Page Cancelled',
    RolesPageToggleLoading = '[Roles] Roles Page Toggle Loading',
    RolesActionToggleLoading = '[Roles] Roles Action Toggle Loading',
    RoleErrorAction = '[Role Component] Error Action',
    ClearRoleError = '[Role Component] Clear Error',
    RemoveAllRoleStore = '[Roles API] Remove All Role Store',
}

export class RemoveAllRoleStore implements MaytechBaseAction {
    readonly type = RoleActionTypes.RemoveAllRoleStore;
    constructor(public actionPrefix: string) { }
}

export class RoleErrorAction implements MaytechBaseAction {
    readonly type = RoleActionTypes.RoleErrorAction;
    constructor(public actionPrefix: string, public payload: { obj: QueryResultsModel }) { }
}

export class ClearRoleError implements MaytechBaseAction {
    readonly type = RoleActionTypes.ClearRoleError;
    constructor(public actionPrefix: string) { }
}

export class AllRolesRequested implements Action {
    readonly type = RoleActionTypes.AllRolesRequested;
}

export class AllRolesLoaded implements Action {
    readonly type = RoleActionTypes.AllRolesLoaded;
    constructor(public payload: { objs: BaseModel[] }) { }
}

export class RoleOnServerCreated implements MaytechBaseAction {
    readonly type = RoleActionTypes.RoleOnServerCreated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class RoleCreated implements MaytechBaseAction {
    readonly type = RoleActionTypes.RoleCreated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class RoleOnServerUpdated implements MaytechBaseAction {
    readonly type = RoleActionTypes.RoleOnServerUpdated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class RoleUpdated implements MaytechBaseAction {
    readonly type = RoleActionTypes.RoleUpdated;
    constructor(public actionPrefix: string, public payload: {
        partialRole: Update<BaseModel>, // For State update
        obj: BaseModel // For Server update (through service)
    }) { }
}

export class RolesStatusUpdated implements MaytechBaseAction {
    readonly type = RoleActionTypes.RolesStatusUpdated;
    constructor(public actionPrefix: string, public payload: {
        objs: BaseModel[],
        status: number
    }) { }
}

export class RoleOnServerDeleted implements MaytechBaseAction {
    readonly type = RoleActionTypes.RoleOnServerDeleted;
    constructor(public actionPrefix: string, public payload: { id: number }) { }
}

export class OneRoleDeleted implements MaytechBaseAction {
    readonly type = RoleActionTypes.OneRoleDeleted;
    constructor(public actionPrefix: string, public payload: { id: number }) { }
}

export class ManyRolesDeleted implements MaytechBaseAction {
    readonly type = RoleActionTypes.ManyRolesDeleted;
    constructor(public actionPrefix: string, public payload: { ids: number[] }) { }
}

export class RolesPageRequested implements MaytechBaseAction {
    readonly type = RoleActionTypes.RolesPageRequested;
    constructor(public actionPrefix: string, public payload: { page: QueryParamsModel }) { }
}

export class RolesPageLoaded implements MaytechBaseAction {
    readonly type = RoleActionTypes.RolesPageLoaded;
    constructor(public actionPrefix: string, public payload: { objs: BaseModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class RolesPageCancelled implements MaytechBaseAction {
    readonly type = RoleActionTypes.RolesPageCancelled;
    constructor(public actionPrefix: string) { }

}

export class RolesPageToggleLoading implements MaytechBaseAction {
    readonly type = RoleActionTypes.RolesPageToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export class RolesActionToggleLoading implements MaytechBaseAction {
    readonly type = RoleActionTypes.RolesActionToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export type RoleActions = AllRolesLoaded
    | AllRolesRequested
    | RoleOnServerCreated
    | RoleCreated
    | RoleOnServerUpdated
    | RoleUpdated
    | RolesStatusUpdated
    | RoleOnServerDeleted
    | OneRoleDeleted
    | ManyRolesDeleted
    | RolesPageRequested
    | RolesPageLoaded
    | RolesPageCancelled
    | RolesPageToggleLoading
    | RolesActionToggleLoading
    | RoleErrorAction
    | ClearRoleError
    | RemoveAllRoleStore;
