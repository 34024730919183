import { BaseModel } from "../core/_base/crud";

export class BookingProductTaxFeeModel extends BaseModel {
    bookingProductTaxFeeId: number;
    BookingProductSessionId: number;
    taxFeeId: number;
    taxFeeName: string;
    taxFeeDesc: string;
    taxFeeType: number;
    valueType: number;
    value: number;
    isInclusive: boolean;
    isCompound: boolean;
    amount: number;
    label: string;
}
