import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// CRUD
import { RoleActions, RoleActionTypes } from '../_actions/role.actions';
import { Role } from '../../../models/role.model';
import { QueryParamsModel, BaseModel } from '../../_base/crud';
import { ObjectState } from '../../_maytech/maytech.reducers';
import { HttpAPICallType, ObjectType } from '../../_utils/define';

export interface RoleState extends ObjectState<BaseModel> {
    isAllRolesLoaded: boolean;
}

export const adapter: EntityAdapter<BaseModel> = createEntityAdapter<BaseModel>({
    selectId: (obj: BaseModel) => obj._id,
});


export const initialRoleState: RoleState = adapter.getInitialState({
    isAllRolesLoaded: false,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel(''),
    lastCreatedObjectId: undefined,
    lastUpdatedObjectId: undefined,
    lastDeletedObjectId: undefined,
    showInitWaitingMessage: true,
    httpAPICallType: undefined,
    objectType: undefined,
    errorMessage: '',
    errorCode: 0
});

export function rolesReducer(actionPrefix: string) {

    function theRoleReducer(state = initialRoleState, action: RoleActions): RoleState {
        switch (action.type) {
            case RoleActionTypes.RemoveAllRoleStore: {
                return adapter.removeAll(initialRoleState);
            };
            case RoleActionTypes.RoleErrorAction: return {
                ...state,
                listLoading: false,
                actionsloading: false,
                errorCode: action.payload.obj.errorCode,
                errorMessage: action.payload.obj.errorMessage,
                objectType: ObjectType.Role
            };
            case RoleActionTypes.ClearRoleError: return {
                ...state,
                errorCode: undefined,
                errorMessage: ''
            };
            case RoleActionTypes.RolesPageToggleLoading: return {
                ...state,
                listLoading: action.payload.isLoading,
                lastCreatedObjectId: undefined,
                lastUpdatedObjectId: undefined
            };
            case RoleActionTypes.RolesActionToggleLoading: return {
                ...state, actionsloading: action.payload.isLoading
            };
            case RoleActionTypes.RoleOnServerCreated: return {
                ...state
            };
            case RoleActionTypes.RoleCreated: return adapter.addOne(<Role>action.payload.obj, {
                ...state,
                lastCreatedObjectId: (<Role>action.payload.obj)._id,
                httpAPICallType: HttpAPICallType.Create,
                objectType: ObjectType.Role,
                actionsloading: false
            });
            case RoleActionTypes.RoleOnServerUpdated: return {
                ...state
            };
            case RoleActionTypes.RoleUpdated: return adapter.updateOne(action.payload.partialRole, {
                ...state,
                lastUpdatedObjectId: action.payload.obj._id,
                httpAPICallType: HttpAPICallType.Update,
                objectType: ObjectType.Role,
                actionsloading: false
            });
            case RoleActionTypes.RolesStatusUpdated: {
                const _partialRole: Update<Role>[] = [];
                for (let i = 0; i < action.payload.objs.length; i++) {
                    _partialRole.push({
                        id: (<BaseModel>action.payload.objs[i])._id,
                        changes: {
                            _status: action.payload.status
                        }
                    });
                }
                return adapter.updateMany(_partialRole, state);
            }
            case RoleActionTypes.OneRoleDeleted: return adapter.removeOne(action.payload.id, {
                ...state,
                lastDeletedObjectId: action.payload.id,
                httpAPICallType: HttpAPICallType.Delete,
                objectType: ObjectType.Role,
                listLoading: false,
                actionsloading: false
            });
            case RoleActionTypes.ManyRolesDeleted: return adapter.removeMany(action.payload.ids, state);
            case RoleActionTypes.RolesPageCancelled: return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel('')
            };
            case RoleActionTypes.RolesPageLoaded:
                return adapter.addMany(<Role[]>action.payload.objs, {
                    ...initialRoleState,
                    totalCount: action.payload.totalCount,
                    listLoading: false,
                    lastQuery: action.payload.page,
                    showInitWaitingMessage: false
                });
            case RoleActionTypes.AllRolesLoaded: return adapter.addMany(<Role[]>action.payload.objs, {
                ...state, isAllRolesLoaded: true, showInitWaitingMessage: false
            });
            default: return state;
        }
    }
    return (state = initialRoleState, action: RoleActions) => {
        return theRoleReducer(state, action);
        //switch (action.actionPrefix) {
        //    case actionPrefix:
        //        return theRoleReducer(state, action);
        //    default:
        //        return state;
        //}
    }
}
//export const getRoleState = createFeatureSelector<BaseModel>('roles');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
