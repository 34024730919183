// Angular
import { Component, Input } from '@angular/core';
import { GeneralSearchModel } from '../../../../models/generalsearch.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'kt-search-result',
	templateUrl: './search-result.component.html',
	styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent {
	// Public properties
    @Input() data: GeneralSearchModel[];
    @Input() noRecordText: string;
    constructor(private translate: TranslateService) {}
}
