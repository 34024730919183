import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaytechService } from '../../_maytech/maytech.service';
import { HttpUtilsService } from '../../_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { ObjectType, HttpAPICallType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { RoleErrorAction } from '../_actions/role.actions';

@Injectable()
export class RoleService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/roles';

    }
    getErrorDispatcher(error: any, objectType: ObjectType = ObjectType.Any, httpAPICallType: HttpAPICallType = HttpAPICallType.GetList): MaytechBaseAction {
        return new RoleErrorAction(this.actionPrefix, {
            obj: {
                items: [],
                totalCount: 0,
                errorMessage: error.message,
                errorCode: error.status,
                pageIndex: 0
            }
        });
    }
}
