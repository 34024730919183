import { BaseModel } from '../core/_base/crud';


export class RecentOrdersModel extends BaseModel {
    bookingId: number;
    customerId: number;
    bookingNumber: string;
    date: string;
    amount: number;
    status: number;

    clear() {
        this.bookingId = 0;
        this.bookingNumber = '';
        this.customerId = 0;
        this.status = 0;
        this.date = '';
        this.amount = 0;
    }
}
