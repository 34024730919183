import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpUtilsService, BaseModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../_reducers';
import { ObjectType, HttpAPICallType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContextHelpModel } from '..';
import { Options } from 'selenium-webdriver/ie';
import { environment } from '../../../../environments/environment';

// Real REST API
@Injectable()
export class ContextHelpService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService ) {

        super(http, httpUtils, route,store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/contexthelp';
    }

    getContextHelpByName(name: string, lang:string): Observable<ContextHelpModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/GetContextHelpByName/${name}/${lang}`;
        return this.http.get<ContextHelpModel>(url, {
            headers: httpHeaders
        }).pipe(catchError(this.handleError("getContextHelpByName", []))
        );

    }

    // UPDATE => PUT: update the object on the server
    saveContextHelpByName(obj: BaseModel): Observable<ContextHelpModel> {

        const url = `${this.API_URL}/SaveContextHelpByName`;


        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<ContextHelpModel>(url, obj, { headers: httpHeaders }).pipe(catchError(this.handleError("updateObjects", []))
        );
    }

}
