import { Component, Directive, Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


import { HostListener } from "@angular/core";
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class ComponentCanDeactivate {

    abstract canDeactivate(): boolean;


    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }
   
    
}


export abstract class FormCanDeactivate extends ComponentCanDeactivate {

    abstract get form(): NgForm;

    canDeactivate(): boolean {
        return this.form.submitted || !this.form.dirty
    }
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private translate: TranslateService) {}
    canDeactivate(component: ComponentCanDeactivate): boolean {

        let msg = this.translate.instant("GENERAL.NOT_SAVE_MESSAGE");//"You have unsaved changes! If you leave, your changes will be lost."

        if (!component.canDeactivate()) {
            if (confirm(msg)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
