import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, BaseModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BookingProductPriceModel } from '../../../models/bookingproductprice.model';
import { ListObjectIDModel } from '../../../models/listobject.model';
// Real REST API
@Injectable()
export class BookingProductTaxFeeService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/bookingproducttaxfee';
    }

    getTmpListObjectByParentID(currencyId: number, bookingProductSessionId: number, data: any): Observable<BaseModel[]> {
        const url = `${this.API_URL}/getTmpListObjectByParentID/${currencyId}/${bookingProductSessionId}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<BaseModel[]>(url, data, { headers: httpHeaders });
    }

    getBookingTaxFees(bookingId: number): Observable<BaseModel[]> {
        const url = `${this.API_URL}/getBookingTaxFees/${bookingId}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<BaseModel[]>(url, { headers: httpHeaders });
    }
}
