import { ChangeDetectionStrategy, Component, ViewChild, ElementRef, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { EditRequestModel } from '../../../../../../models/editrequest.model';
import { EditRequestApprovalModel } from '../../../../../../models/editrequestapproval.model';
import { EditRequestService } from '../../../../../../core/setting';
import { LayoutUtilsService, QueryParamsModel, AlertMessageType, BaseModel } from '../../../../../../core/_base/crud';
import { MaytechTenantService } from '../../../../../../core/_maytech/maytech.tenant.service';
import { HelpEventService } from '../../../../../partials/layout/help/help-event.service';
import { SubModule, Module, EditRequestStatus, ObjectType, EditRequestType } from '../../../../../../core/_utils/define';
import { MaytechSimpleListComponent } from '../../../../../../core/_maytech/maytech-simple-list.component';

@Component({
    selector: 'kt-edit-request-list-dialog',
    templateUrl: './edit-request-list-dialog.component.html'
})
export class EditRequestListDialogComponent extends MaytechSimpleListComponent {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    listPageSize = 5;
    listPageSizeOptions = [5];

    itemListArray: EditRequestModel;
    object: BaseModel;
    editrequest: EditRequestModel;

    constructor(
        router: Router,
        activatedRoute: ActivatedRoute,
        translate: TranslateService,
        layoutUtilsService: LayoutUtilsService,
        cdr: ChangeDetectorRef,
        dataService: EditRequestService,
        tenantService: MaytechTenantService,
        helpEventService: HelpEventService,
        permissionsService: NgxPermissionsService,
        public dialogRef: MatDialogRef<EditRequestListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super(helpEventService, permissionsService, translate, activatedRoute, router, layoutUtilsService, cdr, dataService, tenantService);
        this.data = data
    }
    // Table fields
    displayedColumns = ['createdDate', 'createdBy', 'newvalue', 'notes', 'actions'];

    /**
     * Fetch selected customers
     */
    fetchObjects() {
        // tslint:disable-next-line:prefer-const
        let messages = [];
        this.selection.selected.forEach(elem => {
            messages.push({
                text: `${(elem as EditRequestModel).displayFieldName}`,
                id: (elem as EditRequestModel).editRequestId,
                status: (elem as EditRequestModel).editRequestStatus
            });
        });
        this.layoutUtilsService.fetchElements(messages);
    }

    getFilter(): string {
        const filter: any = {};
        filter.keyword = '';
        filter.status = [EditRequestStatus.NEW];
        filter.objectType = this.data.objectType;
        filter.objectId = this.data.objectId;
        filter.fieldName = this.data.fieldName;
        this.paginator.pageSize = 5;
        //filter by status
        return filter;
    }

    getModuleLabel(module: number, subModule: number): string {
        let moduleText = this.translate.instant("ENUM.Module." + Module[(<Module>module)]);
        let subModuleText = this.translate.instant("ENUM.SubModule." + SubModule[(<SubModule>subModule)]);
        return subModuleText.length == 0 ? moduleText : moduleText + ' (' + subModuleText + ')';
    }

    getStatusLabel(status: number): string {
        return this.translate.instant("ENUM.EditRequestStatus." + EditRequestStatus[(<EditRequestStatus>status)]);
    }

    getDislayFieldName(displayFieldName: string, hasTranslate: boolean): string {
        return hasTranslate ? this.translate.instant(displayFieldName) : displayFieldName;
    }

    onCancelClick(): void {
        this.dialogRef.close({
            newValue: this.data.curValue,
            hasPendingRequest: this.dataList.hasItems
        });
    }

    updateStatus(isApprove: boolean = true, item: EditRequestModel) {
        let model: EditRequestApprovalModel = new EditRequestApprovalModel();
        model.isApprove = isApprove;
        if (isApprove) {
            model.editRequestStatus = EditRequestStatus.APPROVED;
            model.isRejectAllOther = true;
        } else {
            model.editRequestStatus = EditRequestStatus.REJECTED;
            model.isRejectAllOther = false;
        }

        if (item) {
            model.editRequests = [item];
        } else {
            return;
        }

        const subDialogRef = this.layoutUtilsService.showApproveEditDialog(model);
        subDialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (isApprove) {
                    this.data.curValue = item.newValue
                    this.dialogRef.close({
                        newValue: item.newValue,
                        hasPendingRequest: false
                    });
                } else {
                    if (this.dataList.dataSource.data.length > 1) {
                        this.loadObjectsList();
                    } else {
                        this.dialogRef.close({
                            newValue: this.data.curValue,
                            hasPendingRequest: false
                        });
                    }
                }
            }
        });
    }

    get editRequestType() { return EditRequestType; }
}
