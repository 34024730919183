import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as viLang } from './core/_config/i18n/vi';
import { locale as enLang } from './core/_config/i18n/en';

@Component({
    selector: 'body[kt-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Oxalis Heart';
    loader: boolean;
    private unsubscribe: Subscription[] = [];

    constructor(private translationService: TranslationService,
        private router: Router,
        private layoutConfigService: LayoutConfigService,
        private splashScreenService: SplashScreenService) {

        // register translations
        //this.translationService.setLanguage("vi");
        //this.translationService.loadTranslations(viLang, enLang);
        //this.translationService.(['en', 'da']);
        //this.translationService.('en');
        //var json = JSON.stringify(viLang);

    }

    /**
       * On init
       */
    ngOnInit(): void {
        // enable/disable loader
        this.loader = this.layoutConfigService.getConfig('loader.enabled');

        const routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('kt-page--loaded');
                }, 500);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

	/**
	 * On Destroy
	 */
    ngOnDestroy() {
        this.unsubscribe.forEach(sb => sb.unsubscribe());
    }
}
