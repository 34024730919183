// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User } from '../../../models/user.model';
import { environment } from '../../../../environments/environment';
import { ObjectType } from '../../_utils/define';
import { Permission } from '..';

export interface AuthState {
    loggedIn: boolean;
    authToken: string;
    user: User;
    permissions: Permission[];
    isUserLoaded: boolean;
    isUserUpdated: boolean;
    httpAPICallType: any;
    objectType: any;
    errorMessage: string;
    errorCode: number;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    authToken: undefined,
    user: undefined,
    permissions: undefined,
    isUserLoaded: false,
    isUserUpdated: false,
    httpAPICallType: undefined,
    objectType: undefined,
    errorMessage: '',
    errorCode: 0
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.AuthUserErrorAction: return {
            ...state,
            errorMessage: action.payload.obj.errorMessage,
            errorCode: action.payload.obj.errorCode,
            objectType: ObjectType.Auth
        };

        case AuthActionTypes.ClearAuthError: return {
            ...state,
            errorCode: undefined,
            errorMessage: ''
        };

        case AuthActionTypes.Login: {
            const _token: string = action.payload.authToken;
            return {
                ...state,
                loggedIn: true,
                authToken: _token,
                user: undefined,
                permissions: undefined,
                isUserLoaded: false,
                isUserUpdated: false
            };
        }

        case AuthActionTypes.Register: {
            const _token: string = action.payload.authToken;
            return {
                ...state,
                loggedIn: false,
                authToken: _token,
                user: undefined,
                isUserLoaded: false,
                isUserUpdated: false
            };
        }

        case AuthActionTypes.Logout:
            return initialAuthState;

        case AuthActionTypes.UserLoaded: {
            const _user: User = action.payload.user;
            localStorage.setItem(environment.currencyCode, _user.currencyCode);
            return {
                ...state,
                user: _user,
                permissions: _user.userPermissions,
                isUserLoaded: true,
                errorMessage: '',
                errorCode: 0
            };
        }

        case AuthActionTypes.UserPermissionLoaded: {
            const _permissions: Permission[] = action.payload.permissions;
            return {
                ...state,
                permissions: _permissions
            };
        }

        case AuthActionTypes.AuthUserOnServerUpdated: return {
            ...state
        };
        case AuthActionTypes.AuthUserUpdated: {
            const _user: User = <User>action.payload.user;
            return {
                ...state,
                user: _user,
                isUserUpdated: true
            };
        }

        default:
            return state;
    }
}
