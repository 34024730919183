import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryResultsModel } from '../../_base/crud';
import { ActivatedRoute } from '@angular/router';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { MaytechService } from '../../_maytech/maytech.service';
import { Observable } from 'rxjs';
import { ListObjectIDModel } from '../../../models/listobject.model';

// Real REST API
@Injectable()
export class BookingInfoService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        tenantService: MaytechTenantService) {
        super(http, httpUtils, route, null, tenantService);
        if (tenantService.currentClient != null)
            this.API_URL = `${tenantService.currentClient.apiurl}/bookinginfo`;
    }

    getBookingFields(bookingID: number): Observable<any> {
        const url = this.API_URL + "/GetBookingFields/" + bookingID.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
    }

    getUnselectedBookingFields(bookingID: number): Observable<any> {
        const url = this.API_URL + "/GetUnselectedBookingFields/" + bookingID.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
    }

    updateBookingInfos(bookingID: number, objs: any[] = []): Observable<any> {
        const url = this.API_URL + "/UpdateBookingInfos/" + bookingID.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, { objs: objs }, { headers: httpHeaders });
    }
}
