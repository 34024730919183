// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../core/_reducers';
// Selectors
import { ObjectsDataSource } from '../../_maytech/maytech.datasource';

export class OptionDataSource extends ObjectsDataSource {
   
	constructor(store: Store<AppState>) {
        super(store, 'options');
       
	}
}
