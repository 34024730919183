import { FormControl, FormGroup } from '@angular/forms';
import { BaseModel } from '../core/_base/crud';


export class CustomerInfoModel extends BaseModel {
    customerInfoId: number;
    bookingCustomerId: number;
    productBookingFieldId: number;
    bookingFieldId: number;
    bookingFieldName: string;
    fieldType: number;
    listArray: string;
    infoValue: string;
    orderNumber: number;
    showOnlineBooking: boolean;
    mandatoryOnline: boolean;
    showInternalBooking: boolean;
    mandatoryInternal: boolean;
    oncePerOrder: boolean;
    updateCustomer: boolean;
    bookingProductSessionId: number;
    hasRefCusColName: boolean;
    isEmail: boolean;
    listArrayJS: any[];
    hasHistory: boolean;
    hasPendingRequest: boolean;
    warningStatus: number;
    validateFunction: number;
    validateCompusoryWhenBooking: boolean;
    validateNote: string;
    reportColName: string;

    clear() {
        this.customerInfoId = 0;
        this.bookingCustomerId = 0;
        this.bookingProductSessionId = 0;
        this.productBookingFieldId = 0;
        this.bookingFieldId = 0;
        this.bookingFieldName = '';
        this.fieldType = 0;
        this.listArray = '';
        this.infoValue = '';
        this.orderNumber = 0;
        this.validateCompusoryWhenBooking = false;
        this.showOnlineBooking = false;
        this.mandatoryOnline = false;
        this.showInternalBooking = false;
        this.mandatoryInternal = false;
        this.oncePerOrder = false;
        this.updateCustomer = false;
        this.hasRefCusColName = false;
        this.isEmail = false;
        this.hasHistory = false;
        this.hasPendingRequest = false;
        this.validateNote = '';
        this.warningStatus = 0;
        this.validateFunction = 0;
        this.reportColName = '';
    }

    static asFormGroup(model: CustomerInfoModel): FormGroup {
        const fg = new FormGroup({
            _id: new FormControl(model.customerInfoId),
            customerInfoId: new FormControl(model.customerInfoId),
            bookingFieldId: new FormControl(model.bookingFieldId),
            bookingCustomerId: new FormControl(model.bookingCustomerId),
            bookingFieldName: new FormControl(model.bookingFieldName),
            fieldType: new FormControl(model.fieldType),
            listArray: new FormControl(model.listArray),
            infoValue: new FormControl(model.infoValue),
            mandatoryInternal: new FormControl(model.mandatoryInternal),
            hasHistory: new FormControl(model.hasHistory),
            hasPendingRequest: new FormControl(model.hasPendingRequest),
        });
        return fg;
    }
}
