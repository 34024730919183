import { BaseModel } from '../core/_base/crud';


export class TripModel extends BaseModel {
    tripId: number;
    tripStatus: number;
    productSessionId: number;
    fromDate: '';
    toDate: '';
    notes: string;
    createdBy: number;
    createdDate: '';
    productName: '';
    productCode: '';
    orderCount: number;
    participantCount: number;
    isAllDay: boolean;

    clear() {
        this.tripId = 0;
        this.tripStatus = 0;
        this.productSessionId = 0;
        this.fromDate = '';
        this.toDate = '';
        this.notes = '';
        this.createdDate = '';
        this.createdBy = 0;
        this.productName = '';
        this.productCode = '';
        this.orderCount = 0;
        this.participantCount = 0;
        this.isAllDay = false;
    }
}
