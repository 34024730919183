import { BaseModel } from '../core/_base/crud';


export class MessageLogModel extends BaseModel {
    messageLogId: number;
    messageTemplateId: number;
    messageRenderStatus: number;
    messageMethod: number;
    messageSendStatus: number;
    messageOpenStatus: number;
    sentDate: string;
    toAddress: string;
    objectType: number;
    objectId: number;
    createdDate: string;
    createdBy: number;
    updatedDate: string;
    updatedBy: number;
    orderNumber: string;
    messageTemplateName: string;
    retryCount: number;
    notes: string;
    sentBy: number;
    sendFrom: string;
    subject: string;
    body: string;
    attachment: string;

    clear(): void {
        this.messageLogId = 0;
        this.messageTemplateId = 0;
        this.sentDate = '';
        this.messageRenderStatus = 0;
        this.messageMethod = 0;
        this.messageSendStatus = 0;
        this.messageOpenStatus = 0;
        this.toAddress = '';
        this.objectType=0;
        this.objectId = 0;
        this.createdDate = '';
        this.createdBy = 0;
        this.updatedDate = '';
        this.updatedBy = 0;
        this.orderNumber = '';
        this.messageTemplateName = '';
        this.retryCount = 0;
        this.notes = '';
        this.sentBy = 0;
        this.sendFrom = '';
        this.subject = '';
        this.body = '';
        this.attachment = '';
       
        
    }
}
