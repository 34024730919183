import { BaseModel } from '../core/_base/crud';


export class CurrencyModel extends BaseModel {
    currencyId: number;
    currencyStatus: number;
    currencyName: string;
    currencyCode: string;
    currencySymbol: string;
    symbolPosition: number;
    isMainCurrency: number;
    rate: number;
    language: string;
    createdDate: string;
    createdBy: number;
    updatedDate: string;
    updatedBy: number;

    clear() {
        this.currencyId = 0;
        this.currencyStatus=0;
        this.currencyName = '';
        this.language = '';
        this.currencyCode = '';
        this.currencySymbol = '';
        this.symbolPosition = 0;
        this.isMainCurrency = 0;
        this.rate = 0;
        this.createdDate='';
        this.createdBy=0;
        this.updatedDate='';
        this.updatedBy = 0;
    }
}
