import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { MaytechListComponent } from '../../../../../../core/_maytech/maytech-list.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../../../core/_base/layout';
import { LayoutUtilsService, QueryParamsModel } from '../../../../../../core/_base/crud';
import { AppState } from '../../../../../../core/_reducers';
import { MaytechTenantService } from '../../../../../../core/_maytech/maytech.tenant.service';
import { Store } from '@ngrx/store';
import { SelectionModel } from '@angular/cdk/collections';
import { Userdevice } from '../../../../../../models/userdevice.model';
import { DevicesDataSource, DevicesPageRequested } from '../../../../../../core/auth';
import { CommonStatus } from '../../../../../../core/_utils/define';
import { TranslateService } from '@ngx-translate/core';
import { DeviceLogout, RemoveAllDeviceStore } from '../../../../../../core/auth/_actions/device.actions';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'mt-user-devices',
    templateUrl: './user-devices.component.html',
    styleUrls: ['./user-devices.component.css']
})
/** user-devices component*/
export class UserDevicesComponent extends MaytechListComponent {
    // Incoming data
    @Input() userId: number;
    @Input() isReadonly: boolean;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    /** user-devices ctor */
    constructor(
        translate: TranslateService,
        dialog: MatDialog,
        activatedRoute: ActivatedRoute,
        router: Router,
        subheaderService: SubheaderService,
        layoutUtilsService: LayoutUtilsService,
        store: Store<AppState>,
        m: MaytechTenantService) {
        super(translate, dialog, activatedRoute, router, subheaderService, layoutUtilsService, store, m);
        this.actionPrefix = 'userdevices';        
    }
    displayedColumns = ['loginDate', 'ipaddress'];
    selection = new SelectionModel<Userdevice>(true, []);

    ngOnInit() {
        if (!this.isReadonly) {
            this.displayedColumns.push('actions');
        }
        this.store.dispatch(new RemoveAllDeviceStore(this.actionPrefix));
        super.ngOnInit();
    }

    filterConfiguration(): string {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;
        filter.keyword = searchText;
        filter.userId = this.userId;
        return filter;
    }

    initDatasource() {
        this.dataSource = new DevicesDataSource(this.store);
    }

    GetPageRequestedEvent(queryParams: QueryParamsModel): any {
        return new DevicesPageRequested(this.actionPrefix, { page: queryParams });
    }

    GetStatusLabel(status: number): string {
        return CommonStatus[(<CommonStatus>status)];
    }

    disconnectDevice(_item: Userdevice) {
        const _title: string = this.translate.instant("GENERAL.INFORM");
        const _description: string = this.translate.instant("USER_MANAGEMENT.DEVICE.LOGOUT_CONFIRM");
        const dialogRef = this.layoutUtilsService.showConfirmDialog(_title, _description);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.store.dispatch(new DeviceLogout(this.actionPrefix, { obj: _item }));
        });
    }
}
