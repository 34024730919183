import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, BaseModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BookingServiceModel } from '../../../models/bookingservice.model';
import { ListObjectIDModel } from '../../../models/listobject.model';
// Real REST API
@Injectable()
export class BookingServiceService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/bookingservice';
    }

    getTmpListObjectByParentID(productSessionId: number): Observable<BaseModel[]> {
        const url = `${this.API_URL}/getTmpListObjectByParentID/${productSessionId}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<BaseModel[]>(url, { headers: httpHeaders });
    }

    updateServices(bookingProductSessionId: number, objects: BaseModel[], currencyId: number, catchErr = true): Observable<QueryResultsModel> {
        const url = `${this.API_URL}/${currencyId}/${bookingProductSessionId}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        if (catchErr) {
            return this.http.put<QueryResultsModel>(url, objects, { headers: httpHeaders })
                .pipe(catchError(this.handleError("updateServices", []))
                );
        }
        return this.http.put<QueryResultsModel>(url, objects, { headers: httpHeaders });
    }

    updateBookingServices(bookingProductSessionID: number, objs: any[] = []): Observable<any> {
        const url = this.API_URL + "/UpdateBookingServices/" + bookingProductSessionID.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, { objs: objs }, { headers: httpHeaders });
    }
}
