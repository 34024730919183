import { BaseModel } from '../core/_base/crud';


export class DictionaryModel extends BaseModel {
    dictionaryId: number;
    dictionaryStatus: number;
    name: string;
    language: string;
    content: string;
    createdDate: string;
    createdBy: number;
    updatedDate: string;
    updatedBy: number;

    clear() {
        this.dictionaryId = 0;
        this.content = '';
        this.dictionaryStatus=0;
        this.name = '';
        this.language = '';
        this.createdDate='';
        this.createdBy=0;
        this.updatedDate='';
        this.updatedBy = 0;
       
    }
}
