// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { each } from 'lodash';
import { User } from '../../../models/user.model';
import { OptionState } from '../_reducers/option.reducers';


export const selectOptionsState = createFeatureSelector<OptionState>('options');

export const selectOptionError = createSelector(
    selectOptionsState,
    optionState => {
        return { errorMessage: optionState.errorMessage, errorCode: optionState.errorCode, objectType: optionState.objectType }
    }
);
