// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../_base/crud';
// Models
import { BaseModel } from '../_base/crud';
import { ActivatedRoute } from '@angular/router';
import { MaytechTenantService } from './maytech.tenant.service';
import { AppState } from '../_reducers';
import { Store } from '@ngrx/store';
import { catchError, retry, filter } from 'rxjs/operators';
import { MaytechBaseAction, ObjectErrorAction } from './maytech.actions';
import { ObjectType, HttpAPICallType } from '../_utils/define';
import { ListObjectIDModel } from '../../models/listobject.model';


// Real REST API
//@Injectable()
export class MaytechService {

    public API_BASE_URL: string = '';
    public API_URL: string = '';
    public actionPrefix: string = '';
    public objectType: ObjectType = ObjectType.Any;


    lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel('', 'asc', '', 0, 10));

    constructor(public http: HttpClient,
        protected httpUtils: HttpUtilsService,
        private route: ActivatedRoute,
        public store: Store<AppState>,
        private tenantService: MaytechTenantService) {

        let clientId: string = this.route.snapshot.paramMap.get('tenant');
        if (!clientId) {
            var parsedUrl = new URL(window.location.href);
            var baseUrl = parsedUrl.origin;
            clientId = baseUrl;
        }
        this.tenantService.getClient(clientId);
        if (tenantService.currentClient != null) {
            this.API_BASE_URL = tenantService.currentClient.apiurl;
            this.API_URL = tenantService.currentClient.apiurl;
        }
    }

    // CREATE =>  POST: add a new product to the server
    createObject(obj: BaseModel, catchErr = true): Observable<BaseModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        if (catchErr) {
            return this.http.post<BaseModel>(this.API_URL, obj, { headers: httpHeaders }).pipe(catchError(this.handleError("createObject", []))
            );
        }
        return this.http.post<BaseModel>(this.API_URL, obj, { headers: httpHeaders });
    }

    // READ
    getAllObjects(catchErr = true): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        if (catchErr) {
            return this.http.get<QueryResultsModel>(this.API_URL + '/getall', { headers: httpHeaders })
                .pipe(catchError(this.handleError("getAllObjects", []))
            );
        }
        return this.http.get<QueryResultsModel>(this.API_URL + '/getall', {headers: httpHeaders});
    }

    getObjectById(objId: number, catchErr = true): Observable<BaseModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/${objId}`;
        if (catchErr) {
            return this.http.get<BaseModel>(url, { headers: httpHeaders })
                .pipe(catchError(this.handleError("getObjectById", [])));
        }
        return this.http.get<BaseModel>(url, { headers: httpHeaders });

    }
    //getObjectByIdNew(objId: number): Observable<BaseModel> {
    //    const httpHeaders = this.httpUtils.getHTTPHeaders();
    //    const url = this.API_URL + `/${objId}`;
    //    return this.http.get<BaseModel>(url, {
    //        headers: httpHeaders
    //    });

    //}

    // Server should return filtered/sorted result
    findObjects(queryParams: QueryParamsModel, catchErr = true): Observable<QueryResultsModel> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = this.API_URL;
        if (catchErr) {
            return this.http.get<QueryResultsModel>(url, { headers: httpHeaders, params: httpParams })
                .pipe(catchError(this.handleError("findObjects", []))
            );
        }
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders, params: httpParams });
    }

    // UPDATE => PUT: update the object on the server
    updateObject(obj: BaseModel, catchErr = true): Observable<BaseModel> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        if (catchErr) {
            return this.http.put<BaseModel>(this.API_URL, obj, { headers: httpHeaders })
                .pipe(catchError(this.handleError("updateObject", []))
            );
        }
        return this.http.put<BaseModel>(this.API_URL, obj, { headers: httpHeaders })
    }
    //updateObjectNew(object: BaseModel): Observable<BaseModel> {
    //    // Note: Add headers if needed (tokens/bearer)
    //    const httpHeaders = this.httpUtils.getHTTPHeaders();
    //    return this.http.put<BaseModel>(this.API_URL, object, { headers: httpHeaders }).pipe(catchError(this.handleError("updateObjectNew", []))
    //    ).pipe(catchError(this.handleError("delete", []))
    //    );
    //}

    // UPDATE => PUT: update the objects on the server
    updateObjects(objects: BaseModel[], catchErr = true): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        if (catchErr) {
            return this.http.put<QueryResultsModel>(this.API_URL, objects, { headers: httpHeaders })
                .pipe(catchError(this.handleError("updateObjects", []))
                );
        }
        return this.http.put<QueryResultsModel>(this.API_URL, objects, { headers: httpHeaders });
    }

    // UPDATE Status
    // Comment this when you start work with real server
    // This code imitates server calls
    updateStatusForObject(ids: number[] = [], status: number, catchErr = true): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const body = {
            objectIds: ids,
            newStatus: status
        };
        const url = this.API_URL + '/updateStatus';
        if (catchErr) {
            return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders })
                .pipe(
                    retry(1),
                    catchError(this.handleError("updateStatusForObject", []))
            );
        }
        return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
    }

    // DELETE => delete the product from the server
    deleteObject(objId: number, catchErr = true): Observable<BaseModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = `${this.API_URL}/${objId}`;
        if (catchErr) {
            return this.http.delete<BaseModel>(url, { headers: httpHeaders })
                .pipe(catchError(this.handleError("deleteObject", []))
            );;
        }
        return this.http.delete<BaseModel>(url, { headers: httpHeaders });
    }

    deleteObjects(ids: number[] = [], catchErr = true): Observable<QueryResultsModel> {
        const url = this.API_URL;

        const options = {
            headers: this.httpUtils.getHTTPHeaders(),
            body: { objectIds: ids },
        };
        if (catchErr) {
            return this.http.delete<QueryResultsModel>(url, options)
                .pipe(catchError(this.handleError("deleteObjects", []))
            );
        }
        return this.http.delete<QueryResultsModel>(url, options);
    }

    getTextFile(filename: string, baseurl = false) {
        if (baseurl)
            return this.http.get(this.API_BASE_URL.replace('/api', '') + filename, { responseType: 'text' });
        else
            return this.http.get(filename, { responseType: 'text' });
    }

    //quickSearch(textkey: string) {
    //    const httpHeaders = this.httpUtils.getHTTPHeaders();
    //    return this.http.post<SearchModel[]>(this.API_URL, textkey, { headers: httpHeaders }).pipe(catchError(this.handleError("quickSearch", [])));
    //}

    updateWidgetCss(url: string, content: string): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const fullurl = this.API_BASE_URL + url;
        content = content.replace(/"/g, '\\"');
        return this.http.post<boolean>(fullurl, `\"${content}\"`, { headers: httpHeaders });
    }

    uploadFile(formData: FormData) {
        const httpHeaders = this.httpUtils.getAuthHTTPHeaders();
        const url = `${this.API_BASE_URL}/documents/upload`;
        return this.http.post(url, formData, { headers: httpHeaders }).pipe(catchError(this.handleError("uploadFile", [])));
    }

    ////////handle error
    getErrorDispatcher(error: any, objectType: ObjectType = ObjectType.Any, httpAPICallType: HttpAPICallType = HttpAPICallType.GetList): MaytechBaseAction {
        return new ObjectErrorAction(this.actionPrefix, {
            errorMessage: error.message,
            errorCode: error.status,
            objectType: (objectType != ObjectType.Any) ? objectType:this.objectType
        });
    }

    public handleError<T>(operation: string = 'operation', result?: any, objectType: ObjectType = ObjectType.Any) {
        return (error: any): Observable<any> => {

            let message = "API Error!!!";
            if (error.error && error.error.errorMessage)
                message = error.error.errorMessage;
            else if (error.errorMessage)
                message = error.errorMessage;
            console.error(`Operation: ${operation}\nObjectType: ${objectType}\nError Code: ${error.status}\nMessage: ${message}`); // log to console 
            this.store.dispatch(this.getErrorDispatcher(error, objectType));

            // Let the app keep running by returning an empty result.
            return of();
        };
    }

    getTmpObjectByRefId(objId: number): Observable<BaseModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/getTmpObjectByRefId/${objId}`;
        return this.http.get<BaseModel>(url, {
            headers: httpHeaders
        }).pipe(catchError(this.handleError("getTmpObjectByRefId", []))
        );
    }

    getListObjectID(parentID: number, catchErr = true): Observable<any> {
        const url = `${this.API_URL}/getListObjectID/${parentID}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        if (catchErr) {
            return this.http.get<ListObjectIDModel>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getListObjectID", [])));
        }
        return this.http.get<ListObjectIDModel>(url, { headers: httpHeaders });
    }

    getListObjectByParentID(parentID: number): Observable<BaseModel[]> {
        const url = `${this.API_URL}/getListObjectByParentID/${parentID}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<BaseModel[]>(url, { headers: httpHeaders });
    }
}
