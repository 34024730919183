import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel, BaseModel } from '../../_base/crud';
import { OptionModel } from '../../../models/option.model';
import { OptionActions, OptionActionTypes } from '../_actions/option.actions';
import { ObjectState } from '../../_maytech/maytech.reducers';
import { HttpAPICallType, ObjectType } from '../../_utils/define';

export interface OptionState extends ObjectState<BaseModel> {

}

export const adapter: EntityAdapter<BaseModel> = createEntityAdapter<BaseModel>({
    selectId: (obj: BaseModel) => obj._id,
});

export const initialOptionState: OptionState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel(''),
    lastCreatedObjectId: undefined,
    lastUpdatedObjectId: undefined,
    lastDeletedObjectId: undefined,
    showInitWaitingMessage: true,
    httpAPICallType: undefined,
    objectType: undefined,
    errorMessage: '',
    errorCode: 0
});

export function optionReducer(actionPrefix: string) {
    function theOptionReducer(state = initialOptionState, action: OptionActions): OptionState {
        switch (action.type) {
            case OptionActionTypes.OptionPageToggleLoading: return {
                ...state, listLoading: action.payload.isLoading, lastUpdatedObjectId: undefined
            };
            case OptionActionTypes.OptionActionToggleLoading: return {
                ...state, actionsloading: action.payload.isLoading
            };
            case OptionActionTypes.OptionOnServerUpdated: return {
                ...state
            };
            case OptionActionTypes.OptionUpdated: {
                const _partialOptions: Update<BaseModel>[] = [];
                for (let i = 0; i < action.payload.objs.length; i++) {
                    _partialOptions.push({
                        id: (<BaseModel>action.payload.objs[i])._id,
                        changes: action.payload.objs[i]
                    });
                }
                return adapter.updateMany(_partialOptions, {
                    ...state,
                    lastUpdatedObjectId: action.payload.objs[0]._id,
                    httpAPICallType: HttpAPICallType.Update,
                    objectType: ObjectType.Option,
                    actionsloading: false
                });
            };
            case OptionActionTypes.OptionPageLoaded:
                return adapter.addMany(<OptionModel[]>action.payload.objs, {
                    ...initialOptionState,
                    totalCount: action.payload.totalCount,
                    listLoading: false,
                    lastQuery: action.payload.page,
                    showInitWaitingMessage: false
                });
            case OptionActionTypes.OptionErrorAction: return {
                ...state,
                listLoading: false,
                actionsloading: false,
                errorMessage: action.payload.obj.errorMessage,
                errorCode: action.payload.obj.errorCode,
                objectType: ObjectType.Option
            };
            case OptionActionTypes.ClearOptionError: return {
                ...state,
                errorCode: undefined,
                errorMessage: ''
            };
            default: return state;
        }
    }
    return (state = initialOptionState, action: OptionActions) => {
        return theOptionReducer(state, action);
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
