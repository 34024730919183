// Angular
import { Injectable } from '@angular/core';
// RxJS
import { of, Observable, defer, forkJoin } from 'rxjs';
import { mergeMap, map, withLatestFrom, filter, tap } from 'rxjs/operators';
// NGRX
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// State
import { AppState } from '../../../core/_reducers';
import { ObjectEffects } from '../../_maytech/maytech.effects';
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
import { DeviceService } from '../_services';
import { DeviceActionTypes, DevicesPageRequested, DevicesPageLoaded } from '..';
import { DeviceLogout, DevicesPageToggleLoading } from '../_actions/device.actions';

@Injectable()
export class DeviceEffects extends ObjectEffects {
    constructor(actions: Actions, service: DeviceService, store: Store<AppState>) {
        super(actions, service, store);
        this.actionPrefix = 'userdevices';

    }
    showPageLoadingDistpatcher = new DevicesPageToggleLoading(this.actionPrefix, { isLoading: true });
    showLoadingDistpatcher = new DevicesPageToggleLoading(this.actionPrefix, { isLoading: true });
    hideActionLoadingDistpatcher = new DevicesPageToggleLoading(this.actionPrefix, { isLoading: false });

    
    loadDevicesPage$ = createEffect(() => this.actions$.pipe(
        ofType<DevicesPageRequested>(DeviceActionTypes.DevicesPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.objsService.findObjects(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new DevicesPageLoaded(this.actionPrefix, {
                objs: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
        })
    ));
    
    logoutDevice$ = createEffect(() => this.actions$.pipe(
        ofType<DeviceLogout>(DeviceActionTypes.DeviceLogout),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showLoadingDistpatcher);
            return this.objsService.updateObject(payload.obj);
        }),
        map(() => {
            return this.hideActionLoadingDistpatcher;
        }),
    ));
}
