import { BaseModel } from '../core/_base/crud';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ObjectPriceGroupListModel } from './objectpricegrouplist.model';


export class BookingServiceModel extends BaseModel {
    bookingServiceId: number;
    serviceId: number;
    bookingProductSessionId: number;
    currencyId: number;
    serviceName: string;
    quantity: number;
    amount: number;
    createdDate: string;
    createdBy: number;
    availability: number;
    objectPriceId: number;
    subGroupId: number;
    label: string;
    labelEnglish: string;
    quantityUsed: number;
    minQuantity: number;
    maxQuantity: number;
    refPrices: ObjectPriceGroupListModel[];

    clear() {
        this.bookingServiceId = 0;
        this.serviceId = 0;
        this.bookingProductSessionId = 0;
        this.currencyId = 0;
        this.serviceName = '';
        this.quantity = 0;
        this.amount = 0;
        this.createdDate = '';
        this.createdBy = 0;
        this.availability = -1;
        this.objectPriceId = 0;
        this.subGroupId = 0;
        this.label = '';
        this.labelEnglish = '';
        this.quantityUsed = 0;
        this.minQuantity = 0;
        this.maxQuantity = 0;
        this.refPrices = null;
    }

    static asFormGroup(model: BookingServiceModel): FormGroup {
        const fg = new FormGroup({
            bookingServiceId: new FormControl(model.bookingServiceId),
            serviceId: new FormControl(model.serviceId),
            bookingProductSessionId: new FormControl(model.bookingProductSessionId),
            currencyId: new FormControl(model.currencyId),
            serviceName: new FormControl(model.serviceName),
            quantity: new FormControl(model.quantity),
            amount: new FormControl(model.amount),
            createdDate: new FormControl(model.createdDate),
            createdBy: new FormControl(model.createdBy),
            availability: new FormControl(model.availability > 0 ? model.availability : 10),
            objectPriceId: new FormControl(model.objectPriceId),
            subGroupId: new FormControl(model.subGroupId),
            label: new FormControl(model.label),
            labelEnglish: new FormControl(model.labelEnglish),
            quantityUsed: new FormControl(model.quantityUsed),
            minQuantity: new FormControl(model.minQuantity),
            maxQuantity: new FormControl(model.maxQuantity)
        });
        return fg;
    }
}
