// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material

import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription, BehaviorSubject } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../_reducers';
// UI
import { SubheaderService } from '../_base/layout';
// CRUD
import { LayoutUtilsService, QueryParamsModel, AlertMessageType, QueryResultsModel, BaseDataSource } from '../_base/crud';
import { ObjectsDataSource } from './maytech.datasource';
import { selectObjectsPageLastQuery, selectError } from './maytech.selectors';
import { OneObjectDeleted, ManyObjectsDeleted, ObjectsStatusUpdated, ObjectsPageRequested } from './maytech.actions';
import { BaseModel } from '../_base/crud';
import { MaytechComponent } from './maytech.component';
import { MaytechTenantService } from './maytech.tenant.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { MaytechService } from './maytech.service';
import { ResultMessageType } from '../_utils/define';
import { HelpEventService } from '../../views/partials/layout/help/help-event.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { MaytechSimpleDataSource } from './maytech-simple.datasource';



@Directive()
export class MaytechSimpleListComponent extends MaytechComponent implements OnInit, OnDestroy {
    // Table fields
    //dataSource: ObjectsDataSource;
    displayedColumns = [];
    listPageSize = 10;
    listPageSizeOptions = [10, 20, 50, 100];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    dataList = new MaytechSimpleDataSource();
    loadingSubject = new BehaviorSubject<boolean>(true);
    loading$: Observable<boolean>;
    isLoading = false;

    filterStatus: string = '';
    filterCondition: string = '';
    lastQuery: QueryParamsModel;
    // Selection
    selection = new SelectionModel<BaseModel>(true, []);

    subscriptions: Subscription[] = [];
    editUrl: string = '';
    actionPrefix: string = '';
    disabledEdit = false;

    activatedRow = null;

    constructor(
        protected helpEventService: HelpEventService,
        protected permissionsService: NgxPermissionsService,
        public translate: TranslateService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected layoutUtilsService: LayoutUtilsService,
        public cdr: ChangeDetectorRef,
        protected maytechDataService: MaytechService,
        protected tenantService: MaytechTenantService) {
        super(tenantService);
    }
    //initDatasource() {
    //    this.dataSource = new ObjectsDataSource(this.store,'');
    //}
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.loading$ = this.loadingSubject.asObservable();
        this.dataList.loading$ = this.loading$;
        this.dataList.isPreloadTextViewed$ = this.loading$;
        this.setLoading(true);
        super.ngOnInit();
        // If the user changes the sort order, reset back to the first page.
        if (this.sort) {
            const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
            this.subscriptions.push(sortSubscription);

            const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
                .pipe(tap(() => this.loadObjectsList()))
                .subscribe();
            this.subscriptions.push(paginatorSubscriptions);
        }
        // Filtration, bind to searchInput
        if (this.searchInput) {
            this.subscriptions.push(
                fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
                    debounceTime(500),
                    distinctUntilChanged(),
                    tap(() => {
                        this.fitler();
                    })).subscribe()
            );
        }
        // Set title to page breadCrumbs
        //this.subheaderService.setTitle('Objects');
        this.loadObjectsList();
        this.selection.clear();
    }

    fitler() {
        this.paginator.pageIndex = 0;
        this.loadObjectsList();
    }

    showErrorLoadingList(message: string, code = 0) {
        if (message && message != "") {
            if (code === 401) {
                message = `${this.translate.instant("AUTH.VALIDATION.UNAUTHORIZED")}`;
            } else {
                message = `${this.translate.instant("GENERAL.ERROR")}`;
            }
            this.layoutUtilsService.showActionNotification(message, AlertMessageType.Read, 3000, true, false);
        }
    }
    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    /**
     * Load Objects List
     */
    loadObjectsList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.getFilter(),
            this.sort ? this.sort.direction : null,
            this.sort ? this.sort.active : null,
            this.paginator ? this.paginator.pageIndex : null,
            this.paginator ? this.paginator.pageSize : null
        );

        this.setLoading(true);

        this.subscriptions.push(
            this.maytechDataService.findObjects(queryParams, false).subscribe(res => {
                if (res) {// success
                    this.bindDataToList(res);
                }
                this.setLoading(false);
            }, err => {
                this.setLoading(false);
                this.onDataServiceError(err);
            })
        );

        this.selection.clear();
    }

    getFilter(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;
        filter.keyword = searchText;
        return filter;
    }
    onCustomValidation(_object: BaseModel): boolean {
        return true;
    }
    onSubmitFail() {
        //this.hasFormErrors = true;
        //this.cdr.detectChanges();
    }
    onUpdateDataSuccess() {
        this.showMessage(ResultMessageType.UPDATED);
    }
    onDataServiceError(err) {
        this.showMessage(ResultMessageType.LOAD_LIST_ERROR);
    }

    showMessage(resultMessage: ResultMessageType) {
        const message = this.getResultMessageTypeLabel(resultMessage);
        this.layoutUtilsService.showActionNotification(message, AlertMessageType.Update, 5000, true, true);
    }
    getResultMessageTypeLabel(resultMessageType: number): string {
        return this.translate.instant("ENUM.ResultMessageType." + ResultMessageType[(<ResultMessageType>resultMessageType)]);
    }
    bindDataToList(res: QueryResultsModel) {
        this.dataList.paginatorTotalSubject.next(res.totalCount);
        this.dataList.dataSource.data = res.items;
        this.selection.clear();
        this.cdr.detectChanges();
    }

    restoreState(queryParams: QueryParamsModel, id: number) {
        if (!queryParams.filter) {
            return;
        }
    }

    /** ACTIONS */
    /**
     * Delete customer
     *
     * @param _item: BaseModel
     */
    deleteObject(_item: BaseModel, _deleteMessage = '') {
        this.setLoading(true);
        //this.subscriptions.push(
        this.maytechDataService.deleteObject(_item._id).toPromise().then(res => {
            this.setLoading(false);
            if (res) {// success
                this.loadObjectsList();
                if (_deleteMessage != '') {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, AlertMessageType.Delete);
                }
            }
        }, err => {
            this.setLoading(false);
            this.onDataServiceError(err);
        });

        this.selection.clear();
    }

    /** ACTIONS */
    /**
     * Delete customer
     *
     * @param _item: BaseModel
     */
    deleteObjectNew(_item: BaseModel, data: any) {
        const dialogRef = this.layoutUtilsService.deleteElement(data._title, data._description, data._waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.setLoading(true);
            //this.subscriptions.push(
            this.maytechDataService.deleteObject(_item._id).toPromise().then(res => {
                this.setLoading(false);
                if (res) {// success
                    this.loadObjectsList();
                    this.layoutUtilsService.showActionNotification(data._deleteMessage, AlertMessageType.Delete);
                } else {
                    if (data._deleteMessageFailed) {
                        this.layoutUtilsService.showActionNotification(data._deleteMessageFailed, AlertMessageType.Delete);
                    } else {
                        this.layoutUtilsService.showActionNotification(this.translate.instant("GENERAL.DELETE_FAILED"), AlertMessageType.Delete);
                    }
                }
                this.afterDelete();
            }, err => {
                this.setLoading(false);
                this.onDataServiceError(err);
            });

            this.selection.clear();
        });
    }

    afterDelete() { }
    /**
     * Delete customers
     */
    deleteObjects(_deleteMessage = '') {
        this.setLoading(true);
        const idsForDeletion: number[] = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.selection.selected.length; i++) {
            idsForDeletion.push(this.selection.selected[i]._id);
        }
        this.maytechDataService.deleteObjects(idsForDeletion).toPromise().then(res => {
            this.setLoading(false);
            if (res) {// success
                this.loadObjectsList();
                if (_deleteMessage != '') {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, AlertMessageType.Delete);
                }
            }
        }, err => {
            this.setLoading(false);
            this.onDataServiceError(err);
        });

        this.selection.clear();
    }

    /**
     * Delete customers
     */
    deleteObjectsNew(data) {
        const dialogRef = this.layoutUtilsService.deleteElement(data._title, data._description, data._waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.setLoading(true);
            const idsForDeletion: number[] = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.selection.selected.length; i++) {
                idsForDeletion.push(this.selection.selected[i]._id);
            }
            this.maytechDataService.deleteObjects(idsForDeletion).toPromise().then(res => {
                this.setLoading(false);
                if (res) {// success
                    this.loadObjectsList();
                    var msg = this.translate.instant("GENERAL.DELETED") + " " + res.totalCount + " " + data._deletedObj;
                    this.layoutUtilsService.showActionNotification(msg, AlertMessageType.Delete);
                } else {
                    if (data._deleteMessageFailed) {
                        this.layoutUtilsService.showActionNotification(data._deleteMessageFailed, AlertMessageType.Delete);
                    } else {
                        this.layoutUtilsService.showActionNotification(this.translate.instant("GENERAL.DELETE_FAILED"), AlertMessageType.Delete);
                    }
                }
            }, err => {
                this.setLoading(false);
                this.onDataServiceError(err);
            });
            this.selection.clear();
        });
    }

    /**
     * Fetch selected customers
     */
    fetchObjects() {
        // tslint:disable-next-line:prefer-const
        let messages = [];
        //this.selection.selected.forEach(elem => {
        //          messages.push({
        //              text: `${elem.customerName} ${elem.customerStatus}`,
        //		id: elem.customerId,
        //		status: elem.customerStatus
        //	});
        //});
        this.layoutUtilsService.fetchElements(messages);
    }

    /**
     * Update status dialog
     */
    updateStatusForObjects(status: number) {

        const ids: number[] = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.selection.selected.length; i++) {
            ids.push(this.selection.selected[i]._id);
        }
        this.maytechDataService.updateStatusForObject(ids, status).toPromise().then(res => {
            if (res) {// success
                this.loadObjectsList();
            }
            this.setLoading(false);
        }, err => {
            this.setLoading(false);
            this.onDataServiceError(err);
        });

    }

    editObject(id) {
        this.router.navigate([this.editUrl, id], { relativeTo: this.activatedRoute });
    }

    createObject() {
        this.router.navigateByUrl(this.editUrl);
    }

    /**
     * Check all rows are selected
     */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataList.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataList.dataSource.data.forEach(row => {
                this.selection.select(row);
            });
        }
    }

    getItemStatusString(status: number = 0): string {
        switch (status) {
            case 0:
                return 'Deactive';
            case 1:
                return 'Active';
        }
        return '';
    }

    /**
     * Returns CSS Class by status
     *
     * @param status: number
     */
    getItemCssClassByStatus(status: number = 0): string {
        switch (status) {
            case 0:
                return 'metal';
            case 1:
                return 'confirmed';
            case 2:
                return 'danger';
        }
        return '';
    }

    /**
     * Rerurns condition string
     *
     * @param condition: number
     */
    getItemConditionString(condition: number = 0): string {
        switch (condition) {
            case 0:
                return 'New';
            case 1:
                return 'Used';
        }
        return '';
    }
    setLoading(loading: boolean) {

        if (this.isLoading != loading) {
            this.loadingSubject.next(loading);
            this.isLoading = loading
        }
    }
    /**
     * Returns CSS Class by condition
     *
     * @param condition: number
     */
    getItemCssClassByCondition(condition: number = 0): string {
        switch (condition) {
            case 0:
                return 'accent';
            case 1:
                return 'primary';
        }
        return '';
    }

    //matRowClicked(row) {
    //    this.activatedRow = row;
    //}

    //actionMenuClosed() {
    //    this.activatedRow = null;
    //}

    showHelpContext(controlName: string) {
        this.helpEventService.showHelpContent(controlName);
    }
}
