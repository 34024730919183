import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Real REST API
@Injectable()
export class PaymentService extends MaytechService{
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);
        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/payment';
    }

    getPaymentsByBookingId(bookingId: number): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/GetPaymentsByBookingId/${bookingId}`;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders
        });
    }
}
