import { BaseModel } from '../core/_base/crud';


export class BookingCustomerModel extends BaseModel {
    bookingCustomerId: number;
    bookingProductSessionId: number;
    customerId: number;

    clear() {
        this.bookingCustomerId=0;
        this.bookingProductSessionId=0;
        this.customerId = 0;
    }
}
