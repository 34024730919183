import { BaseModel } from "../core/_base/crud";
import { ObjectPriceModel } from "./objectprice.model";

export class TaxFeeModel extends BaseModel {
    taxFeeId: number;
    taxFeeName: string;
    taxFeeDesc: string;
    taxFeeType: number;
    taxFeeStatus: number;
    valueType: number;
    value: number;
    values: ObjectPriceModel[];
    isInclusive: boolean;
    isCompound: boolean;
    paymentTypes: string;
    createdDate: Date;
    createdBy: number;
    updatedDate: Date;
    updatedBy: number;

    clear(): void {
        this.taxFeeId = 0;
        this.taxFeeName = '';
        this.taxFeeDesc = '';
        this.taxFeeType = 0;
        this.taxFeeStatus = 0;
        this.valueType = 0;
        this.isInclusive = false;
        this.isCompound = false;
        this.paymentTypes = "";
        this.createdDate = new Date();
        this.createdBy = 0;
        this.values = [];
        this.value = 0;
    }
}
