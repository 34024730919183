import { BaseModel } from '../core/_base/crud';
import { Permission } from '../core/auth';

export class User extends BaseModel {
    userId: number;
    userName: string;
    userEmail: string;
    userPassword: string;
    userFullName: string;
    userStatus: number;
    OtpcreatedDate: Date;
    createdBy: number;
    createdDate: Date;
    updatedBy: number;
    updatedDate: Date;
    tenantId: number;
    orderWebUrl: string;
    token: string;
    isExpired: boolean;
    roles: number[];
    permissions: number[];
    userPermissions: Permission[];
    currencyCode: string;

    clear(): void {
        this.userId = undefined;
        this.userName = '';
        this.userEmail = '';
        this.userPassword = '';
        this.userFullName = '';
        this.userStatus = undefined;
        this.OtpcreatedDate = undefined;
        this.createdBy = undefined;
        this.createdDate = undefined;
        this.updatedBy = undefined;
        this.updatedDate = undefined;
        this.tenantId = undefined;
        this.orderWebUrl = '';
        this.token = '';
        this.isExpired = false;
        this.roles = [];
        this.permissions = [];
        this.userPermissions = [];
        this.currencyCode = '';
    }
}
