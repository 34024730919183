import { BaseModel } from '../core/_base/crud';
import { FormGroup, FormControl } from '@angular/forms';


export class BookingInfoModel extends BaseModel {
    bookingInfoId: number;
    bookingId: number;
    productBookingFieldId: number;
    bookingFieldId: number;
    bookingFieldName: string;
    fieldType: number;
    listArray: string;
    infoValue: string;
    orderNumber: number;
    showOnlineBooking: boolean;
    mandatoryOnline: boolean;
    showInternalBooking: boolean;
    mandatoryInternal: boolean;
    oncePerOrder: boolean;
    isStandard: boolean;
    refCusColName: string;
    listArrayJS: any[];
    hasHistory: boolean;
    hasPendingRequest: boolean;

    clear() {
        this.bookingInfoId = 0;
        this.bookingId = 0;
        this.productBookingFieldId = 0;
        this.bookingFieldId = 0;
        this.bookingFieldName = '';
        this.fieldType = 0;
        this.listArray = '';
        this.infoValue = '';
        this.orderNumber = 0;
        this.showOnlineBooking = false;
        this.mandatoryOnline = false;
        this.showInternalBooking = false;
        this.mandatoryInternal = false;
        this.oncePerOrder = false;
        this.isStandard = false;
        this.refCusColName = '';
        this.hasHistory = false;
        this.hasPendingRequest = false;
    }

    static asFormGroup(model: BookingInfoModel): FormGroup {
        const fg = new FormGroup({
            _id: new FormControl(model.bookingInfoId),
            bookingInfoId: new FormControl(model.bookingInfoId),
            bookingId: new FormControl(model.bookingId),
            bookingFieldName: new FormControl(model.bookingFieldName),
            fieldType: new FormControl(model.fieldType),
            listArray: new FormControl(model.listArray),
            infoValue: new FormControl(model.infoValue),
            mandatoryInternal: new FormControl(model.mandatoryInternal),
            isStandard: new FormControl(model.isStandard),
            refCusColName: new FormControl(model.refCusColName),
            hasHistory: new FormControl(model.hasHistory),
            hasPendingRequest: new FormControl(model.hasPendingRequest),
        });
        return fg;
    }
}
