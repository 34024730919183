import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../_reducers';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';

// Real REST API
@Injectable()
export class ActionLogService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/actionlog';
    }
 
}
