import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../_reducers';
import { Observable } from 'rxjs';

// Real REST API
@Injectable()
export class CronJobService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService ) {

        super(http, httpUtils, route,store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/cronjob';
    }

    clearCronJobLog(cronJobId: number): Observable<any> {
        const url = this.API_URL + "/clearCronJobLog/" + cronJobId.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.delete<any>(url, { headers: httpHeaders });
    }

}
