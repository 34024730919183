// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/_reducers/';
import { isLoggedIn, currentUserPermissions } from '../_selectors/auth.selectors';
import { NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '../../../models/permission.model';

@Injectable()
export class AuthGuard implements CanActivate {
    isLoggedIn$: Observable<boolean>

    constructor(private store: Store<AppState>, private router: Router, private permissionsService: NgxPermissionsService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        this.isLoggedIn$ = this.store.pipe(select(isLoggedIn), take(1));
        this.isLoggedIn$.subscribe(res => {
            if (!res) {
                this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            } else {
                this.store.pipe(select(currentUserPermissions), take(1)).subscribe(pm => {
                    this.permissionsService.flushPermissions();
                    if (!pm || pm.length === 0) {
                        return;
                    }
                    pm.forEach((pm: Permission) => this.permissionsService.addPermission(pm.permissionCode));
                });
            }
        });

        return this.isLoggedIn$;
    }
}
