
import { OnInit, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { EditRequestStatus } from '../../../../../../core/_utils/define';
import { EditRequestModel } from '../../../../../../models/editrequest.model';
import { EditRequestApprovalModel } from '../../../../../../models/editrequestapproval.model';
import { EditRequestService } from '../../../../../../core/setting';
import { LayoutUtilsService, AlertMessageType } from '../../../../../../core/_base/crud';
import { EditRequestType } from '../../../../../../core/_utils/define';

@Component({
    selector: 'kt-approve-edit-dialog',
    templateUrl: './approve-edit-dialog.component.html'
})
export class ApproveEditDialogComponent implements OnInit {
    notes = new FormControl('');
    request: EditRequestModel;
    model: EditRequestApprovalModel;

    constructor(
        public dialogRef: MatDialogRef<ApproveEditDialogComponent>,
        private translate: TranslateService,
        private editRequestService: EditRequestService,
        private layoutUtilsService: LayoutUtilsService,
        public objectFB: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.data = data
        this.model = (this.data as EditRequestApprovalModel);
        if (this.model.editRequests.length == 1) {
            this.request = this.model.editRequests[0];
        }
    }

    ngOnInit() {
    }


    onYesClick(): void {
        this.model.notes = this.notes.value;
        this.editRequestService.updateObject(this.model).toPromise().then(success => {
            const successMsg = `${this.translate.instant("SETTING.EDITREQUEST.APPROVE_SUCCESSFULLY")}`;
            this.layoutUtilsService.showActionNotification(successMsg, AlertMessageType.Update, 5000, true, true);
            this.dialogRef.close(true);
        }, err => {
            console.log(err);
            const errMsg = `${this.translate.instant("SETTING.EDITREQUEST.APPROVE_FAILED")}`;
            this.layoutUtilsService.showActionNotification(errMsg, AlertMessageType.Update, 5000, true, true);
            this.dialogRef.close(false);
        });
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    get editRequestType() { return EditRequestType; }
}
