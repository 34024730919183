import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpUtilsService, BaseModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../_reducers';
import { ObjectType, HttpAPICallType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EditRequestModel } from '..';
import { Options } from 'selenium-webdriver/ie';
import { environment } from '../../../../environments/environment';

// Real REST API
@Injectable()
export class EditRequestService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/editrequest';
    }

    hasHistory(objectType: number, objectId: number, fieldName: string): Observable<boolean> {
        const url = this.API_URL + "/hasHistory/" + objectType.toString() + "/" + objectId.toString() + "/" + fieldName;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("hasHistory", []))
        );
    }

    hasPendingRequest(objectType: number, objectId: number, fieldName: string): Observable<boolean> {
        const url = this.API_URL + "/hasPendingRequest/" + objectType.toString() + "/" + objectId.toString() + "/" + fieldName;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("hasHistory", []))
        );
    }
}

