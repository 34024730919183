import { BaseModel } from '../core/_base/crud';

export class Role extends BaseModel {
    roleId: number;
    roleName: string;
    roleStatus: number;
    roleType: number;
    userCount: number;
    permissions: number[];
    createdBy: number;
    createdDate: Date;
    updatedBy: number;
    updatedDate: Date;

    clear(): void {
        this.roleId = undefined;
        this.roleName = '';
        this.roleStatus = undefined;
        this.roleType = undefined;
        this.userCount = undefined;
        this.permissions = [];
        this.createdBy = undefined;
        this.createdDate = undefined;
        this.updatedBy = undefined;
        this.updatedDate = undefined;
    }
}
