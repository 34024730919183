// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../_base/crud';
// Models
import { BaseModel } from '../_base/crud';
import { Update } from '@ngrx/entity';
import { ObjectType } from '../_utils/define';

export enum ObjectActionTypes {
    ObjectErrorAction = '[Object Component] Error Action',
    RemoveAllObjectStore = '[Object] RemoveAllObjectStore',
    ObjectOnServerCreated = '[Edit Object Component] Object On Server Created',
    ObjectCreated = '[Edit Object Component] Object Created',
    ObjectOnServerUpdated = '[Edit Object Component] Object On Server Updated',
    ObjectUpdated = '[Edit Object Component] Object Updated',
    ObjectsStatusUpdated = '[Objects List Page] Objects Status Updated',
    OneObjectDeleted = '[Objects List Page] One Object Deleted',
    ManyObjectsDeleted = '[Objects List Page] Many Selected Objects Deleted',
    ObjectsPageRequested = '[Objects List Page] Objects Page Requested',
    ObjectsPageLoaded = '[Objects API] Objects Page Loaded',
    ObjectsPageCancelled = '[Objects API] Objects Page Cancelled',
    ObjectsPageToggleLoading = '[Objects] Objects Page Toggle Loading',
    ObjectsActionToggleLoading = '[Objects] Objects Action Toggle Loading'
}

export interface MaytechBaseAction extends Action {
    actionPrefix: string;
}


export class ObjectErrorAction implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectErrorAction;
    constructor(public actionPrefix: string, public payload: { errorMessage: string, errorCode: number, objectType: ObjectType}) { }
}

export class RemoveAllObjectStore implements MaytechBaseAction {
    readonly type = ObjectActionTypes.RemoveAllObjectStore;
    constructor(public actionPrefix: string) { }
}

export class ObjectOnServerCreated implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectOnServerCreated;
    constructor(public actionPrefix: string,public payload: { obj: BaseModel }) { }
}

export class ObjectCreated implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectCreated;
    constructor(public actionPrefix: string,public payload: { obj: BaseModel }) { }
}

export class ObjectOnServerUpdated implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectOnServerUpdated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class ObjectUpdated implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectUpdated;
    constructor(public actionPrefix: string, public payload: {
        partialObj: Update<BaseModel>,
        obj: BaseModel
    }) { }
}

export class ObjectsStatusUpdated implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectsStatusUpdated;
   constructor(public actionPrefix: string, public payload: {
        objs: BaseModel[],
        status: number
    }) { }
}

export class OneObjectDeleted implements MaytechBaseAction {
    readonly type = ObjectActionTypes.OneObjectDeleted;
   constructor(public actionPrefix: string, public payload: { id: number }) {}
}

export class ManyObjectsDeleted implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ManyObjectsDeleted;
   constructor(public actionPrefix: string, public payload: { ids: number[] }) {}
}

export class ObjectsPageRequested implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectsPageRequested;
   constructor(public actionPrefix: string, public payload: { page: QueryParamsModel }) { }
}

export class ObjectsPageLoaded implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectsPageLoaded;
   constructor(public actionPrefix: string, public payload: { objs: BaseModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class ObjectsPageCancelled implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectsPageCancelled;
    constructor(public actionPrefix: string) { }

}

export class ObjectsPageToggleLoading implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectsPageToggleLoading;
   constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export class ObjectsActionToggleLoading implements MaytechBaseAction {
    readonly type = ObjectActionTypes.ObjectsActionToggleLoading;
   constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export type ObjectActions = ObjectErrorAction
    | RemoveAllObjectStore
    | ObjectOnServerCreated
    | ObjectCreated
    | ObjectOnServerUpdated
    | ObjectUpdated
    | ObjectsStatusUpdated
    | OneObjectDeleted
    | ManyObjectsDeleted
    | ObjectsPageRequested
    | ObjectsPageLoaded
    | ObjectsPageCancelled
    | ObjectsPageToggleLoading
    | ObjectsActionToggleLoading;
