import { Action } from '@ngrx/store';
import { User } from '../../../models/user.model';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { BaseModel, QueryResultsModel } from '../../_base/crud';
import { Update } from '@ngrx/entity';
import { ObjectType } from '../../_utils/define';
import { Permission } from '..';

export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    Register = '[Register] Action',
    UserRequested = '[Auth Api] User Requested',
    UserLoaded = '[Auth Api] User Loaded',
    UserPermissionRequested = '[Auth Api] User Permission Requested',
    UserPermissionLoaded = '[Auth Api] User Permission Loaded',
    AuthUserOnServerUpdated = '[Edit Profile Component] User On Server Updated',
    AuthUserUpdated = '[Edit Profile Component] User Updated',
    AuthUserErrorAction = '[Auth Component] Error Action',
    ClearAuthError = '[Auth Component] Clear Error'
}

export class AuthUserErrorAction implements MaytechBaseAction {
    readonly type = AuthActionTypes.AuthUserErrorAction;
    constructor(public actionPrefix: string, public payload: { obj: QueryResultsModel }) { }
}

export class ClearAuthError implements MaytechBaseAction {
    readonly type = AuthActionTypes.ClearAuthError;
    constructor(public actionPrefix: string) { }
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { authToken: string }) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class Register implements Action {
    readonly type = AuthActionTypes.Register;
    constructor(public payload: { authToken: string }) { }
}

export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User }) { }
}

export class UserPermissionRequested implements Action {
    readonly type = AuthActionTypes.UserPermissionRequested;
}

export class UserPermissionLoaded implements Action {
    readonly type = AuthActionTypes.UserPermissionLoaded;
    constructor(public payload: { permissions: Permission[] }) { }
}

export class AuthUserOnServerUpdated implements MaytechBaseAction {
    readonly type = AuthActionTypes.AuthUserOnServerUpdated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class AuthUserUpdated implements MaytechBaseAction {
    readonly type = AuthActionTypes.AuthUserUpdated;
    constructor(public actionPrefix: string, public payload: {
        partialUser: Update<BaseModel>,
        user: BaseModel
    }) { }
}

export type AuthActions = Login | Logout | Register
    | UserRequested | UserLoaded | UserPermissionRequested | UserPermissionLoaded
    | AuthUserOnServerUpdated | AuthUserUpdated
    | AuthUserErrorAction | ClearAuthError;
