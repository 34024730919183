// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel, BaseModel } from '../../_base/crud';
// Services
import { AuthService } from '../../../core/auth/_services';
// State
import { AppState } from '../../../core/_reducers';
import {
    UserActionTypes,
    UsersPageRequested,
    UsersPageLoaded,
    UserCreated,
    UserDeleted,
    UserUpdated,
    UserOnServerCreated,
    UsersActionToggleLoading,
    UsersPageToggleLoading,
    UserOnServerUpdated,
    UserErrorAction,
    UserOnServerDeleted
} from '../_actions/user.actions';
import { ObjectEffects } from '../../_maytech/maytech.effects';
import { UserService } from '../_services/user.service';
import { Update } from '@ngrx/entity';
import { User } from '../../../models/user.model';
import { ObjectType } from '../../_utils/define';

@Injectable()
export class UserEffects extends ObjectEffects{
    constructor(actions: Actions, service: UserService, store: Store<AppState>) {
        super(actions, service, store);
        this.actionPrefix = 'users';

    }
    showPageLoadingDistpatcher = new UsersPageToggleLoading(this.actionPrefix, { isLoading: true });
    hidePageLoadingDistpatcher = new UsersPageToggleLoading(this.actionPrefix, { isLoading: false });

    showActionLoadingDistpatcher = new UsersActionToggleLoading(this.actionPrefix, { isLoading: true });
    hideActionLoadingDistpatcher = new UsersActionToggleLoading(this.actionPrefix, { isLoading: false });

    
    loadUsersPage$ = createEffect(() => this.actions$
        .pipe(
            ofType<UsersPageRequested>(UserActionTypes.UsersPageRequested),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.objsService.findObjects(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new UsersPageLoaded(this.actionPrefix, {
                    objs: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        ));

    
    deleteUser$ = createEffect(() => this.actions$
        .pipe(
            ofType<UserOnServerDeleted>(UserActionTypes.UserOnServerDeleted),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                return this.objsService.deleteObject(payload.id);
            }),
            map(response => {
                if (response) {
                    const rsModel = (response as unknown as QueryResultsModel);
                    const errorMesg = rsModel.errorMessage;
                    if (errorMesg != undefined) {
                        return new UserErrorAction(this.actionPrefix, { obj: rsModel });
                    } else {
                        return new UserDeleted(this.actionPrefix, { id: response._id });
                    }
                }
                return this.hidePageLoadingDistpatcher;
            }),
        ));

    
    updateUser$ = createEffect(() => this.actions$
        .pipe(
            ofType<UserOnServerUpdated>(UserActionTypes.UserOnServerUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.objsService.updateObject(payload.obj);
            }),
            map(response => {
                if (response) {
                    const rsModel = (response as unknown as QueryResultsModel);
                    if (rsModel.errorCode != undefined) {
                        return new UserErrorAction(this.actionPrefix, { obj: rsModel });
                    } else {
                        const updatedUser: Update<User> = {
                            id: response._id,
                            changes: response
                        };
                        return new UserUpdated(this.actionPrefix, { partialUser: updatedUser, user: response });
                    }
                }
                return this.hideActionLoadingDistpatcher;
            }),
        ));

    
    createUser$ = createEffect(() => this.actions$
        .pipe(
            ofType<UserOnServerCreated>(UserActionTypes.UserOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.objsService.createObject(payload.obj);
            }),
            map(response => {
                if (response) {
                    const rsModel = (response as unknown as QueryResultsModel);
                    const errorMesg = rsModel.errorMessage;
                    if (errorMesg != undefined) {
                        return new UserErrorAction(this.actionPrefix, { obj: rsModel });
                    } else {
                        return new UserCreated(this.actionPrefix, { obj: response });
                    }
                }
                return this.hideActionLoadingDistpatcher;
            }),
        ));
}
