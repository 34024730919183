import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        component: BaseComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'product-management',
                loadChildren: () => import('./views/pages/product-management/product-management.module').then(m => m.ProductModule),
            },
            {
                path: 'order',
                loadChildren: () => import('./views/pages/order-to-cash/ordertocash.module').then(m => m.OrderToCashModule),
            },
            {
                path: 'schedule',
                loadChildren: () => import('./views/pages/schedule/schedule.module').then(m => m.ScheduleModule),
            },
            {
                path: 'thetrip',
                loadChildren: () => import('./views/pages/trip/trip.module').then(m => m.TripModule),
            },
            {
                path: 'resource-management',
                loadChildren: () => import('./views/pages/resource-management/resource-management.module').then(m => m.ResourceManagementModule),
            },
            {
                path: 'user-management',
                loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule),
            },
            {
                path: 'setting',
                loadChildren: () => import('./views/pages/setting/setting.module').then(m => m.SettingModule)
            },
            {
                path: 'report',
                loadChildren: () => import('./views/pages/report/report.module').then(m => m.ReportModule)
            },
            {
                path: 'error/403',
                component: ErrorPageComponent,
                data: {
                    'type': 'error-v6',
                    'code': 403,
                    'title': '403... Access forbidden',
                    'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
                }
            },
            { path: 'error/:type', component: ErrorPageComponent },
            { path: '', redirectTo: 'product', pathMatch: 'full' },
            { path: '**', redirectTo: 'setting', pathMatch: 'full' }
        ]
    },

    { path: '**', redirectTo: 'setting', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
