// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { BaseModel } from '../../_base/crud';

import { Update } from '@ngrx/entity';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';

export enum MetadataActionTypes {
    MetadataErrorAction = '[Metadata Component] Error Action',
    RemoveAllMetadataStore = '[Metadata] RemoveAllMetadataStore',
    MetadataOnServerCreated = '[Edit Metadata Component] Metadata On Server Created',
    MetadataCreated = '[Edit Metadata Component] Metadata Created',
    MetadataOnServerUpdated = '[Edit Metadata Component] Metadata On Server Updated',
    MetadataUpdated = '[Edit Metadata Component] Metadata Updated',
    MetadataStatusUpdated = '[Metadata List Page] Metadata Status Updated',
    OneMetadataDeleted = '[Metadata List Page] One Metadata Deleted',
    ManyMetadataDeleted = '[Metadata List Page] Many Selected Metadata Deleted',
    MetadataPageRequested = '[Metadata List Page] Metadata Page Requested',
    MetadataPageLoaded = '[Metadata API] Metadata Page Loaded',
    MetadataPageCancelled = '[Metadata API] Metadata Page Cancelled',
    MetadataPageToggleLoading = '[Metadata] Metadata Page Toggle Loading',
    MetadataActionToggleLoading = '[Metadata] Metadata Action Toggle Loading'
}

export class MetadataErrorAction implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataErrorAction;
    constructor(public actionPrefix: string, public payload: { errorMessage: string, errorCode: number }) { }
}

export class RemoveAllMetadataStore implements MaytechBaseAction {
    readonly type = MetadataActionTypes.RemoveAllMetadataStore;
    constructor(public actionPrefix: string) { }
}


export class MetadataOnServerCreated implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataOnServerCreated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class MetadataCreated implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataCreated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}


export class MetadataOnServerUpdated implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataOnServerUpdated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class MetadataUpdated implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataUpdated;
    constructor(public actionPrefix: string, public payload: {
        partialMetadata: Update<BaseModel>, // For State update
        obj: BaseModel // For Server update (through contextHelp)
    }) { }
}

export class MetadataStatusUpdated implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataStatusUpdated;
    constructor(public actionPrefix: string, public payload: {
        objs: BaseModel[],
        status: number
    }) { }
}

export class OneMetadataDeleted implements MaytechBaseAction {
    readonly type = MetadataActionTypes.OneMetadataDeleted;
    constructor(public actionPrefix: string, public payload: { id: number }) { }
}

export class ManyMetadataDeleted implements MaytechBaseAction {
    readonly type = MetadataActionTypes.ManyMetadataDeleted;
    constructor(public actionPrefix: string, public payload: { ids: number[] }) { }
}

export class MetadataPageRequested implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataPageRequested;
    constructor(public actionPrefix: string, public payload: { page: QueryParamsModel }) { }
}

export class MetadataPageLoaded implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataPageLoaded;
    constructor(public actionPrefix: string, public payload: { objs: BaseModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class MetadataPageCancelled implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataPageCancelled;
    constructor(public actionPrefix: string) { }

}

export class MetadataPageToggleLoading implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataPageToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export class MetadataActionToggleLoading implements MaytechBaseAction {
    readonly type = MetadataActionTypes.MetadataActionToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export type MetadataActions = MetadataErrorAction
    | RemoveAllMetadataStore
    | MetadataOnServerCreated
    | MetadataCreated
    | MetadataOnServerUpdated
    | MetadataUpdated
    | MetadataStatusUpdated
    | OneMetadataDeleted
    | ManyMetadataDeleted
    | MetadataPageRequested
    | MetadataPageLoaded
    | MetadataPageCancelled
    | MetadataPageToggleLoading
    | MetadataActionToggleLoading;
