/** Angular */
import { Injectable } from '@angular/core';

@Injectable()
export class TypesUtilsService {
    /**
     * Convert number to string and addinng '0' before
     *
     * @param value: number
     */
    padNumber(value: number) {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else {
            return '';
        }
    }

    /**
     * Checking value type equals to Number
     *
     * @param value: any
     */
    isNumber(value: any): boolean {
        return !isNaN(this.toInteger(value));
    }

    /**
     * Covert value to number
     *
     * @param value: any
     */
    toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    /**
     * Convert date to string with 'MM/dd/yyyy' format
     *
     * @param date: Date
     */
    dateFormat(date: Date): string {
        if (date) {
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        }
        return '';
    }

    dateFormatDDMMYYYY(dateInput: Date): string {
        var _date = new Date(dateInput);
        if (_date) {
            const month = _date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1;
            const year = _date.getFullYear();
            const day = _date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate();
            return `${day}/${month}/${year}`;
        }
        return '';
    }

    dateFormatDDMMYYYYHHmm(dateInput: Date): string {
        var _date = new Date(dateInput);
        if (_date) {
            const month = _date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1;
            const year = _date.getFullYear();
            const day = _date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate();
            const hour = _date.getHours() < 10 ? '0' + _date.getHours() : _date.getHours();
            const min = _date.getMinutes() < 10 ? '0' + _date.getMinutes() : _date.getMinutes();
            return `${day}/${month}/${year} ${hour}:${min}`;
        }
        return '';
    }

    dateFormatHHmm(dateInput: Date): string {
        var _date = new Date(dateInput);
        if (_date) {
            const hour = _date.getHours() < 10 ? '0' + _date.getHours() : _date.getHours();
            const min = _date.getMinutes() < 10 ? '0' + _date.getMinutes() : _date.getMinutes();
            return `${hour}:${min}`;
        }
        return '';
    }

    dateFormatDDMMYYYYHHmmSSForFile(dateInput: Date): string {
        var date = new Date(dateInput);
        if (date) {
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            const hour = date.getHours();
            const mins = date.getMinutes();
            const sec = date.getSeconds();
            return `${year}${month}${day}${hour}${mins}${sec}`;
        }
        return '';
    }

    dateFormatToServer(date: Date): string {
        if (date) {
            const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
            const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        }
        return '';
    }

    /**
     * Convert Date to string with custom format 'MM/dd/yyyy'
     *
     * @param date: any
     */
    dateCustomFormat(date: any): string {
        let stringDate: string = '';
        if (date) {
            stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '';
            stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '/' : '';

            stringDate += date.year;
        }
        return stringDate;
    }

    /**
     * Convert string to DateFormatter (For Reactive Forms Validators)
     *
     * @param dateInStr: string (format => 'MM/dd/yyyy')
     */
    getDateFormatterFromString(dateInStr: string): any {
        if (dateInStr && dateInStr.length > 0) {
            const dateParts = dateInStr.trim().split('/');
            return [
                {
                    year: this.toInteger(dateParts[2]),
                    month: this.toInteger(dateParts[0]),
                    day: this.toInteger(dateParts[1])
                }
            ];
        }

        const _date = new Date();
        return [
            {
                year: _date.getFullYear(),
                month: _date.getMonth() + 1,
                day: _date.getDay()
            }
        ];
    }

    /**
     * Convert string to Date
     *
     * @param dateInStr: string (format => 'MM/dd/yyyy')
     */
    getDateFromString(dateInStr: string = ''): Date {
        if (dateInStr && dateInStr.length > 0) {
            const dateParts = dateInStr.trim().split('/');
            const year = this.toInteger(dateParts[2]);
            const month = this.toInteger(dateParts[0]);
            const day = this.toInteger(dateParts[1]);
            // tslint:disable-next-line:prefer-const
            let result = new Date();
            result.setDate(day);
            result.setMonth(month - 1);
            result.setFullYear(year);
            return result;
        }

        return new Date();
    }


    getDateFromStringDDMMYYYY(dateInStr: string = ''): Date {
        if (dateInStr && dateInStr.length > 0) {
            const dateParts = dateInStr.trim().split('/');
            const day = this.toInteger(dateParts[0]);
            const month = this.toInteger(dateParts[1]);
            const year = this.toInteger(dateParts[2]);
            // tslint:disable-next-line:prefer-const
            let result = new Date();
            result.setDate(day);
            result.setMonth(month - 1);
            result.setFullYear(year);
            return result;
        }
        return new Date();
    }
    /**
     * Convert Date to string with format 'MM/dd/yyyy'
     * @param _date: Date?
     */
    getDateStringFromDate(_date: Date = new Date(), _dateOnly: boolean = false): string {
        const month = _date.getMonth() + 1;
        const year = _date.getFullYear();
        const date = _date.getDate();
        const hour = _date.getHours();
        const min = _date.getMinutes();
        const sec = _date.getSeconds();
        if (_dateOnly)
            return `${month}/${date}/${year}`;
        return `${hour}:${min} ${month}/${date}/${year}`;
    }

    getDateTimeStringFromDate(_date: Date = new Date()): string {
        const month = _date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1;
        const year = _date.getFullYear();
        const date = _date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate();
        const hour = _date.getHours() < 10 ? '0' + _date.getHours() : _date.getHours();
        const min = _date.getMinutes() < 10 ? '0' + _date.getMinutes() : _date.getMinutes();

        return `${hour}:${min} ${date}/${month}/${year}`;
    }

    getMySQLDateTimeStringFromDate(_date: Date = new Date()): string {
        const month = _date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1;
        const year = _date.getFullYear();
        const date = _date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate();
        const hour = _date.getHours() < 10 ? '0' + _date.getHours() : _date.getHours();
        const min = _date.getMinutes() < 10 ? '0' + _date.getMinutes() : _date.getMinutes();
        const sec = _date.getSeconds() < 10 ? '0' + _date.getSeconds() : _date.getSeconds();
        return `${year}-${month}-${date} ${hour}:${min}:${sec}`;
    }

    convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','

                line += '\"' + array[i][index] + '\"';
            }

            str += line + '\r\n';
        }
        return str;
    }

    getDateFromStringYYYYMMDD_InCustomerInfo(dateInStr: string = ''): Date {
        if (dateInStr && dateInStr.length > 0) {
            const dateParts = dateInStr.trim().split('-');
            const year = this.toInteger(dateParts[0]);
            const month = this.toInteger(dateParts[1]);
            const day = this.toInteger(dateParts[2]);
            // tslint:disable-next-line:prefer-const
            let result = new Date();
            result.setDate(day);
            result.setMonth(month - 1);
            result.setFullYear(year);
            return result;
        }
        return new Date();
    }

    getCodeFromDatetime() {
        var _date = new Date();
        const month = _date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1;
        const year = _date.getFullYear();
        const day = _date.getDate() < 10 ? '0' + _date.getDate() : _date.getDate();
        return `${year}${month}${day}`;
    }

    getTimeDifference(date1, date2) {
        // Convert the dates to milliseconds
        const time1 = new Date(date1).getTime();
        const time2 = new Date(date2).getTime();
        // Calculate the difference in milliseconds
        const diffInMilliseconds = Math.abs(time1 - time2);
        // Calculate the difference in hours and minutes
        const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        return { hours, minutes };
    }
}
