// Angular
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, Renderer2, Input, AfterViewInit } from '@angular/core';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, SplashScreenService, SubheaderService } from '../../../../core/_base/layout';
import { Subscription } from 'rxjs';
import { HelpEventService } from './help-event.service';
import { ContextHelpService, ContextHelpModel } from '../../../../core/setting';
import { TranslateService } from '@ngx-translate/core';
import Editor from '../../content/ckeditor5/build/ckeditor';
import { Router, ActivatedRoute } from '@angular/router';
import { MaytechTenantService } from '../../../../core/_maytech/maytech.tenant.service';
import { MatDialog } from '@angular/material/dialog';
import { MaytechEditComponent } from '../../../../core/_maytech/maytech-edit.component';
import { AppState } from '../../../../core/_reducers';
import { TypesUtilsService, LayoutUtilsService } from '../../../../core/_base/crud';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
	selector: 'mt-help',
	templateUrl: './help.component.html'   
})
export class HelpComponent extends MaytechEditComponent implements AfterViewInit {
    @Input() toggleBtnId: string;
    @Input() parentLabel: string;
    @Input() parentContent: string;

    public ckEditor = Editor;

    @ViewChild('contentInput') contentInput: CKEditorComponent;
    ctrEditMode: boolean = false;
    public ctrHelpContent: string = "";

    @ViewChild('parentContentInput') parentContentInput: CKEditorComponent;
    parentEditMode: boolean = false;
    public parentHelpContent: string = "";

    canEdit: boolean = true;
    helpName: string = "name";
    subscriptions: Subscription[]=[];
    options: string[];
    lang: string = "vn";
    controlLabel: string = '';

    public removeEventListener: () => void;
    btnElm: HTMLElement;
    isShowHelp = true;

    //@ViewChild('contentInput') contentInput: ElementRef;
    //constructor(
    //    private helpService: HelpEventService,
    //    private contexthelpService: ContextHelpService,
    //    private translate: TranslateService)
    //constructor(private helpService: HelpEventService,
    //    private dialog: MatDialog,
    //    private router: Router,
    //    private translate: TranslateService,
    //    private m: MaytechTenantService,
    //    private contexthelpService: ContextHelpService,
    //    private cdr: ChangeDetectorRef) 

    //{
    //    //this.actionPrefix = 'Product';
    //    this.lang = this.translate.currentLang;
    //}
    constructor(
        private helpService: HelpEventService,
        store: Store<AppState>,
        activatedRoute: ActivatedRoute,
        router: Router,
        typesUtilsService: TypesUtilsService,
        contexthelpFB: FormBuilder,
        dialog: MatDialog,
        subheaderService: SubheaderService,
        layoutUtilsService: LayoutUtilsService,
        layoutConfigService: LayoutConfigService,
        contexthelpService: ContextHelpService,
        translate: TranslateService,
        helpEventService: HelpEventService,
        permissionsService: NgxPermissionsService,
        cdr: ChangeDetectorRef
    ) {
        super(helpEventService, permissionsService, translate,store, activatedRoute, router, typesUtilsService, contexthelpFB, dialog, subheaderService, layoutUtilsService, layoutConfigService, contexthelpService, cdr);
        this.listUrl = '/setting/contexthelp';
        this.actionPrefix = 'Contexthelp';
    }    
    
    ngOnInit() {
        this.subscriptions.push(this.helpService.showHelpContentEvent.subscribe(res => {
            this.getHelpContent(res.content);
            this.controlLabel = res.label;
        }));
        if (this.parentContent) {
            this.setLoading(true);
            (<ContextHelpService>this.objectService).getContextHelpByName(this.parentContent, this.lang).toPromise().then(res => {
                if (res) {// success
                    this.parentHelpContent = res.content;
                    if (this.parentContentInput)
                        this.parentContentInput.editorInstance.setData(this.parentHelpContent);

                    this.setLoading(false);
                    this.cdr.detectChanges();
                }
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.toggleBtnId) {
            this.btnElm = document.getElementById(`${this.toggleBtnId}`);
            if (this.btnElm != null) {
                this.btnElm.addEventListener('click', this.toggleHelpPanel);
            }
        } else {
            this.isShowHelp = false;
        }
        this.toggleHelpPanel();
    }

    toggleHelpPanel() {
        if (this.isShowHelp) {
            document.body.classList.add('help-context-close');
            this.isShowHelp = false;
        } else {
            document.body.classList.remove('help-context-close');
            this.isShowHelp = true;
        }
    }

    getHelpContent(name: string) {
        if (this.helpName != name) {
            this.helpName = name;
            this.setLoading(true);
            (<ContextHelpService>this.objectService).getContextHelpByName(name, this.lang).toPromise().then(res => {
                if (res) {// success
                    this.ctrHelpContent = res.content;
                    if (this.contentInput)
                        this.contentInput.editorInstance.setData(this.ctrHelpContent);

                    this.setLoading(false);
                    this.cdr.detectChanges();
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
        document.body.classList.remove('help-context-close');
        if (this.btnElm)
            this.btnElm.removeEventListener('click', this.toggleHelpPanel);
    }

    toggleEditMode(type) {
        if (type == 'control')
            this.ctrEditMode = !this.ctrEditMode;

        if (type == 'parent')
            this.parentEditMode = !this.parentEditMode;

        if (this.cdr)
            this.cdr.detectChanges();
    }

    saveParentHelpContent() {
        this.setLoading(true);
        let contHelp = new ContextHelpModel();
        contHelp.clear();
        contHelp.content = this.parentContentInput.editorInstance.getData();
        contHelp.language = this.lang;
        contHelp.name = this.parentContent;

        this.subscriptions.push(
            (<ContextHelpService>this.objectService).saveContextHelpByName(contHelp).subscribe(res => {
                if (res) {// success
                    this.parentHelpContent = res.content;
                    this.parentContentInput.editorInstance.setData(this.parentHelpContent);
                    this.setLoading(false);
                    this.cdr.detectChanges();
                    this.toggleEditMode('parent');
                }
            })
        );
    }

    saveCtrHelpContent() {
        this.setLoading(true);
        let contHelp = new ContextHelpModel();
        contHelp.clear();
        contHelp.content = this.contentInput.editorInstance.getData();
        contHelp.language = this.lang;
        contHelp.name = this.helpName;

        this.subscriptions.push(
            (<ContextHelpService>this.objectService).saveContextHelpByName(contHelp).subscribe(res => {
                if (res) {// success
                    this.ctrHelpContent = res.content;
                    this.contentInput.editorInstance.setData(this.ctrHelpContent);
                    this.setLoading(false);
                    this.cdr.detectChanges();
                    this.toggleEditMode('control');
                }
            })
        );
    }

    cancelHelpContent(type) {
        this.toggleEditMode(type);
    }

    getParentLabel() {
        if (this.parentLabel)
            return this.parentLabel;
        return '';
    }

    getControlLabel() {
        return this.controlLabel;
    }
}
