// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SupportTicketService, SupportTicketModel } from '../../../../../../core/theme';
import { QueryParamsModel, TypesUtilsService, LayoutUtilsService, AlertMessageType, BaseModel } from '../../../../../../core/_base/crud';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpEventService } from '../../../../../partials/layout/help/help-event.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { MaytechSimpleEditComponent } from '../../../../../../core/_maytech/maytech-simple-edit.component';
import { SupportTicketStatus } from '../../../../../../core/_utils/define';
import html2canvas from 'html2canvas';
import { KtDialogService } from '../../../../../../core/_base/layout';


@Component({
    selector: 'mt-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class FeedbackDialogComponent extends MaytechSimpleEditComponent {
    close: boolean = false;
    feedbackContent: string = "";
    apiErrorMesg: string;
    previewUrl: string = "";
    isSaveImg: boolean = false;
    
    constructor(
        helpEventService: HelpEventService,
        permissionsService: NgxPermissionsService,
        translate: TranslateService,
        activatedRoute: ActivatedRoute,
        router: Router,
        layoutUtilsService: LayoutUtilsService,
        formBuilder: FormBuilder,
        cdr: ChangeDetectorRef,
        private dialogRef: MatDialogRef<FeedbackDialogComponent>,
        private feedbackService: SupportTicketService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(helpEventService, permissionsService, translate, activatedRoute, router, layoutUtilsService, formBuilder, cdr, feedbackService);
    }
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */
    ngOnInit() {
        super.ngOnInit();
        this.setLoading(false);
        this.screenShot();
    }

    screenShot() {
        html2canvas(document.body, { scale: 1 }).then(canvas => {
            this.previewUrl = canvas.toDataURL();
        });
    }

    viewImg() {
        var modal = document.getElementById("myModal");

        // Get the image and insert it inside the modal - use its "alt" text as a caption
        var img = document.getElementById("myImg");
        var modalImg = document.getElementById("img01");

        modal.style.display = "block";

    }
    closeModal() {
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
    }

    prepareRole(): SupportTicketModel {
        const _feedback = new SupportTicketModel();
        _feedback.notes = this.feedbackContent;
        _feedback.supportTicketUrl = this.router.url;
        if (this.isSaveImg == true) {
            _feedback.screenshot = this.previewUrl;
        }
        _feedback.supportTicketStatus = SupportTicketStatus.OPEN;
        return _feedback;
    }

    /**
 * Save data
 */
    onDialogSubmit() {
        this.apiErrorMesg = '';
        this.hasFormErrors = false;
        /** check form */
        if (!(this.feedbackContent.length > 0)) {
            this.apiErrorMesg = `${this.translate.instant("USER_MANAGEMENT.ROLE.TITLE_REQUIRED")}`;
            this.hasFormErrors = true;
            return;
        }

        const editedRole = this.prepareRole();
        this.addObject(editedRole);
    }

    onCreateDataSuccess(res) {
        var message = this.translate.instant('FRONTPAGE.FEEDBACK_CREATED');
        this.layoutUtilsService.showActionNotification(message, AlertMessageType.Update, 5000, true, true);
        this.onClose();
    }

    onClose() {
        this.dialogRef.close();
    }
    onScreen() {
        html2canvas(document.body).then(canvas => {
            document.body.appendChild(canvas)
        });
    }
    getTitle(): string {
        return this.translate.instant('FRONTPAGE.FEEDBACK');
    }

    addObject(_object: BaseModel) {
        //this.subscriptions.push(
        this.maytechDataService.createObject(_object, false).toPromise().then(
            res => {
                this.setLoading(false);
                    this.onCreateDataSuccess(res);
               
            },
            err => {
                this.onSubmitFail();
                this.setLoading(false);
                this.onDataServiceError(err);
            }
        );
        //);
    }
}
