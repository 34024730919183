import { BaseModel } from '../core/_base/crud';
import { CustomerModel } from './customer.model';


export class MessageTemplateModel extends BaseModel {
    messageTemplateId: number;
    messageTemplateStatus: number;
    messageTemplateName: string;
    messageContent: string;
    attachmentContent: string;
    attachmentFileName: string;
    fromEmail: string;
    isAutoSend: number;
    isAttachmentFile: number;
    sendType: number;
    sendValue: number;
    subject: string;
    dataStoreProc: string;
    sendTo: string;
    //predefinedBlock: string;
    timeUnit: number;
    receiverType: number;
    timeline: number;
    messageType: number;
    messageMethod: number;
    objectType: number;
    objectStatus: number;
    sendAfter: number;
    createdDate: string;
    createdBy: number;
    updatedDate: string;
  //  attachments: string;
    updatedBy: number;
    //shortCode: string;
    //listEmail: string;
    //bookingNumber: string;
    //totalAmount: number;
    //paidStatus: number;
    //productName: string;
    //customerName: string;
    //customerEmail: string;
    //phone: number;

    clear() {
        //this.predefinedBlock = '';
        this.messageTemplateId = 0;
        this.messageContent = '';
        this.attachmentContent = '';
        this.attachmentFileName = '';
       // this.attachments = '';
        this.messageType = 0;
        this.receiverType = 0;
        this.timeline = 0;
        this.sendTo = '';
        this.dataStoreProc = '';
        this.objectStatus = 0;
        this.objectType = 0;
        this.messageTemplateStatus=0;
        this.messageTemplateName = '';
        this.messageMethod = 0;
        this.sendAfter = 0;
        this.sendType = 0;
        this.isAutoSend = 0;
        this.isAttachmentFile = 0;
        this.sendValue = 0;
        this.timeUnit = 0;
        this.fromEmail = '';
        //this.shortCode ='';
        //this.listEmail='';
        this.subject = '';
        this.createdDate = '';
        this.createdBy = 0;
        this.updatedDate = '';
        this.updatedBy = 0;
        //this.bookingNumber = '';
        //this.totalAmount = 0;
        //this.paidStatus = 0;
        //this.productName = '';
        //this.customerName = '';
        //this.customerEmail = '';
        //this.phone = 0;

    }
}
