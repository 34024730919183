export class QueryResultsModel {
	// fields
	items: any[];
	totalCount: number;
    errorCode: number;
	errorMessage: string;
    pageIndex: number;

    constructor(_items: any[] = [], _totalCount: number = 0, _errorMessage: string = '', _errorCode: number = undefined) {
		this.items = _items;
		this.totalCount = _totalCount;
	}
}
