export class BaseModel {
    // Edit
    _isEditMode: boolean = false;
    // Log
    _userId: number = 0; // Admin
    _createdDate: string;
    _updatedDate: string;
    _id: number = 0;
    _status: number = 0;
}
