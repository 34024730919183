import { BaseModel } from '../core/_base/crud';

export class Userdevice extends BaseModel {
    userDeviceId: number;
    userId: number;
    ipAddress: string;
    uniqueKey: string;
    loginDate: Date;
    isActive: number;

    clear(): void {
        this.userDeviceId = undefined;
        this.userId = undefined;
        this.ipAddress = '';
        this.uniqueKey = '';
        this.loginDate = undefined;
        this.isActive = undefined;
    }
}
