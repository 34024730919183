// Models
import { BaseModel, QueryParamsModel, QueryResultsModel } from '../../_base/crud';

import { Update } from '@ngrx/entity';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { ObjectType } from '../../_utils/define';

export enum OptionActionTypes {
    OptionOnServerUpdated = '[Option] Option On Server Updated',
    OptionUpdated = '[Option] Option Updated',
    OptionPageRequested = '[Option] Option Page Requested',
    OptionPageLoaded = '[Option] Option Page Loaded',
    OptionPageToggleLoading = '[Option] Option Page Toggle Loading',
    OptionActionToggleLoading = '[Option] Option Action Toggle Loading',
    OptionErrorAction = '[Option Component] Error Action',
    ClearOptionError = '[Option Component] Clear Error'
}

export class OptionErrorAction implements MaytechBaseAction {
    readonly type = OptionActionTypes.OptionErrorAction;
    constructor(public actionPrefix: string, public payload: { obj: QueryResultsModel }) { }
}

export class ClearOptionError implements MaytechBaseAction {
    readonly type = OptionActionTypes.ClearOptionError;
    constructor(public actionPrefix: string) { }
}

export class OptionOnServerUpdated implements MaytechBaseAction {
    readonly type = OptionActionTypes.OptionOnServerUpdated;
    constructor(public actionPrefix: string, public payload: { objs: BaseModel[] }) { }
}

export class OptionUpdated implements MaytechBaseAction {
    readonly type = OptionActionTypes.OptionUpdated;
    constructor(public actionPrefix: string, public payload: {
        objs: BaseModel[]
    }) { }
}

export class OptionPageRequested implements MaytechBaseAction {
    readonly type = OptionActionTypes.OptionPageRequested;
    constructor(public actionPrefix: string, public payload: { page: QueryParamsModel }) { }
}

export class OptionPageLoaded implements MaytechBaseAction {
    readonly type = OptionActionTypes.OptionPageLoaded;
    constructor(public actionPrefix: string, public payload: { objs: BaseModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class OptionPageToggleLoading implements MaytechBaseAction {
    readonly type = OptionActionTypes.OptionPageToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export class OptionActionToggleLoading implements MaytechBaseAction {
    readonly type = OptionActionTypes.OptionActionToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export type OptionActions = OptionOnServerUpdated
    | OptionUpdated
    | OptionPageRequested
    | OptionPageLoaded
    | OptionPageToggleLoading
    | OptionActionToggleLoading
    | OptionErrorAction
    | ClearOptionError;
