import { OnInit, Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { LayoutUtilsService, AlertMessageType, TypesUtilsService } from '../../../../core/_base/crud';
import { BookingInfoModel, BookingInfoService, EditRequestModel } from '../../../../core/order-to-cash';
import { ActivatedRoute, Router } from '@angular/router';
import { MaytechSimpleEditComponent } from '../../../../core/_maytech/maytech-simple-edit.component';
import { EditRequestService, CurrencyModel } from '../../../../core/setting';
import { EditRequestType, ResultMessageType } from '../../../../core/_utils/define';
import { ObjectPriceGroupListModel } from '../../../../models/objectpricegrouplist.model';

@Component({
    selector: 'mt-reference-price-table',
    templateUrl: './mt-reference-price-table.component.html'
})

export class MtReferencePriceTableComponent implements OnInit {
    @Input() refPrices: ObjectPriceGroupListModel;
    @Input() currencyList: CurrencyModel[] = [];

    constructor(
        protected translate: TranslateService,
        protected router: Router,
        protected layoutUtilsService: LayoutUtilsService,
        public cdr: ChangeDetectorRef,
        public typesUtilsService: TypesUtilsService,
        protected editRequestService: EditRequestService) { }

    ngOnInit() {
        //console.log("refPrices");
        //console.log(this.refPrices);
    }

    showReferencePriceTable() {
        const dialogRef = this.layoutUtilsService.showRefPricesDialogComponent({
            refPrices: this.refPrices,
            currencyList: this.currencyList
        }
        );
    }
}
