import { BaseModel } from '../core/_base/crud';


export class PromotionModel extends BaseModel {
    promotionId: number;
    promotionName: string;
    promotionCode: string;
    promotionStatus: number;
    promotionObjectType: number;
    objectId: number;
    discountType: number;
    discountValue: number;
    discountLimit: number;
    discountLimitValue: number;
    minAmount: number;
    includeTaxFee: number;
    includeService: number;
    notes: string;
    validFromDate: '';
    validToDate: '';
    parentId: number;
    createdBy: number;
    createdDate: '';

    clear() {
        this.promotionId = 0;
        this.promotionName = '';
        this.promotionCode = '';
        this.promotionStatus = 0;
        this.promotionObjectType = 0;
        this.objectId = 0;
        this.discountType = 0;
        this.discountValue = 0;
        this.discountLimit = 0;
        this.discountLimitValue = 0;
        this.minAmount = 0;
        this.includeTaxFee = 0;
        this.includeService = 0;
        this.notes = '';
        this.validFromDate = '';
        this.validToDate = '';
        this.parentId = 0;
        this.createdDate = '';
        this.createdBy = 0;
    }
}
