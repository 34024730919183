// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom, map } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, Register, UserLoaded, UserRequested, AuthUserOnServerUpdated, AuthUserUpdated, AuthUserErrorAction, UserPermissionRequested, UserPermissionLoaded } from '../_actions/auth.actions';
import { AppState } from '../../_reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { ObjectsPageToggleLoading } from '../../_maytech/maytech.actions';
import { Update } from '@ngrx/entity';
import { QueryResultsModel } from '../../_base/crud';
import { AllRolesRequested } from '../_actions/role.actions';
import { AllPermissionsRequested } from '../_actions/permission.actions';
import { AuthService } from '../_services';
import { User } from '../../../models/user.model';
import { Permission } from '../../../models/permission.model';

@Injectable()
export class AuthEffects {
    showLoadingDistpatcher = new ObjectsPageToggleLoading('auth', { isLoading: true });
    hideActionLoadingDistpatcher = new ObjectsPageToggleLoading('auth', { isLoading: false });

    
    login$ = createEffect(() => this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(action => {
            localStorage.setItem(environment.authTokenKey, action.payload.authToken);
            this.store.dispatch(new UserRequested());
        }),
    ), { dispatch: false });

    
    logout$ = createEffect(() => this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        mergeMap(() => this.auth.logout()),
        tap(() => {
            localStorage.removeItem(environment.authTokenKey);
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.returnUrl } });
        })
    ), { dispatch: false });

    
    register$ = createEffect(() => this.actions$.pipe(
        ofType<Register>(AuthActionTypes.Register),
        tap(action => {
            localStorage.setItem(environment.authTokenKey, action.payload.authToken);
        })
    ), { dispatch: false });

    
    loadUser$ = createEffect(() => this.actions$
        .pipe(
            ofType<UserRequested>(AuthActionTypes.UserRequested),
            withLatestFrom(this.store.pipe(select(isUserLoaded))),
            filter(([action, _isUserLoaded]) => !_isUserLoaded),
            mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
            tap(_user => {
                if (_user) {
                    this.store.dispatch(new UserLoaded({ user: _user }));
                    const allowaccessPermission = _user.userPermissions.filter(p => p.permissionCode == 'CanAccessUserManModule');
                    if (allowaccessPermission.length > 0) {
                        this.store.dispatch(new AllRolesRequested());
                        this.store.dispatch(new AllPermissionsRequested());
                    }
                } else {
                    this.store.dispatch(new Logout());
                }
            })
    ), { dispatch: false });

    
    loadUserPermissions$ = createEffect(() => this.actions$
        .pipe(
            ofType<UserPermissionRequested>(AuthActionTypes.UserPermissionRequested),
            mergeMap(() => this.auth.getUserPermissions()),
            map((_permissions: Permission[]) => {
                return new UserPermissionLoaded({
                    permissions: _permissions
                });
            })
        ));

    
    updateUser$ = createEffect(() => this.actions$
        .pipe(
            ofType<AuthUserOnServerUpdated>(AuthActionTypes.AuthUserOnServerUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.auth.updateUser(<User>payload.obj);
            }),
            map(response => {
                if (response && response != '') {
                    const rsModel = (response as unknown as QueryResultsModel);
                    if (rsModel.errorCode != undefined) {
                        return new AuthUserErrorAction('auth', { obj: rsModel });
                    } else {
                        const updatedUser: Update<User> = {
                            id: response._id,
                            changes: response
                        };
                        return new AuthUserUpdated('auth', { partialUser: updatedUser, user: response });
                    }
                }
                return this.hideActionLoadingDistpatcher;
            }),
        ));

    
    init$: Observable<Action> = createEffect(() => defer(() => {
        const userToken = localStorage.getItem(environment.authTokenKey);
        let observableResult = of({ type: 'NO_ACTION' });
        if (userToken) {
            observableResult = of(new Login({ authToken: userToken }));
        }
        return observableResult;
    }));

    private returnUrl: string;

    constructor(private actions$: Actions,
        private router: Router,
        private auth: AuthService,
        private store: Store<AppState>) {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.returnUrl = event.url;
            }
        });
    }
}
