import { Component } from '@angular/core';

@Component({
    selector: 'mt-loader',
    templateUrl: './mt-loader.component.html',
    styleUrls: ['./mt-loader.component.scss']
})
/** mt-loader component*/
export class MtLoaderComponent {
    /** mt-loader ctor */
    constructor() {

    }
}