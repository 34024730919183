import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TypesUtilsService } from '../../../../core/_base/crud';

@Component({
    selector: 'mt-filter-timerange',
    templateUrl: './mt-filter-timerange.component.html',
    styleUrls: ['./mt-filter-timerange.component.scss']
})

export class MtFilterTimeRangeComponent {
    @ViewChild('selectionList', { static: true }) selectionList: MatSelectionList;
    @ViewChild(NgbDropdown)
    dropdown: NgbDropdown;

    @Input() mtModel: any[];
    @Input() icon: string = 'schedule';
    @Input() iconPos: string = 'right';
    @Input() label: string = '';
    @Input() colorStyle: string = '';
    @Input() placement: string = 'bottom-left';
    @Input() multiple: boolean = true;
    @Input() labelToggle: boolean = false;
    @Input() hideCancelFilter: boolean = true;
    @Input() hideCancel: boolean = false;
    @Input() hideSelectAll: boolean = true;


    @Output() change = new EventEmitter<any[]>();
    @Output() mtModelChange = new EventEmitter<any[]>();

    selectedOption = '-1';
    focusOption = '';
    //timeRange: any[] = [new Date(), new Date()];
    optionList = [
        { 'Value': '-1', 'Text': this.translate.instant("GENERAL.SELECT_ALL") },
        { 'Value': '1', 'Text': this.translate.instant("TOUR_MANAGEMENT.TOUR_EDIT.SESSION.ALL_DATE") },
        { 'Value': '0', 'Text': this.translate.instant("TOUR_MANAGEMENT.TOUR_EDIT.SESSION.TIME_RANGE") }
    ];
    fromTime = '00:00';
    toTime = '23:59';
    fromTimeList = [];
    toTimeList = [];
    timeList = [];
    //selectedValues = [];
    //focusValue = [];

    constructor(protected translate: TranslateService, private cdr: ChangeDetectorRef) {
        for (let i = 0; i < 24; i++) {
            var time = i < 10 ? ('0' + i + ':00') : (i + ':00');
            this.timeList.push(time);
        }
        this.fromTimeList = Object.assign([], this.timeList);
        this.toTimeList = Object.assign([], this.timeList);
    }

    applyFilter() {
        //this.selectedOption = this.focusOption;
        this.mtModelChange.emit(this.mtModel);
        this.change.emit(this.mtModel);
        this.dropdown.toggle();
    }

    cancelFilter() {
        this.selectedOption = '-1';
        this.focusOption = '';
        this.mtModel = [new Date(), new Date()];
        this.mtModelChange.emit(this.mtModel);
        this.change.emit(this.mtModel);
        this.dropdown.toggle();
    }

    getSelectedLabel() {
        if (this.labelToggle) {
            switch (this.selectedOption) {
                case '-1':
                    return `${this.label}: <font color="primary">${this.translate.instant("GENERAL.ALL")}</font>`;
                case '1':
                    return `${this.label}: <font color="primary">${this.translate.instant("TOUR_MANAGEMENT.TOUR_EDIT.SESSION.ALL_DATE")}</font>`;
                case '0':
                    var utils = new TypesUtilsService();
                    let selectedTime = '';

                    //if (this.mtModel && this.mtModel.length > 0 && this.mtModel[0] != '' && this.mtModel[1] != '') {
                    //    const fromTime = utils.dateFormatHHmm(new Date(this.mtModel[0]));
                    //    const toTime = utils.dateFormatHHmm(new Date(this.mtModel[1]));
                    //    selectedTime = `${fromTime} - ${toTime}`;
                    //    //selectedTime = `${this.timeRange[0]} - ${this.timeRange[1]}`;
                    //}
                    selectedTime = `${this.fromTime} - ${this.toTime}`;

                    return this.label + ':  <font color="primary">' + selectedTime + '</font>';
            }
        }
        return this.label;
    }

    onOptionChange(selectValue) {
        //if (selectValue != '0') {
        //    this.applyFilter();
        //}
        this.selectedOption = selectValue;
    }

    dateTimeInput() {
        //this.selectedOption = '0';
        this.dropdown.toggle();
        this.applyFilter();
    }

    dropdownOpenChange(event) {
        if (event) {
            this.focusOption = this.selectedOption;
        }
    }

    changeTime(event) {
        this.applyFilter();
    }
}