import { EditRequestModel } from './editrequest.model';
import { BaseModel } from '../core/_base/crud';

export class EditRequestApprovalModel extends BaseModel {
    editRequests: EditRequestModel[];
    editRequestStatus: number;
    notes: string;
    isApprove: boolean;
    isRejectAllOther: boolean;
}
