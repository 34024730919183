// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
// State
import { AppState } from '../../../core/_reducers';
// Selectirs
import { ObjectsDataSource } from '../../_maytech/maytech.datasource';

export class RolesDataSource extends ObjectsDataSource {
    constructor(store: Store<AppState>) {
        super(store, 'roles');

    }
}
