// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material

import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../_reducers';
// UI
import { SubheaderService } from '../_base/layout';
// CRUD
import { LayoutUtilsService, QueryParamsModel, AlertMessageType } from '../_base/crud';
import { ObjectsDataSource } from './maytech.datasource';
import { selectObjectsPageLastQuery, selectError } from './maytech.selectors';
import { OneObjectDeleted, ManyObjectsDeleted, ObjectsStatusUpdated, ObjectsPageRequested } from './maytech.actions';
import { BaseModel } from '../_base/crud';
import { MaytechComponent } from './maytech.component';
import { MaytechTenantService } from './maytech.tenant.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';



@Directive()
export class MaytechListComponent extends MaytechComponent implements OnInit, OnDestroy {
    // Table fields
    dataSource: ObjectsDataSource;
    displayedColumns = ['select', 'Name', 'Status', 'createdDate', 'notes', 'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    filterStatus: string = '';
    filterCondition: string = '';
    lastQuery: QueryParamsModel;
    // Selection
    selection = new SelectionModel<BaseModel>(true, []);
    objectResult: BaseModel[] = [];
    subscriptions: Subscription[] = [];
    editUrl: string = '';
    actionPrefix: string = '';
    disabledEdit = false;
    activatedRow = null;
    /**
       * Component constructor
       *
       * @param dialog: MatDialog
       * @param activatedRoute: ActivatedRoute
       * @param router: Router
       * @param subheaderService: SubheaderService
       * @param layoutUtilsService: LayoutUtilsService
       * @param store: Store<AppState>
       */
    constructor(
        protected translate: TranslateService,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private subheaderService: SubheaderService,
        protected layoutUtilsService: LayoutUtilsService,
        protected store: Store<AppState>,
        protected m: MaytechTenantService,
        ) {
        super(m);
    }
    initDatasource() {
        this.dataSource = new ObjectsDataSource(this.store,'');
    }
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
    ngOnInit() {
        super.ngOnInit();
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadObjectsList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => {
                this.fitler();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Set title to page breadCrumbs
        this.subheaderService.setTitle('Objects');

        // Init DataSource
        this.initDatasource();
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.objectResult = res;
        });

        //this.subscriptions.push(entitiesSubscription);
        //const lastQuerySubscription = this.store.pipe(select(selectObjectsPageLastQuery(this.actionPrefix))).subscribe(res => this.lastQuery = res);
        //// Load last query from store
        //this.subscriptions.push(lastQuerySubscription);

        // Read from URL itemId, for restore previous state
        const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
            //if (params.id) {
            //    this.restoreState(this.lastQuery, +params.id);
            //}
            //// First Load
            of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
                this.loadObjectsList();
            });
        });
        this.subscriptions.push(routeSubscription);

        this.subscriptions.push(
            this.store.pipe(
                select(selectError(this.actionPrefix))
            ).subscribe(
                (response) => {
                    this.showErrorLoadingList(response.errorMessage, response.errorCode);

                }
            )
        );
    }

    fitler() {
        this.paginator.pageIndex = 0;
        this.loadObjectsList();
    }

    showErrorLoadingList(message:string, code:number=0) {
        if (message && message != "") {
            if (code === 401) {
                message = `${this.translate.instant("AUTH.VALIDATION.UNAUTHORIZED")}`;
            } else {
                message = `${this.translate.instant("GENERAL.ERROR")}`;
            }
            this.layoutUtilsService.showActionNotification(message, AlertMessageType.Read, 3000, true, false);
        }
    }
    /**
	 * On Destroy
	 */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

	/**
	 * Load Objects List
	 */
    loadObjectsList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
        // Call request from server
        this.store.dispatch(this.GetPageRequestedEvent(queryParams));
        this.selection.clear();
    }
    GetPageRequestedEvent(queryParams: QueryParamsModel): any{
        return new ObjectsPageRequested(this.actionPrefix, { page: queryParams });
    }
	/**
	 * Returns object for filter
	 */
    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;
        filter.keyword = searchText;
        return filter;
        //const filter: any = {};
        //const searchText: string = this.searchInput.nativeElement.value;

        //if (this.filterStatus && this.filterStatus.length > 0) {
        //    filter.status = +this.filterStatus;
        //}

        //if (this.filterCondition && this.filterCondition.length > 0) {
        //    filter.condition = +this.filterCondition;
        //}

        //filter.model = searchText;

        //filter.manufacture = searchText;
        //filter.color = searchText;
        //filter.VINCode = searchText;
        //return filter;
    }

	/**
	 * Restore state
	 *
	 * @param queryParams: QueryParamsModel
	 * @param id: number
	 */
    restoreState(queryParams: QueryParamsModel, id: number) {

        if (!queryParams.filter) {
            return;
        }

        //if ('condition' in queryParams.filter) {
        //    this.filterCondition = queryParams.filter.condition.toString();
        //}

        //if ('status' in queryParams.filter) {
        //    this.filterStatus = queryParams.filter.status.toString();
        //}

        //if (queryParams.filter.model) {
        //    this.searchInput.nativeElement.value = queryParams.filter.model;
        //}
    }

    /** ACTIONS */
	/**
	 * Delete customer
	 *
	 * @param _item: BaseModel
	 */
    deleteObject(_item: BaseModel) {
        const _title: string = 'Object Delete';
        const _description: string = 'Are you sure to permanently delete this object?';
        const _waitDesciption: string = 'Object is deleting...';
        const _deleteMessage = `Object has been deleted`;

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new OneObjectDeleted(this.actionPrefix,{ id: _item._id }));
            this.layoutUtilsService.showActionNotification(_deleteMessage, AlertMessageType.Delete);
            this.loadObjectsList();
        });
    }

	/**
	 * Delete customers
	 */
    deleteObjects() {
        const _title: string = 'Objects Delete';
        const _description: string = 'Are you sure to permanently delete selected customers?';
        const _waitDesciption: string = 'Objects are deleting...';
        const _deleteMessage = 'Selected customers have been deleted';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            const idsForDeletion: number[] = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.selection.selected.length; i++) {
                idsForDeletion.push(this.selection.selected[i]._id);
            }
            this.store.dispatch(new ManyObjectsDeleted(this.actionPrefix,{ ids: idsForDeletion }));
            this.layoutUtilsService.showActionNotification(_deleteMessage, AlertMessageType.Delete);
            this.selection.clear();
        });
    }

	/**
	 * Fetch selected customers
	 */
    fetchObjects() {
        // tslint:disable-next-line:prefer-const
        let messages = [];
        //this.selection.selected.forEach(elem => {
        //          messages.push({
        //              text: `${elem.customerName} ${elem.customerStatus}`,
        //		id: elem.customerId,
        //		status: elem.customerStatus
        //	});
        //});
        this.layoutUtilsService.fetchElements(messages);
    }

	/**
	 * Update status dialog
	 */
    updateStatusForObjects() {
        const _title = 'Update status for selected customers';
        const _updateMessage = 'Status has been updated for selected customers';
        const _statuses = [{ value: 0, text: 'Deactive' }, { value: 1, text: 'Active' }];
        const _messages = [];

        //this.selection.selected.forEach(elem => {
        //          _messages.push({
        //              text: `${elem.customerName} $ ${elem.customerStatus}`,
        //		        id: elem.customerId,
        //		        status: elem.customerStatus,
        //              statusTitle: this.getItemStatusString(elem.customerStatus),
        //              statusCssClass: this.getItemCssClassByStatus(elem.customerStatus)
        //	});
        //});
        //  const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
        //dialogRef.afterClosed().subscribe(res => {
        //	if (!res) {
        //		this.selection.clear();
        //		return;
        //	}

        //          this.store.dispatch(new ObjectsStatusUpdated({
        //		status: +res,
        //		objs: this.selection.selected
        //	}));

        //	this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update);
        //	this.selection.clear();
        //});
    }

	/**
	 * Redirect to edit page
	 *
	 * @param id: any
	 */
    editObject(id) {
        this.router.navigate([this.editUrl, id], { relativeTo: this.activatedRoute });
    }

    createObject() {
        this.router.navigateByUrl(this.editUrl);
    }

	/**
	 * Check all rows are selected
	 */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.objectResult.length;
        return numSelected === numRows;
    }

	/**
	 * Selects all rows if they are not all selected; otherwise clear selection
	 */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.objectResult.forEach(row => {
                this.selection.select(row);
            });
        }
    }

    /* UI */
	/**
	 * Returns status string
	 *
	 * @param status: number
	 */
    getItemStatusString(status: number = 0): string {
        switch (status) {
            case 0:
                return 'Deactive';
            case 1:
                return 'Active';
        }
        return '';
    }

	/**
	 * Returns CSS Class by status
	 *
	 * @param status: number
	 */
    getItemCssClassByStatus(status: number = 0): string {
        switch (status) {
            case 0:
                return 'metal';
            case 1:
                return 'success';
        }
        return '';
    }

	/**
	 * Rerurns condition string
	 *
	 * @param condition: number
	 */
    getItemConditionString(condition: number = 0): string {
        switch (condition) {
            case 0:
                return 'New';
            case 1:
                return 'Used';
        }
        return '';
    }

	/**
	 * Returns CSS Class by condition
	 *
	 * @param condition: number
	 */
    getItemCssClassByCondition(condition: number = 0): string {
        switch (condition) {
            case 0:
                return 'accent';
            case 1:
                return 'primary';
        }
        return '';
    }

    matRowClicked(row) {
        this.activatedRow = row;
    }

    actionMenuClosed() {
        this.activatedRow = null;
    }
}
