import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../../../models/user.model';
import { Permission } from '../../../models/permission.model';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel, HttpUtilsService } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { MaytechService } from '../../_maytech/maytech.service';
import { Role } from '../../../models/role.model';
import { Rolepermission } from '../../../models/rolepermission.model';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { AuthModel } from '../../../models/auth.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../_reducers';
import { ObjectType, HttpAPICallType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { AuthUserErrorAction } from '../_actions/auth.actions';
import { NgxPermissionsService } from 'ngx-permissions';


@Injectable()
export class AuthService extends MaytechService {
    BASE_URL = '';
    API_USER_URL = '';
    API_USERS_URL = '';
    API_AUTH_URL = '';
    API_ROLES_URL = '';
    TenantId = 0;

    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService,
        private permissionsService: NgxPermissionsService) {

        super(http, httpUtils, route,store, tenantService);

        this.BASE_URL = this.API_URL;
        this.API_USER_URL = this.BASE_URL + '/users/getuser';
        this.API_USERS_URL = this.BASE_URL + '/users';
        this.API_AUTH_URL = this.BASE_URL + '/auth';
        this.API_ROLES_URL = this.BASE_URL + '/roles';
        if (tenantService.currentClient != null)
            this.TenantId = tenantService.currentClient.tenantId;
    }

    // Authentication/Authorization
    login(auth: AuthModel): Observable<User> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        const url = `${this.BASE_URL}/auth/login`;
        auth.tenantId = this.TenantId;
        return this.http.post<User>(url, auth, { headers: httpHeaders });
    }

    logout() {
        const url = `${this.BASE_URL}/auth/logout`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get(url, { headers: httpHeaders }).pipe(catchError(this.handleError("logout", [])));
    }

    checkEmail(loginUser: User): Observable<User> {
        loginUser.tenantId = this.TenantId;
        return this.http.post<User>(this.BASE_URL + '/auth/checkemail', loginUser);
    }

    getUserByToken(): Observable<User> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + userToken);
        return this.http.get<User>(this.API_USER_URL, { headers: httpHeaders });
    }

    register(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');

        user.tenantId = this.TenantId;

        return this.http.post<User>(this.BASE_URL + '/auth/register', user, { headers: httpHeaders })
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
        return this.http.get(this.API_USERS_URL + '/forgot?=' + email)
            .pipe(catchError(this.handleError('forgot-password', []))
            );
    }


    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(this.API_USERS_URL);
    }

    getUserById(userId: number): Observable<User> {
        return this.http.get<User>(this.API_USERS_URL + `/${userId}`);
    }


    // DELETE => delete the user from the server
    deleteUser(userId: number) {
        const url = `${this.API_USERS_URL}/${userId}`;
        return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
    updateUser(_user: User): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.put(this.API_USERS_URL, _user, { headers: httpHeaders }).pipe(catchError(this.handleError("updateObject", [])));
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: User): Observable<User> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(this.API_USERS_URL, user, { headers: httpHeaders });
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + userToken);
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<QueryResultsModel>(this.API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
    }

    // Permission
    getAllPermissions(): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<QueryResultsModel>(this.API_AUTH_URL + '/permissions', {
            headers: httpHeaders
        }).pipe(catchError(this.handleError("getAllPermissions", []))
        );
    }

    getRolePermissions(roleId: number): Observable<Rolepermission> {
        return this.http.get<Rolepermission>(this.API_AUTH_URL + '/rolepermissions/' + roleId);
    }

    getUserPermissions(): Observable<Permission[]> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<Permission[]>(this.API_AUTH_URL + '/userpermissions', { headers: httpHeaders })
            .pipe(catchError(this.handleError("getUserPermissions", [])));
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(this.API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
        return this.http.get<Role>(this.API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
    createRole(role: Role): Observable<Role> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<Role>(this.API_ROLES_URL, role, { headers: httpHeaders });
    }

    // UPDATE => PUT: update the role on the server
    updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(this.API_ROLES_URL, role, { headers: httpHeaders });
    }

    // DELETE => delete the role from the server
    deleteRole(roleId: number): Observable<Role> {
        const url = `${this.API_ROLES_URL}/${roleId}`;
        return this.http.delete<Role>(url);
    }

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(this.API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<QueryResultsModel>(this.API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders });
    }

    public getIPAddress() {
        return this.http.get("https://api.ipify.org/?format=json");
    }

    getErrorDispatcher(error: any, objectType: ObjectType = ObjectType.Any, httpAPICallType: HttpAPICallType = HttpAPICallType.GetList): MaytechBaseAction {
        return new AuthUserErrorAction(this.actionPrefix, {
            obj: {
                items: [],
                totalCount: 0,
                errorMessage: error.message,
                errorCode: error.status,
                pageIndex: 0
            }
        });
    }

    loadPermissionOnInit() {
        return new Promise<void>((resolve, reject) => {
            const userToken = localStorage.getItem(environment.authTokenKey);
            if (userToken) {
                this.getUserPermissions().toPromise().then(res => {
                    this.permissionsService.flushPermissions();
                    if (!res || res.length === 0) {
                        return;
                    }
                    console.log('loadRolesWithPermissions');
                    res.forEach((pm: Permission) => this.permissionsService.addPermission(pm.permissionCode));
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
     *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    //private handleError<T>(operation = 'operation', result?: any) {
    //    return (error: any): Observable<any> => {
    //        // TODO: send the error to remote logging infrastructure
    //        console.error(error); // log to console instead

    //        // Let the app keep running by returning an empty result.
    //        return of(result);
    //    };
    //}
}
