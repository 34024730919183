// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material/dialog';
// RxJS
import { Observable, BehaviorSubject, Subscription, of } from 'rxjs';
import { map, startWith, delay, first } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../_reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, BaseModel, AlertMessageType } from '../_base/crud';

import { MaytechService } from './maytech.service';
import { selectObjectById, selectLastCreatedObjectId, selectLastUpdatedObjectId, selectError } from './maytech.selectors';
import { ObjectOnServerCreated, ObjectUpdated, ObjectOnServerUpdated } from './maytech.actions';
import { filter } from 'minimatch';
import { HttpAPICallType } from '../_utils/define';
import { TranslateService } from '@ngx-translate/core';
import { HelpEventService } from '../../views/partials/layout/help/help-event.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Directive()
export class MaytechEditComponent implements OnInit, OnDestroy {
    // Public properties
   _object: BaseModel;
    get object(): BaseModel {
        return this._object;
    }
    set object(value: BaseModel) {
        this._object = value;
    }

    objectId$: Observable<number>;
    oldMaytech: BaseModel;
    loadingSubject = new BehaviorSubject<boolean>(true);
    loading$: Observable<boolean>;
    isLoading: boolean = false;
    objectForm: FormGroup;
    hasFormErrors: boolean = false;
    isGoback: boolean = false;
    saveAndAddNew: boolean = false;
    disabledEdit = false;
    isFormChanges = false;

    listUrl: string = '';
    actionPrefix: string = '';
    // Private password
    protected subscriptions: Subscription[] = [];
    // sticky portlet header margin
    protected headerMargin: number;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param objectFB: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param objectService: MaytechsService
	 * @param cdr: ChangeDetectorRef
	 */
    constructor(
        protected helpEventService: HelpEventService,
        protected permissionsService: NgxPermissionsService,
        public translate: TranslateService,
        protected store: Store<AppState>,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected typesUtilsService: TypesUtilsService,
        protected objectFB: FormBuilder,
        public dialog: MatDialog,
        protected subheaderService: SubheaderService,
        protected layoutUtilsService: LayoutUtilsService,
        protected layoutConfigService: LayoutConfigService,
        protected objectService: MaytechService,
        public cdr: ChangeDetectorRef) {
    }
    /**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
    ngOnInit() {

        this.loading$ = this.loadingSubject.asObservable();
        this.setLoading(true);
        this.subscriptions.push(
            this.activatedRoute.params.subscribe(params => {
                const id = params['id'];
                if (id && id > 0) {
                    this.loadObjectFromService(id);
                    //this.store.pipe(
                    //    select(selectObjectById(this.actionPrefix,id))
                    //).subscribe(result => {
                    //    if (!result) {
                    //        this.loadObjectFromService(id);
                    //        return;
                    //    }

                    //    this.loadObject(result);
                    //});
                } else {
                    this.initNewObject();
                }
            }));

        // sticky portlet header
        window.onload = () => {
            const style = getComputedStyle(document.getElementById('kt_header'));
            this.headerMargin = parseInt(style.height, 0);
        };
        this.subscriptions.push(
            this.store.pipe(
                select(selectLastCreatedObjectId(this.actionPrefix))
            ).subscribe(result => {
                if ((result.httpAPICallType == HttpAPICallType.Create) && this.isLoading) {
                    if (result.id > 0) {
                        this.setLoading(false);
                        if (this.isGoback) {
                            this.goBack(result.id);
                        } else {
                            const message = this.translate.instant("SETTING.PROFILE.ADDED");
                            this.layoutUtilsService.showActionNotification(message, AlertMessageType.Create, 5000, true, true);
                            this.refreshObject(true, result.id, this.saveAndAddNew);
                        }
                        this.setLoading(false);
                    }

                }

            }
            )
        );
        const updateSubscription = this.store.pipe(select(selectLastUpdatedObjectId(this.actionPrefix))).subscribe(result => {
            const successMsg = this.translate.instant("SETTING.GENERAL.SAVE_SUCCEED");
            const failedMsg = this.translate.instant("SETTING.GENERAL.SAVE_FAILED");
            if ((result.httpAPICallType == HttpAPICallType.Update) && this.isLoading) {
                if (result.id > 0) {
                    this.layoutUtilsService.showActionNotification(successMsg, AlertMessageType.Update, 5000, true, true);
                    if (this.isGoback) {
                        this.goBackWithoutId();
                    } else {
                        this.refreshObject(false, result.id, false);
                    }
                } else {
                    this.layoutUtilsService.showActionNotification(failedMsg, AlertMessageType.Update, 5000, true, true);
                }
                this.setLoading(false);
            }

        });
        this.subscriptions.push(updateSubscription);
        this.subscriptions.push(
            this.store.pipe(
                select(selectError(this.actionPrefix))
            ).subscribe(
                (response) => {
                    if (response.errorMessage != "" && this.isLoading) {
                        const message = response.errorMessage;
                        this.layoutUtilsService.showActionNotification(message, AlertMessageType.Create, 5000, false, false);
                        this.setLoading(false);
                    }
                }
            )
        );

        this.catchObjectError();
    }

    catchObjectError() {}

    setLoading(isLoading: boolean) {
        if (this.isLoading != isLoading) {
            this.loadingSubject.next(isLoading);
            this.isLoading = isLoading
        }
    }
    loadObject(_object, fromService: boolean = false) {
        this.setLoading(false);
        if (!_object) {
            this.goBack('');
        }
        this.object = _object;
        this.objectId$ = of(_object._id);
        this.oldMaytech = Object.assign({}, _object);
        this.initObject();
        if (fromService) {
            this.cdr.detectChanges();
        }
    }
    initNewObject() {
        const newObj = new BaseModel();
        this.loadObject(newObj);
    }

    // If object didn't find in store
    loadObjectFromService(objectId) {

        this.setLoading(true);
        this.subscriptions.push(this.objectService.getObjectById(objectId).subscribe(res => {
            if (res) {// success
                this.loadObject(res, true);
            }
            this.setLoading(false);
        }));
    }

	/**
	 * On destroy
	 */
    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

	/**
	 * Init object
	 */
    initObject() {
        this.createForm();
        this.setLoading(false);
        if (!this.object._id) {
            this.subheaderService.setBreadcrumbs([
                { title: 'eCommerce', page: `/ecommerce` },
                { title: 'Maytechs', page: `/ecommerce/objects` },
                { title: 'Create object', page: `/ecommerce/objects/add` }
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit object');
        this.subheaderService.setBreadcrumbs([
            { title: 'eCommerce', page: `/ecommerce` },
            { title: 'Maytechs', page: `/ecommerce/objects` },
            { title: 'Edit object', page: `/ecommerce/objects/edit`, queryParams: { id: this.object._id } }
        ]);
    }

	/**
	 * Create form
	 */
    createForm() {

    }


	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
    goBack(id) {
        this.setLoading(false);
        const url = this.listUrl;//`oxalis/object?id=${id}`;
        this.router.navigateByUrl(url);
    }

    goBackWithoutId() {
        this.router.navigateByUrl(this.listUrl);
    }

	/**
	 * Refresh object
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
    refreshObject(isNew: boolean = false, id: number = 0, saveAndAddNew: boolean = false) {
        //this.setLoading(false);
        let url = this.router.url;
        if (isNew) {
            if (saveAndAddNew)
                this.router.navigateByUrl(url);
            else {
                url = `${url}/${id}`;
                this.router.navigateByUrl(url);
            }
        } else {//update
            this.router.navigateByUrl(url);
        }
    }

	/**
	 * Reset
	 */
    reset() {
        this.object = Object.assign({}, this.oldMaytech);
        this.createForm();
        this.hasFormErrors = false;
        this.objectForm.markAsPristine();
        this.objectForm.markAsUntouched();
        this.objectForm.updateValueAndValidity();
    }

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
    onSumbit(withBack: boolean = false, saveAndAddNew: boolean = false) {
        this.hasFormErrors = false;
        this.isFormChanges = false;

        const controls = this.objectForm.controls;
        /** check form */
        if (this.objectForm.invalid) {
            this.objectForm.markAllAsTouched();
            this.hasFormErrors = true;
            this.cdr.detectChanges();
            this.onSubmitFail();
            return;
        }

        // tslint:disable-next-line:prefer-const
        let editedMaytech = this.prepareObject();

        if (!editedMaytech || !this.onCustomValidation(editedMaytech)) {
            this.hasFormErrors = true;
            this.cdr.detectChanges();
            this.onSubmitFail();
            return;
        }
        this.cdr.detectChanges();

        if (editedMaytech._id > 0) {
            this.updateObject(editedMaytech, withBack);
            return;
        }

        this.addObject(editedMaytech, withBack, saveAndAddNew);
    }

    onCustomValidation(_object: BaseModel): boolean {
        return true;
    }

    onSubmitFail() {
    }

	/**
	 * Returns object for saving
	 */
    prepareObject(): BaseModel {
        const controls = this.objectForm.controls;
        const _object = new BaseModel();
        return _object;
    }

	/**
	 * Add object
	 *
	 * @param _object: BaseModel
	 * @param withBack: boolean
	 */
    addObject(_object: BaseModel, withBack: boolean = false, saveAndAddNew: boolean = false) {
        this.setLoading(true);
        this.store.dispatch(new ObjectOnServerCreated(this.actionPrefix, { obj: _object }));
        this.saveAndAddNew = saveAndAddNew;

    }

	/**
	 * Update object
	 *
	 * @param _object: BaseModel
	 * @param withBack: boolean
	 */
    updateObject(_object: BaseModel, withBack: boolean = false) {
        this.store.dispatch(new ObjectOnServerUpdated(this.actionPrefix, { obj: _object }));
        this.isGoback = withBack;

    }

	/**
	 * Returns component title
	 */
    getComponentTitle() {
        let result = 'Create object';
        if (!this.object || !this.object._id) {
            return result;
        }

        result = `Edit object - ${this.object._id}`;
        return result;
    }

	/**
	 * Close alert
	 *
	 * @param $event
	 */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    showHelpContext(controlName: string) {
        this.helpEventService.showHelpContent(controlName);
    }

    isFromValid(): boolean {
        return !this.objectForm.invalid;
    }
}
