import { OnInit, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResultMessageType, EditRequestType, PaymentType } from '../../../../core/_utils/define';

@Component({
    selector: 'mt-edit-request-value',
    templateUrl: './mt-edit-request-value.component.html'
})

export class MtEditRequestValueComponent implements OnInit {
    @Input() inputType: number = 0;
    @Input() value: string = '';
    checked: boolean = false;

    constructor(
        protected translate: TranslateService) { }

    ngOnInit() {
        switch (this.inputType) {
            case EditRequestType.CheckBox:
                this.checked = this.value == '1' ? true : false;
                break;
        }

    }

    get editRequestType() { return EditRequestType; }

    getPaymentTypeLabel(paymentType: string): string {
        return this.translate.instant("ENUM.PaymentType." + PaymentType[(<PaymentType>(Number(paymentType)))]);
    }
}
