import { BaseModel } from '../core/_base/crud';

export class Permission extends BaseModel {
    permissionId: number;
    permissionName: string;
    permissionCode: string;
    permissionStatus: number;
    level: number;
    parentId: number;
    _isSelected: boolean;
    _children: Permission[];

    clear(): void {
        this.permissionId = undefined;
        this.permissionName = '';
        this.permissionCode = '';
        this.permissionStatus = undefined;
        this.level = 1;
        this.parentId = undefined;
        this._isSelected = false;
        this._children = [];
    }
}
