import { Component } from '@angular/core';
import { OffcanvasOptions } from '../../../../core/_base/layout';

@Component({
    selector: 'mt-quick-help',
    templateUrl: './quick-help.component.html',
    styleUrls: ['./quick-help.component.scss']
})

export class QuickHelpComponent {
    offcanvasOptions: OffcanvasOptions = {
        overlay: false,
        baseClass: 'mt-quick-help',
        closeBy: 'mt_quick_help_close_btn',
        toggleBy: 'mt_quick_help_toggler_btn'
    };
}
