import { BaseModel } from '../core/_base/crud';
import { InvoiceModel } from './invoice.model';

export class PaymentModel extends BaseModel {
    paymentId: number;
    bookingId: number;
    paymentStatus: number;
    paymentType: number;
    paymentDate: string;
    paymentTime: string;
    paymentDateServer: string;
    refNumber: string;
    amount: number;
    currencyId: number;
    currencyCode: string;
    notes: string;
    createdDate: string;
    createdBy: number;
    updatedDate: string;
    updatedBy: number;
    invoice: InvoiceModel;

    clear(bookingId: number) {
        this.paymentId = undefined;
        this.bookingId = bookingId;
        this.paymentStatus = 0;
        this.paymentType = 0;
        this.paymentDate = '';
        this.paymentTime = '';
        this.paymentDateServer = '';
        this.refNumber = '';
        this.amount = 0;
        this.currencyId = 0;
        this.currencyCode = '';
        this.notes = '';
        this.createdDate = '';
        this.createdBy = 0;
        this.updatedDate = '';
        this.updatedBy = 0;
        this.invoice = undefined;
    }
}
