import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaytechService } from '../../_maytech/maytech.service';
import { HttpUtilsService, BaseModel, QueryResultsModel, QueryParamsModel } from '../../_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { ObjectType, HttpAPICallType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { UserErrorAction } from '../_actions/user.actions';
import { Observable } from 'rxjs';
import { User } from '../../../models/user.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/users';

    }
    getErrorDispatcher(error: any, objectType: ObjectType = ObjectType.Any, httpAPICallType: HttpAPICallType = HttpAPICallType.GetList): MaytechBaseAction {
        return new UserErrorAction(this.actionPrefix, {
            obj: {
                items: [],
                totalCount: 0,
                errorMessage: error.message,
                errorCode: error.status,
                pageIndex: 0
            }
        });
    }

    getBookingRepresentor(bookingId: number): Observable<User> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/getBookingRepresentor/${bookingId}`;
        return this.http.get<User>(url, { headers: httpHeaders });
    }

    getBookingSeller(bookingId: number): Observable<User> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/getBookingSeller/${bookingId}`;
        return this.http.get<User>(url, { headers: httpHeaders });
    }

    getBookingRepresentorList(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
        const url = this.API_URL + `/getBookingRepresentor`;
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders, params: httpParams });
    }

    getGetOnlyUsers(): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + `/getonlyusers`;
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
    }
}
