import { forkJoin, Subject } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, withLatestFrom, filter, takeUntil, take } from 'rxjs/operators';
// NGRX
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel, BaseModel } from '../../_base/crud';
// Services
import { OptionService } from '../_services/option.service';
// State
import { AppState } from '../../_reducers';
import { defer, Observable, of } from 'rxjs';
import { ObjectEffects } from '../../_maytech/maytech.effects';
import { Update } from '@ngrx/entity';
import { OptionActionTypes, OptionPageRequested, OptionPageLoaded, OptionUpdated, OptionOnServerUpdated, OptionPageToggleLoading, OptionErrorAction } from '../_actions/option.actions';

@Injectable()
export class OptionEffects extends ObjectEffects {
    constructor(actions: Actions, optionService: OptionService, store: Store<AppState>) {
        super(actions, optionService, store);
        this.actionPrefix = 'options';
        
    }

    showPageLoadingDistpatcher = new OptionPageToggleLoading(this.actionPrefix, { isLoading: true });
    showLoadingDistpatcher = new OptionPageToggleLoading(this.actionPrefix, { isLoading: true });
    hideActionLoadingDistpatcher = new OptionPageToggleLoading(this.actionPrefix, { isLoading: false });

    
    loadOptionPage$ = createEffect(() => this.actions$.pipe(
        ofType<OptionPageRequested>(OptionActionTypes.OptionPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.objsService.findObjects(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new OptionPageLoaded(this.actionPrefix, {
                objs: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
        })
    ));
    
    updateOptions$ = createEffect(() => this.actions$.pipe(
        ofType<OptionOnServerUpdated>(OptionActionTypes.OptionOnServerUpdated),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showLoadingDistpatcher);
            return this.objsService.updateObjects(payload.objs);
        }),
        map(response => {
            if (response.items) {
                const rsModel = (response as unknown as QueryResultsModel);
                if (rsModel.errorCode != undefined) {
                    return new OptionErrorAction(this.actionPrefix, { obj: rsModel });
                } else {
                    return new OptionUpdated(this.actionPrefix, { objs: response.items });
                }
            }
            return this.hideActionLoadingDistpatcher;
        }),
    ));
}
