import { BaseModel } from '../core/_base/crud';

export class EditRequestModel extends BaseModel {
    editRequestId: number;
    editRequestStatus: number;
    module: number;
    subModule: number;
    objectType: number;
    objectId: number;
    fieldType: number;
    fieldName: string;
    displayFieldName: string;
    hasTranslate: boolean;
    oldValue: string;
    newValue: string;
    notes: string;
    masterObjectId: number;
    link: string;
    createdDate: string;
    createdBy: number;
    approvedDate: string;
    approvedBy: number;
    approvedNotes: string;
    createdByUser: string;
    approvedByUser: string;

    clear() {
        this.editRequestId = 0;
        this.editRequestStatus = 0;
        this.module = 0;
        this.subModule = 0;
        this.objectType = 0;
        this.objectId = 0;
        this.fieldType = 0;
        this.fieldName = '';
        this.displayFieldName = '';
        this.hasTranslate = true;
        this.oldValue = '';
        this.newValue = '';
        this.notes = '';
        this.masterObjectId = 0;
        this.link = '';
        this.createdDate = '';
        this.createdBy = 0;
        this.approvedDate = '';
        this.approvedBy = 0;
        this.approvedNotes = '';
        this.createdByUser = '';
        this.approvedByUser = '';
    }
}  