import { BaseModel } from '../core/_base/crud';

export class AuthModel extends BaseModel {
    userEmail: string;
    userPassword: string;
    ipAddress: string;
    userAgent: string;
    tenantId: number;

    clear(): void {
        this.userEmail = '';
        this.userPassword = '';
        this.ipAddress = '';
        this.userAgent = '';
        this.tenantId = undefined;
    }
}
