import { BaseModel } from '../core/_base/crud';

export class CronJobModel extends BaseModel {
    cronJobId: number;
    cronJobName: string;
    cronJobStatus: number;
    cronJobType: number;
    interval: number;
    cronJobDetail: string;
    createdDate: string;
    createdBy: number;
    updatedDate: string;
    updatedBy: number;

    clear() {
        this.cronJobId = 0;
        this.cronJobName = '';
        this.cronJobStatus = 0;
        this.cronJobDetail = '';
        this.createdDate = '';
        this.createdBy = 0;
        this.updatedDate = '';
        this.updatedBy = 0;
        this.interval = 0;
        this.cronJobType = 0;
    }

}
