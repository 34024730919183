import { BaseModel } from '../core/_base/crud';


export class RecentEmailModel extends BaseModel {
    messageLogId: number;
    sentDate: string;
    messageOpenStatus: number;
    messageSendStatus: number;
    toAddress: string;
    objectType: number;
    objectId: number;
    bookingId: number;
    
    bookingNumber: string;
    messageTemplateName: string

    clear(): void {
        this.messageLogId = 0;
        this.sentDate = '';
        this.messageOpenStatus = 0;
        this.messageSendStatus = 0;
        this.toAddress = '';
        this.objectType = 0;
        this.objectId = 0;
        this.bookingId = 0;
        this.bookingNumber = '';
        this.messageTemplateName = '';
    }
}
