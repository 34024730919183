import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterText' })
export class FilterTextPipe implements PipeTransform {
    public transform(objects: any[], searchText: any): any {
        if (searchText == null || objects == null) {
            return objects;
        }
        return objects.filter(obj => obj.Text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    }
}
