// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { User } from '../../../models/user.model';
// Models
import { QueryParamsModel, BaseModel, QueryResultsModel } from '../../_base/crud';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { ObjectType } from '../../_utils/define';

export enum UserActionTypes {
    AllUsersRequested = '[Users Module] All Users Requested',
    AllUsersLoaded = '[Users API] All Users Loaded',
    UserOnServerCreated = '[Edit User Component] User On Server Created',
    UserOnServerUpdated = '[Edit User Component] User On Server Updated',
    UserOnServerDeleted = '[Users List Page] User On Server Deleted',
    UserCreated = '[Edit User Component] User Created',
    UserUpdated = '[Edit User Component] User Updated',
    UserDeleted = '[Users List Page] User Deleted',
    UsersPageRequested = '[Users List Page] Users Page Requested',
    UsersPageLoaded = '[Users API] Users Page Loaded',
    UsersPageCancelled = '[Users API] Users Page Cancelled',
    UsersPageToggleLoading = '[Users] Users Page Toggle Loading',
    UsersActionToggleLoading = '[Users] Users Action Toggle Loading',
    UserErrorAction = '[User Component] Error Action',
    ClearUserError = '[User Component] Clear Error',
    RemoveAllUserStore = '[Users API] Remove All User Store',
}

export class RemoveAllUserStore implements MaytechBaseAction {
    readonly type = UserActionTypes.RemoveAllUserStore;
    constructor(public actionPrefix: string) { }
}

export class UserErrorAction implements MaytechBaseAction {
    readonly type = UserActionTypes.UserErrorAction;
    constructor(public actionPrefix: string, public payload: { obj: QueryResultsModel }) { }
}

export class ClearUserError implements MaytechBaseAction {
    readonly type = UserActionTypes.ClearUserError;
    constructor(public actionPrefix: string) { }
}

export class UserOnServerCreated implements MaytechBaseAction {
    readonly type = UserActionTypes.UserOnServerCreated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class UserCreated implements MaytechBaseAction {
    readonly type = UserActionTypes.UserCreated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class UserOnServerUpdated implements MaytechBaseAction {
    readonly type = UserActionTypes.UserOnServerUpdated;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class UserUpdated implements MaytechBaseAction {
    readonly type = UserActionTypes.UserUpdated;
    constructor(public actionPrefix: string, public payload: {
        partialUser: Update<BaseModel>,
        user: BaseModel
    }) { }
}

export class UserOnServerDeleted implements MaytechBaseAction {
    readonly type = UserActionTypes.UserOnServerDeleted;
    constructor(public actionPrefix: string, public payload: { id: number }) { }
}

export class UserDeleted implements MaytechBaseAction {
    readonly type = UserActionTypes.UserDeleted;
    constructor(public actionPrefix: string, public payload: { id: number }) {}
}

export class UsersPageRequested implements MaytechBaseAction {
    readonly type = UserActionTypes.UsersPageRequested;
    constructor(public actionPrefix: string, public payload: { page: QueryParamsModel }) { }
}

export class UsersPageLoaded implements MaytechBaseAction {
    readonly type = UserActionTypes.UsersPageLoaded;
    constructor(public actionPrefix: string, public payload: { objs: BaseModel[], totalCount: number, page: QueryParamsModel  }) { }
}


export class UsersPageCancelled implements MaytechBaseAction {
    readonly type = UserActionTypes.UsersPageCancelled;
    constructor(public actionPrefix: string) { }
}

export class UsersPageToggleLoading implements MaytechBaseAction {
    readonly type = UserActionTypes.UsersPageToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export class UsersActionToggleLoading implements MaytechBaseAction {
    readonly type = UserActionTypes.UsersActionToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export type UserActions = RemoveAllUserStore
    | ClearUserError
    | UserCreated
    | UserUpdated
    | UserDeleted
    | UserOnServerCreated
    | UserOnServerUpdated
    | UserOnServerDeleted
    | UsersPageLoaded
    | UsersPageCancelled
    | UsersPageToggleLoading
    | UsersPageRequested
    | UsersActionToggleLoading
    | UserErrorAction;
