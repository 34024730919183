import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';

import { Observable } from 'rxjs';
@Injectable()
export class GeneralSearchService {
    API_URL: string;
    constructor(private http: HttpClient,
        private httpUtils: HttpUtilsService,
        private tenantService: MaytechTenantService) {

          if (this.tenantService.currentClient != null)
            this.API_URL = this.tenantService.currentClient.apiurl + '/search/';

    }
    // Server should return filtered/sorted result
    findObjects(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = this.API_URL;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams
        });
    }

}
