import { OnInit, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { SubModule, Module, EditRequestStatus, ObjectType } from '../../../../../../core/_utils/define';
import { EditRequestModel } from '../../../../../../models/editrequest.model';
import { EditRequestApprovalModel } from '../../../../../../models/editrequestapproval.model';
import { EditRequestService } from '../../../../../../core/setting';
import { LayoutUtilsService, AlertMessageType } from '../../../../../../core/_base/crud';

@Component({
    selector: 'kt-edit-request-details-dialog',
    templateUrl: './edit-request-details-dialog.component.html'
})
export class EditRequestDetailsDialogComponent implements OnInit {
    request: EditRequestModel;

    constructor(
        public dialogRef: MatDialogRef<EditRequestDetailsDialogComponent>,
        private translate: TranslateService,
        private editRequestService: EditRequestService,
        private layoutUtilsService: LayoutUtilsService,
        public objectFB: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.request = data;
    }

    ngOnInit() {
    }

    getModuleLabel(module: number, subModule: number): string {
        let moduleText = this.translate.instant("ENUM.Module." + Module[(<Module>module)]);
        let subModuleText = this.translate.instant("ENUM.SubModule." + SubModule[(<SubModule>subModule)]);
        return subModuleText.length == 0 ? moduleText : moduleText + ' (' + subModuleText + ')';
    }

    getStatusLabel(status: number): string {
        return this.translate.instant("ENUM.EditRequestStatus." + EditRequestStatus[(<EditRequestStatus>status)]);
    }

    //getDislayFieldName(displayFieldName: string, hasTranslate: boolean): string {
    //    return hasTranslate ? this.translate.instant(displayFieldName) : displayFieldName;
    //}

    onCancelClick(): void {
        this.dialogRef.close();
    }

    getItemCssClassByStatus(status: number = 0): string {
        switch (status) {
            case 0:
                return 'metal';
            case 1:
                return 'confirmed';
            case 2:
                return 'danger';
        }
        return '';
    }
}
