import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypesUtilsService } from '../../../../core/_base/crud';

@Component({
    selector: 'mt-filter-daterange',
    templateUrl: './mt-filter-daterange.component.html',
    styleUrls: ['./mt-filter-daterange.component.scss']
})

export class MtFilterDaterangeComponent {
    @Input() mtModel: any[];
    @Input() icon: string = 'event';
    @Input() iconPos: string = 'right';
    @Input() label: string = '';

    @Input() showClearBtn: boolean = true;

    @Output() mtModelChange = new EventEmitter<any[]>();
    @Output() change = new EventEmitter<any[]>();

    constructor() {
        this.label = this.label.length > 0 ? this.label + ': ' : '';
    }

    getSelectedDateLabel() {
        var utils = new TypesUtilsService();
        let selectedDate = '';

        if (this.mtModel.length > 0 && this.mtModel[0] != '' && this.mtModel[1] != '') {
            const fromDate = utils.dateFormatDDMMYYYY(new Date(this.mtModel[0]));
            const toDate = utils.dateFormatDDMMYYYY(new Date(this.mtModel[1]));
            selectedDate = `${fromDate} - ${toDate}`;

        }

        return this.label + ':  <font color="primary">' + selectedDate + '</font>';
    }

    clearInput() {
        this.mtModel = ['', ''];
        this.mtModelChange.emit(this.mtModel);
        this.change.emit(this.mtModel);
    }

    dateTimeInput() {
        this.mtModelChange.emit(this.mtModel);
        this.change.emit(this.mtModel);
    }
}
