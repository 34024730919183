import { BaseModel } from '../core/_base/crud';
import { CustomerInfoModel } from './customerinfo.model';


export class CustomerModel extends BaseModel {
    customerId: number;
    customerStatus: number;
    customerType: number;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    gender: number;
    birthDate: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    phone: string;
    notes: string;
    customerGroupId: number;
    createdDate: string;
    createdBy: number;
    updatedDate: string;
    updatedBy: number;
    mandatoryInternals: string[];
    customerInfoList: CustomerInfoModel[];

    clear() {
        this.customerId = 0;
        this.customerStatus = 0;
        this.customerType = 0;
        this.firstName = '';
        this.middleName = '';
        this.lastName = '';
        this.email = '';
        this.gender = 0;
        this.birthDate = '';
        this.address = '';
        this.city = '';
        this.state = '';
        this.zipcode = '';
        this.country = '';
        this.phone = '';
        this.notes = '';
        this.customerGroupId = 0;
        this.createdDate = '';
        this.createdBy = 0;
        this.updatedDate = '';
        this.updatedBy = 0;
        this.mandatoryInternals = [];
        this.customerInfoList = [];
    }
}
