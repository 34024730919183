// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { UserService } from '../../core/auth/_services/user.service';
import { UserDevicesComponent } from './user-management/users/_subs/user-devices/user-devices.component';
import { DeviceService } from '../../core/auth/_services';
import { StoreModule } from '@ngrx/store';
import { devicesReducer } from '../../core/auth/_reducers/device.reducers';
import { EffectsModule } from '@ngrx/effects';
import { DeviceEffects } from '../../core/auth/_effects/device.effects';
import { TranslateModule } from '@ngx-translate/core';

import { NgxPermissionsModule } from 'ngx-permissions';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        PartialsModule,
        TranslateModule.forChild(),
		NgxPermissionsModule.forChild(),        
        MatButtonModule,
        MatMenuModule,
        MatInputModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTabsModule,
        StoreModule.forFeature('userdevices', devicesReducer('userdevices')),
        EffectsModule.forFeature([DeviceEffects])
    ],
    providers: [
        UserService,
        DeviceService
    ],
    declarations: [
        UserDevicesComponent
    ],
    exports: [
        UserDevicesComponent
    ]
})
export class PagesModule {
}
