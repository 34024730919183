// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// CRUD

import { Client } from '../../models/client.model';
import { ComponentCanDeactivate } from '../_utils/can-deactivate-guard';
import { MaytechTenantService } from './maytech.tenant.service';

@Directive()
export class MaytechComponent extends ComponentCanDeactivate implements OnInit {
    public client: Client;
    constructor(protected tenantService: MaytechTenantService) {
        super();
    }
    canDeactivate(): boolean {
        return true;
    }

    ngOnInit(): void {
        if (this.tenantService.currentClient != null)
            this.client = this.tenantService.currentClient;
    }
    
}
