import { BaseModel } from '../core/_base/crud';


export class ContextHelpModel extends BaseModel {
    contextHelpId: number;
    contextHelpStatus: number;
    name: string;
    language: string;
    content: string;

    clear() {
        this.contextHelpId = 0;        
        this.contextHelpStatus=0;
        this.name='';
        this.language = '';
        this.content = '';
    }
}
