// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

// State

import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { MaytechSimpleEditComponent } from '../../../../../../core/_maytech/maytech-simple-edit.component';
import { HelpEventService } from '../../../help/help-event.service';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';
import { TypesUtilsService } from '../../../../../../core/_base/crud';
import { NotificationModel } from '../../../../../../models/Notification.model';
import { NotificationService } from '../../../../../../core/setting/_services/notification.service';

@Component({
    selector: 'kt-notification-edit-dialog',
    templateUrl: './notification-detail.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class NotificationDetailDialogComponent extends MaytechSimpleEditComponent {
    viewLoading: boolean = false;
    loadingAfterSubmit: boolean = false;
    constructor(
        dataService: NotificationService,
        helpEventService: HelpEventService,
        permissionsService: NgxPermissionsService,
        translate: TranslateService,
        activatedRoute: ActivatedRoute,
        router: Router,
        layoutUtilsService: LayoutUtilsService,
        formBuilder: FormBuilder,
        cdr: ChangeDetectorRef,
        private typesUtilsService: TypesUtilsService, @Inject(MAT_DIALOG_DATA) public data: any) {

        super(helpEventService, permissionsService, translate, activatedRoute, router, layoutUtilsService, formBuilder, cdr, dataService);
        this.listUrl = '/setting/tenant';
        this.isFormChanges = false;
    }
    ngOnInit() {
        this.loading$ = this.loadingSubject.asObservable();
        this.setLoading(true);
       
        if (this.data.notificationId > 0) {
            this.initObject(this.data.notificationId);
           
        }
        
    }
    get object(): NotificationModel {
        return this._object as NotificationModel;
    }
    set object(value: NotificationModel) {
        this._object = value;
    }
    getTitle(): string {
       
        return this.translate.instant('USER_MANAGEMENT.ROLE.NEW_ROLE_BUTTON');
       
    }

}
