import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MaytechTenantService } from './maytech.tenant.service';
import { HttpUtilsService } from '../_base/crud';
import { catchError } from 'rxjs/operators';

export class MaytechTranslationLoader implements TranslateLoader {
    API_URL: string = "";
    constructor(
        private http: HttpClient,
        private tenantService: MaytechTenantService) {

        if (this.tenantService != null) {
            if (this.tenantService.currentClient != null)
                this.API_URL = this.tenantService.currentClient.apiurl + '/Dictionary';
        }
        //else {

        //    this.API_URL = "http://localhost:61247/api/Dictionary";
        //    //this.API_URL = "https://oxalis-heart.maytech.vn:8080/api/Dictionary";
        //}
    }

    getTranslation(lang: string): Observable<any> {
        if (this.API_URL != "") {
            return this.http.get(this.API_URL + "/GetLanguageJson/" + lang).pipe(catchError(this.handleLangError("GetLanguageJson", lang)));
        } else {
            return this.http.get("/assets/lang/" + lang + ".lang");
        }
    }

    public handleLangError<T>(operation: string = 'operation', lang: string) {
        return (error: any): Observable<any> => {

            let message = "API Error!!!";
            if (error.error && error.error.errorMessage)
                message = error.error.errorMessage;
            else if (error.errorMessage)
                message = error.errorMessage;
            console.error(`Operation: ${operation}\nError Code: ${error.status}\nMessage: ${message}`); // log to console 
            
            return this.http.get("/assets/lang/" + lang + ".lang");
        };
    }
}
