// Angular
import { Injectable } from '@angular/core';
// RxJS
import { of, Observable, defer, forkJoin } from 'rxjs';
import { mergeMap, map, withLatestFrom, filter, tap } from 'rxjs/operators';
// NGRX
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// State
import { AppState } from '../../../core/_reducers';
import { ObjectEffects } from '../../_maytech/maytech.effects';
import { RoleService } from '../_services/role.service';
import { RolesPageRequested, RoleActionTypes, RolesPageLoaded, OneRoleDeleted, RoleUpdated, RoleOnServerCreated, RoleCreated, AllRolesRequested, AllRolesLoaded, RolesPageToggleLoading, RoleOnServerUpdated, RoleErrorAction, RolesActionToggleLoading, RoleOnServerDeleted } from '../_actions/role.actions';
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
import { allRolesLoaded } from '../_selectors/role.selectors';
import { Update } from '@ngrx/entity';
import { Role } from '../../../models/role.model';

@Injectable()
export class RoleEffects extends ObjectEffects {
    constructor(actions: Actions, service: RoleService, store: Store<AppState>) {
        super(actions, service, store);
        this.actionPrefix = 'roles';

    }
    showPageLoadingDistpatcher = new RolesPageToggleLoading(this.actionPrefix, { isLoading: true });
    hidePageLoadingDistpatcher = new RolesPageToggleLoading(this.actionPrefix, { isLoading: false });

    showActionLoadingDistpatcher = new RolesActionToggleLoading(this.actionPrefix, { isLoading: true });
    hideActionLoadingDistpatcher = new RolesActionToggleLoading(this.actionPrefix, { isLoading: false });

    
    loadAllRoles$ = createEffect(() => this.actions$
        .pipe(
            ofType<AllRolesRequested>(RoleActionTypes.AllRolesRequested),
            //withLatestFrom(this.store.pipe(select(allRolesLoaded))),
            //filter(([action, isAllRolesLoaded]) => !isAllRolesLoaded),
            mergeMap(() => this.objsService.getAllObjects()),
            map(res => {
                return new AllRolesLoaded({ objs: res.items });
            })
        ));
    
    loadRolesPage$ = createEffect(() => this.actions$.pipe(
        ofType<RolesPageRequested>(RoleActionTypes.RolesPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.objsService.findObjects(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new RolesPageLoaded(this.actionPrefix, {
                objs: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
        })
    ));
    
    deleteRole$ = createEffect(() => this.actions$
        .pipe(
            ofType<RoleOnServerDeleted>(RoleActionTypes.RoleOnServerDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                return this.objsService.deleteObject(payload.id);
            }),
            map(response => {
                if (response && response._id != undefined) {
                    const rsModel = (response as unknown as QueryResultsModel);
                    if (rsModel.errorCode != undefined) {
                        return new RoleErrorAction(this.actionPrefix, { obj: rsModel });
                    } else {
                        return new OneRoleDeleted(this.actionPrefix, { id: response._id });
                    }
                }
                return this.hidePageLoadingDistpatcher;
            }),
        ));

    
    updateRole$ = createEffect(() => this.actions$
        .pipe(
            ofType<RoleOnServerUpdated>(RoleActionTypes.RoleOnServerUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.objsService.updateObject(payload.obj);
            }),
            map(response => {
                if (response) {
                    const rsModel = (response as unknown as QueryResultsModel);
                    if (rsModel.errorCode != undefined) {
                        return new RoleErrorAction(this.actionPrefix, { obj: rsModel });
                    } else {
                        const updatedRole: Update<Role> = {
                            id: response._id,
                            changes: response
                        };
                        return new RoleUpdated(this.actionPrefix, { partialRole: updatedRole, obj: response });
                    }
                }
                this.store.dispatch(this.hideActionLoadingDistpatcher);
            }),
        ));

    
    createRole$ = createEffect(() => this.actions$
        .pipe(
            ofType<RoleOnServerCreated>(RoleActionTypes.RoleOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.objsService.createObject(payload.obj);
            }),
            map(response => {
                if (response && response._id != undefined) {
                    const rsModel = (response as unknown as QueryResultsModel);
                    if (rsModel.errorCode != undefined) {
                        return new RoleErrorAction(this.actionPrefix, { obj: rsModel });
                    } else {
                        return new RoleCreated(this.actionPrefix, { obj: response });
                    }
                }
                this.store.dispatch(this.hideActionLoadingDistpatcher);
            }),
        ));

    //@Effect()
    //init$: Observable<Action> = defer(() => {
    //    return of(new AllRolesRequested());
    //});
}
