import { BaseModel } from '../core/_base/crud';

export class SupportTicketModel extends BaseModel {
    supportTicketId: number;
    supportTicketStatus: number;
    supportTicketUrl: string;
    createdBy: number;
    createdByUser: string;
    currentUserName: string;
    updatedBy: number;
    screenshot: string;
    notes: string;
    createdDate: string;
    updatedDate: string;
    parentId: number;
    seenStatus: number;

    clear() {
        this.supportTicketId = 0;
        this.supportTicketStatus = 0;
        this.supportTicketUrl = '';
        this.currentUserName = '';
        this.parentId = 0;
        this.seenStatus = 0;
        this.createdBy = 0;
        this.createdByUser = '';
        this.updatedBy = 0;
        this.createdDate = '';
        this.updatedDate = '';
        this.screenshot = '';
        this.notes = '';
    }

}
