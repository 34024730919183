import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Real REST API
@Injectable()
export class RecentEmailService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        tenantService: MaytechTenantService ) {

        super(http, httpUtils, route, null, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/customer';
    }

    getRecentEmail(objectId: number, objectType: number, queryParams: QueryParamsModel): Observable<any> {
        const url = this.API_URL + "/GetRecentEmail/" + objectId.toString() +"/"+ objectType.toString();

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders, params: httpParams})
            .pipe(catchError(this.handleError("getRecentEmail", []))
            );
    }
}
