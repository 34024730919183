
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mt-save-confirm-dialog',
    templateUrl: './save-confirm-dialog.component.html'
})
export class SaveConfirmDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<SaveConfirmDialogComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onYesClick(): void {
        this.dialogRef.close(true);
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
    
    onCancelClick(): void {
        this.dialogRef.close();
    }
}
