import { forkJoin, Subject } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, takeUntil, take, filter } from 'rxjs/operators';
// NGRX
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { ObjectType } from '../_utils/define';
import { MaytechService } from './maytech.service';
import { AppState } from '../_reducers';

export class ObjectEffects {
    public actionPrefix: string;
    public objectType: ObjectType = ObjectType.Any;

    constructor(public actions$: Actions, public objsService: MaytechService, public store: Store<AppState>) {}
}
