// SERVICES
export { GeneralSearchService } from './_services/generalsearch.service';
export { SupportTicketService } from './_services/supportticket.service';

//// DATA SOURCERS
//export { GeneralSearchDataSource } from './_data-sources/generalsearch.datasource';

//// ACTIONS
//export {
//    GeneralSearchActions,
//    GeneralSearchPageRequested,
//    GeneralSearchPageLoaded
//} from './_actions/generalsearch.actions';

//// EFFECTS
//export { GeneralSearchEffects } from './_effects/generalsearch.effects';

//// REDUCERS
//export { generalSearchReducer } from './_reducers/generalsearch.reducers';

// MODELS
export { GeneralSearchModel } from '../../models/generalsearch.model';
export { SupportTicketModel } from '../../models/supportticket.model';

