// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { BaseModel } from '../../_base/crud';
import { Update } from '@ngrx/entity';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';

export enum DeviceActionTypes {
    DeviceStatusUpdated = '[Devices List Page] Devices Status Updated',
    DeviceLogout = '[Devices List Page] Device Logged Out',
    OneDeviceDeleted = '[Devices List Page] One Device Deleted',
    ManyDevicesDeleted = '[Devices List Page] Many Selected Devices Deleted',
    DevicesPageRequested = '[Devices List Page] Devices Page Requested',
    DevicesPageLoaded = '[Devices API] Devices Page Loaded',
    DevicesPageCancelled = '[Devices API] Devices Page Cancelled',
    DevicesPageToggleLoading = '[Devices] Devices Page Toggle Loading',
    DevicesActionToggleLoading = '[Devices] Devices Action Toggle Loading',
    RemoveAllDeviceStore = '[Devices API] Remove All Device Store',
}

export class RemoveAllDeviceStore implements MaytechBaseAction {
    readonly type = DeviceActionTypes.RemoveAllDeviceStore;
    constructor(public actionPrefix: string) { }
}

export class DeviceStatusUpdated implements MaytechBaseAction {
    readonly type = DeviceActionTypes.DeviceStatusUpdated;
    constructor(public actionPrefix: string, public payload: {
        objs: BaseModel[],
        status: number
    }) { }
}

export class DeviceLogout implements MaytechBaseAction {
    readonly type = DeviceActionTypes.DeviceLogout;
    constructor(public actionPrefix: string, public payload: { obj: BaseModel }) { }
}

export class OneDeviceDeleted implements MaytechBaseAction {
    readonly type = DeviceActionTypes.OneDeviceDeleted;
    constructor(public actionPrefix: string, public payload: { id: number }) { }
}

export class ManyDevicesDeleted implements MaytechBaseAction {
    readonly type = DeviceActionTypes.ManyDevicesDeleted;
    constructor(public actionPrefix: string, public payload: { ids: number[] }) { }
}

export class DevicesPageRequested implements MaytechBaseAction {
    readonly type = DeviceActionTypes.DevicesPageRequested;
    constructor(public actionPrefix: string, public payload: { page: QueryParamsModel }) { }
}

export class DevicesPageLoaded implements MaytechBaseAction {
    readonly type = DeviceActionTypes.DevicesPageLoaded;
    constructor(public actionPrefix: string, public payload: { objs: BaseModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class DevicesPageCancelled implements MaytechBaseAction {
    readonly type = DeviceActionTypes.DevicesPageCancelled;
    constructor(public actionPrefix: string) { }

}

export class DevicesPageToggleLoading implements MaytechBaseAction {
    readonly type = DeviceActionTypes.DevicesPageToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export class DevicesActionToggleLoading implements MaytechBaseAction {
    readonly type = DeviceActionTypes.DevicesActionToggleLoading;
    constructor(public actionPrefix: string, public payload: { isLoading: boolean }) { }
}

export type DeviceActions = DeviceStatusUpdated
    | DeviceLogout
    | OneDeviceDeleted
    | ManyDevicesDeleted
    | DevicesPageRequested
    | DevicesPageLoaded
    | DevicesPageCancelled
    | DevicesPageToggleLoading
    | DevicesActionToggleLoading
    | RemoveAllDeviceStore;
