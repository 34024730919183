// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralSearchModel } from '../../../../../models/generalsearch.model';
import { ActivatedRoute } from '@angular/router';
import { GeneralSearchService } from '../../../../../core/theme';
import { QueryParamsModel } from '../../../../../core/_base/crud';

@Component({
	selector: 'kt-search-default',
	templateUrl: './search-default.component.html',
})
export class SearchDefaultComponent implements OnInit {
	// Public properties

	// Set icon class name
	@Input() icon: string = 'flaticon2-search-1';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    data: GeneralSearchModel[];
	loading: boolean;
   // private unsubscribe: Subject<any>;

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

    constructor(private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private http: HttpClient,
        private searchService: GeneralSearchService) {

        //this.unsubscribe = new Subject();
        //let clientId: string = this.route.snapshot.paramMap.get('tenant');
        //if (!clientId) {
        //    var parsedUrl = new URL(window.location.href);
        //    var baseUrl = parsedUrl.origin;
        //    clientId = baseUrl;
        //}
        //this.tenantService.getClient(clientId);
        //if (tenantService.currentClient != null) {
        //    this.API_URL = tenantService.currentClient.apiurl;
        //}

	}

	/**
	 * On init
	 */
	ngOnInit(): void {
	}

    setLoading(isLoading = true) {
        this.loading = isLoading;
    }
	/**
	 * Search
	 * @param e: Event
	 */
    search(e) {

        this.data = null;
        if (e.target.value.length > 2) {
            this.loading = true;
            const queryParams = new QueryParamsModel(JSON.stringify(e.target.value), "", "", 0, 10);
            this.searchService.findObjects(queryParams)
                .subscribe(

                    res=>{
                        if (res) {
                            if (res) {
                                this.data = res.items;
                            }
                        }
                        this.loading = false;
                        this.cdr.markForCheck();
                    },
                   err=>{
                       this.loading = false;
                       this.cdr.markForCheck();
                    }

            );
		}

        
    }
   
		//this.data = null;
		//if (e.target.value.length > 2) {
  //          this.loading = true;
  //          this.http.post<SearchModel[]>(this.API_URL + '/Search', JSON.stringify(e.target.value), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).pipe(
  //              tap(rs => {
  //                  if (rs) {
  //                      this.data = rs;
  //                  }
  //              }),
  //              takeUntil(this.unsubscribe),
  //              finalize(() => {
  //                  this.loading = false;
  //                  this.cdr.markForCheck();
  //              })
  //          ).subscribe();			
		//}
	//}

	/**
	 * Clear search
	 *
	 * @param e: Event
	 */
	clear(e) {
		this.data = null;
        this.searchInput.nativeElement.value = '';
        //this.unsubscribe.next();
        //this.unsubscribe.complete();
	}
}
