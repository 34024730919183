// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { GestureConfig, MatProgressSpinnerModule, MatFormFieldModule, MatInputModule, MatPaginatorIntl } from './material.module';
import { OverlayModule } from '@angular/cdk/overlay';
// Angular in memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// Hammer JS
import 'hammerjs';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/_reducers';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from "./views/theme/theme.module";
// Partials
import { PartialsModule } from './views/partials/partials.module';
// Layout Services
import {
    DataTableService,
    //FakeApiService,
    KtDialogService,
    LayoutConfigService,
    LayoutRefService,
    MenuAsideService,
    MenuConfigService,
    MenuHorizontalService,
    PageConfigService,
    SplashScreenService,
    SubheaderService
} from './core/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';
//import { RoleService } from './core/auth/_services/role.service';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/layout.config';
import { ColorPickerModule } from 'ngx-color-picker';
//Device Detector
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PaginatorIntlService } from './core/_base/layout/services/paginator-intl.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
//import { GestureConfig } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import localeVi from '@angular/common/locales/vi';
registerLocaleData(localeVi);

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
    // initialize app by loading default demo layout config
    return () => {
        if (appConfig.getConfig() === null) {
            appConfig.loadConfigs(new LayoutConfig().configs);
        }
    };
}

export function HttpTranslateLoaderFactory(http: HttpClient, tenantService: MaytechTenantService) {
    return new MaytechTranslationLoader(http, tenantService);
}

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { MaytechTranslationLoader } from './core/_maytech/maytech.translation.loader';
import { MaytechTenantService } from './core/_maytech/maytech.tenant.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatSidenavModule } from '@angular/material/sidenav';
import { registerLocaleData } from '@angular/common';
import { CanDeactivateGuard } from './core/_utils/can-deactivate-guard';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

export function initializeApp(appInitService: AuthService) {
    return (): Promise<any> => {
        return appInitService.loadPermissionOnInit();
    }
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        MatSidenavModule,
        BrowserModule,
        ColorPickerModule,
        MatFormFieldModule,
        MatInputModule,
        AppRoutingModule,
        HttpClientModule,
        //environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
        //    passThruUnknownUrl: true,
        //    dataEncapsulation: false
        //}) : [],
        NgxPermissionsModule.forRoot(),
        PartialsModule,
        CoreModule,
        OverlayModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        StoreDevtoolsModule.instrument(),
        AuthModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpTranslateLoaderFactory),
                deps: [HttpClient, MaytechTenantService]
            }
        }),
        MatProgressSpinnerModule,
        InlineSVGModule.forRoot(),
        ThemeModule,
        DeviceDetectorModule.forRoot(),
        NgxMaskModule.forRoot(options),
        NgMultiSelectDropDownModule.forRoot()
    ],
    exports: [],
    //exports: [ColorPickerModule],
    providers: [
        AuthService,
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthService], multi: true },
        LayoutConfigService,
        LayoutRefService,
        MenuConfigService,
        PageConfigService,
        KtDialogService,
        DataTableService,
        SplashScreenService,
        CanDeactivateGuard,
        MaytechTenantService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        //{
        //    provide: HAMMER_GESTURE_CONFIG,
        //    useClass: GestureConfig
        //},
        {
            provide: APP_INITIALIZER,
            useFactory: initializeLayoutConfig,
            deps: [LayoutConfigService], multi: true
        },
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new PaginatorIntlService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService]
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-EN'
            //useValue: 'vi-VN'
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        SubheaderService,
        MenuHorizontalService,
        MenuAsideService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
