// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../_base/crud';
// State
import { objectReducer, ObjectState } from './maytech.reducers';
import { BaseModel } from '../_base/crud';


export const selectObjectError = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => {
            return {
                errorMessage: objState.errorMessage,
                errorCode: objState.errorCode,
                objectType: objState.objectType,
                actionLoading: objState.actionsloading
            }
        }
    )
};

export const selectError = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => {
            return { errorMessage: objState.errorMessage, errorCode: objState.errorCode }
        }
    )
};

export const selectObjectById = (prefix: string, objId: number) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => objState.entities[objId]
    )
};

export const selectObjectsPageLoading = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => objState.listLoading
    )
};

export const selectObjectsActionLoading = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => objState.actionsloading
    )
};


export const selectObjectsPageLastQuery = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => objState.lastQuery
    )
};

export const selectLastCreatedObjectId = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => {
            return {
                id: objState.lastCreatedObjectId, httpAPICallType: objState.httpAPICallType
            };
        }
    )
};

export const selectLastUpdatedObjectId = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => {
            return {
                id: objState.lastUpdatedObjectId, httpAPICallType:objState.httpAPICallType
            };
        }
    )
};

export const selectLastDeletedObjectId = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => {
            return {
                id: objState.lastDeletedObjectId, httpAPICallType: objState.httpAPICallType
            };
        }
    )
};

export const selectObjectsInitWaitingMessage = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => objState.showInitWaitingMessage
    )
};

export const selectObjectsInStore =  (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        objState => {
            const items: BaseModel[] = [];
            each(objState.entities, element => {
                items.push(element);
            });
            const httpExtension = new HttpExtenstionsModel();
            const result: BaseModel[] = httpExtension.sortArray(items, objState.lastQuery != null ? objState.lastQuery.sortField : "_id", objState.lastQuery != null ? objState.lastQuery.sortOrder:"asc");
            return new QueryResultsModel(result, objState.totalCount, '');
        }
    )
};

export const selectHasObjectsInStore = (prefix: string) => {
    var selectObjectsState = createFeatureSelector<ObjectState<BaseModel>>(prefix);
    return createSelector(
        selectObjectsState,
        queryResult => {
            if (!queryResult.totalCount) {
                return false;
            }

            return true;
        }
    )
};
