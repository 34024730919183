import { BaseModel } from '../core/_base/crud';

export class CronJobLogModel extends BaseModel {
    cronJobId: number;
    cronJobLogId: number;
    runStatus: number;
    runDate: string;
    notes: string;

    clear() {
        this.cronJobId = 0;
        this.cronJobLogId = 0;
        this.runStatus = 0;
        this.runDate = '';
        this.notes = '';
    }

}
