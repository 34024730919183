// NGRX
import { createFeatureSelector, INIT } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { UserActions, UserActionTypes } from '../_actions/user.actions';
// CRUD
import { QueryParamsModel, BaseModel } from '../../_base/crud';
// Models
import { User } from '../../../models/user.model';
import { ObjectState } from '../../_maytech/maytech.reducers';
import { HttpAPICallType, ObjectType } from '../../_utils/define';

// tslint:disable-next-line:no-empty-interface
export interface UsersState extends ObjectState<BaseModel> {

}

export const adapter: EntityAdapter<BaseModel> = createEntityAdapter<BaseModel>({
    selectId: (obj: BaseModel) => obj._id,
});

export const initialUsersState: UsersState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel(''),
    lastCreatedObjectId: undefined,
    lastUpdatedObjectId: undefined,
    lastDeletedObjectId: undefined,
    showInitWaitingMessage: true,
    httpAPICallType: undefined,
    objectType: undefined,
    errorMessage: '',
    errorCode: 0
});

export function usersReducer(actionPrefix: string) {
    function theUserReducer(state = initialUsersState, action: UserActions): UsersState {
        switch (action.type) {
            case UserActionTypes.RemoveAllUserStore: {
                return adapter.removeAll(initialUsersState);
            };
            case UserActionTypes.UserErrorAction: return {
                ...state,
                listLoading: false,
                actionsloading: false,
                errorCode: action.payload.obj.errorCode,
                errorMessage: action.payload.obj.errorMessage,
                objectType: ObjectType.User
            };
            case UserActionTypes.ClearUserError: return {
                ...state,
                errorCode: undefined,
                errorMessage: ''
            };
            case UserActionTypes.UsersPageToggleLoading: return {
                ...state,
                listLoading: action.payload.isLoading,
                lastCreatedObjectId: undefined,
                lastUpdatedObjectId: undefined
            };
            case UserActionTypes.UsersActionToggleLoading: return {
                ...state,
                actionsloading: action.payload.isLoading
            };
            case UserActionTypes.UserOnServerCreated: return {
                ...state
            };
            case UserActionTypes.UserCreated: return adapter.addOne(<User>action.payload.obj, {
                ...state,
                lastCreatedObjectId: action.payload.obj._id,
                httpAPICallType: HttpAPICallType.Create,
                objectType: ObjectType.User,
                actionsloading: false,
                errorCode: undefined,
                errorMessage: ''
            });
            case UserActionTypes.UserOnServerUpdated: return {
                ...state
            };
            case UserActionTypes.UserUpdated: return adapter.updateOne(action.payload.partialUser, {
                ...state,
                lastUpdatedObjectId: action.payload.user._id,
                httpAPICallType: HttpAPICallType.Update,
                objectType: ObjectType.User,
                actionsloading: false
            });
            case UserActionTypes.UserDeleted: return adapter.removeOne(action.payload.id, {
                ...state,
                lastDeletedObjectId: action.payload.id,
                httpAPICallType: HttpAPICallType.Delete,
                objectType: ObjectType.User,
                listLoading: false,
                actionsloading: false
            });
            case UserActionTypes.UsersPageCancelled: return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel('')
            };
            case UserActionTypes.UsersPageLoaded: {
                return adapter.addMany(<User[]>action.payload.objs, {
                    ...initialUsersState,
                    totalCount: action.payload.totalCount,
                    listLoading: false,
                    lastQuery: action.payload.page,
                    showInitWaitingMessage: false,
                    httpAPICallType: HttpAPICallType.GetList,
                    actionsloading: false,
                    errorCode: undefined,
                    errorMessage: ''
                });
            }
            default: return state;
        }
    }
    return (state = initialUsersState, action: UserActions) => {
        return theUserReducer(state, action);
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
