// CRUD
import { QueryResultsModel, BaseDataSource } from '../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../_reducers';
// Selectors
import { selectObjectsInitWaitingMessage, selectObjectsInStore, selectObjectsPageLoading } from './maytech.selectors';

export class ObjectsDataSource extends BaseDataSource {
  
    constructor(private store: Store<AppState>, private prefix: string) {
		super();
		this.loading$ = this.store.pipe(
            select(selectObjectsPageLoading(this.prefix))
		);

		this.isPreloadTextViewed$ = this.store.pipe(
            select(selectObjectsInitWaitingMessage(this.prefix))
        );

        this.subscriptions.push(
            this.store.pipe(
                select(selectObjectsInStore(this.prefix))
            ).subscribe((response: QueryResultsModel) => {
                this.paginatorTotalSubject.next(response.totalCount);
                this.entitySubject.next(response.items);
            })
        );
	}
}
