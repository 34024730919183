import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable()
export class HelpEventService {

    @Output() showHelpContentEvent: EventEmitter<any> = new EventEmitter();

    showHelpContent(content: string, label: string = '') {
        this.showHelpContentEvent.emit({ content, label });
    }
}
