import { OnInit, Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { LayoutUtilsService, AlertMessageType, TypesUtilsService } from '../../../../core/_base/crud';
import { BookingInfoModel, BookingInfoService, EditRequestModel } from '../../../../core/order-to-cash';
import { ActivatedRoute, Router } from '@angular/router';
import { MaytechSimpleEditComponent } from '../../../../core/_maytech/maytech-simple-edit.component';
import { EditRequestService } from '../../../../core/setting';
import { EditRequestType, ResultMessageType } from '../../../../core/_utils/define';

@Component({
    selector: 'mt-edit-request-menu',
    templateUrl: './mt-edit-request-menu.component.html'
})

export class MtEditRequestMenuComponent implements OnInit {
    @Input() canRequestEdit: string = '';
    @Input() canApproveEdit: string = '';
    @Input() module: number;
    @Input() subModule: number;
    @Input() masterObjectId: number;
    @Input() fieldName: string = '';
    @Input() property: string = '';
    @Input() hasTranslate: boolean;
    @Input() displayText: string = '';
    @Input() inputType: number;
    @Input() curValue: any;
    @Input() listArray: string = '';
    @Input() objectType: number;
    @Input() objectId: number;
    @Input() data: any;
    @Output() approve = new EventEmitter<any[]>();
    hasPendingRequest: boolean = false;
    hasHistory: boolean = false;

    constructor(
        protected translate: TranslateService,
        protected router: Router,
        protected layoutUtilsService: LayoutUtilsService,
        public cdr: ChangeDetectorRef,
        public typesUtilsService: TypesUtilsService,
        protected editRequestService: EditRequestService) { }

    ngOnInit() {
        if (this.objectId) {
            this.editRequestService.hasPendingRequest(this.objectType, this.objectId, this.fieldName).toPromise().then(
                res => {
                    if (res) {
                        this.hasPendingRequest = true;
                        this.cdr.detectChanges();
                    }
                },
                err => {
                    this.onDataServiceError(err);
                }
            );
            this.editRequestService.hasHistory(this.objectType, this.objectId, this.fieldName).toPromise().then(
                res => {
                    if (res) {
                        this.hasHistory = true;
                        this.cdr.detectChanges();
                    }
                },
                err => {
                    this.onDataServiceError(err);
                }
            );
        }
    }

    showEditRequest() {
        //if (this.inputType == EditRequestType.PaymentDate) {
        //    console.log("this.curValue");
        //    console.log(this.curValue);
        //    this.curValue = new Date(this.data.curValue);
        //    console.log("new Date");
        //    console.log(this.curValue);
        //}
        //console.log("showEditRequest - data");
        //console.log(this.data);

        const dialogRef = this.layoutUtilsService.showRequestEditDialog({
            displayText: this.displayText,
            inputType: this.inputType,
            curValue: this.curValue,
            listArray: this.listArray
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                const model = new EditRequestModel();
                model.module = this.module;
                model.subModule = this.subModule;
                model.displayFieldName = this.displayText;
                model.hasTranslate = this.hasTranslate;
                model.masterObjectId = this.masterObjectId;
                model.link = this.router.url;
                model.fieldType = this.inputType;
                model.fieldName = this.fieldName;
                model.objectType = this.objectType;
                model.objectId = this.objectId;
                model.oldValue = res.curValue;
                model.newValue = res.newValue;
                model.notes = res.reasonNote;
                switch (this.inputType) {
                    case EditRequestType.PaymentDate, EditRequestType.Date, EditRequestType.DateTime:
                        model.oldValue = this.typesUtilsService.getMySQLDateTimeStringFromDate(res.oldValue)
                        model.newValue = this.typesUtilsService.getMySQLDateTimeStringFromDate(res.newValue)
                        break;
                    case EditRequestType.CheckBox:
                        model.oldValue = res.curValue ? '1' : '0';
                        model.newValue = res.newValue ? '1' : '0';
                        break;
                }

                this.editRequestService.createObject(model, false).toPromise().then(
                    res => {
                        if (res._id > 0) {
                            this.onRequestEditSuccess();
                            this.hasHistory = true;
                            this.hasPendingRequest = true;
                            this.cdr.detectChanges();
                        } else {
                            this.onDataServiceError({ status: 0, mesage: "Unknown error" });
                        }
                    },
                    err => {
                        this.onSubmitFail();
                        this.onDataServiceError(err);
                    }
                );
            }
        });
    }

    showEditRequestList() {
        const dialogRef = this.layoutUtilsService.showEditRequestListDialog({
            displayText: this.displayText,
            curValue: this.curValue,
            inputType: this.inputType,
            objectId: this.objectId,
            objectType: this.objectType,
            fieldName: this.fieldName
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (res.newValue != this.curValue) {
                    switch (this.inputType) {
                        case EditRequestType.PaymentDate, EditRequestType.Date, EditRequestType.DateTime:
                            this.approve.emit([this.data, new Date(res.newValue), this.property]);
                            break;
                        case EditRequestType.CheckBox:
                            this.approve.emit([this.data, (res.newValue == '1' ? true : false), this.property]);
                            break;
                        default:
                            this.approve.emit([this.data, res.newValue, this.property]);
                            break;
                    }
                }
                if (!res.hasPendingRequest) {
                    this.hasPendingRequest = false;
                }
                this.cdr.detectChanges();
                //this.onApproveRequestSuccess();
            }
        });
    }

    showEditRequestHistory() {
        const dialogRef = this.layoutUtilsService.showEditRequestHistoryDialog({
            displayText: this.displayText,
            curValue: this.curValue,
            inputType: this.inputType,
            objectId: this.objectId,
            objectType: this.objectType,
            fieldName: this.fieldName
        });
    }

    onDataServiceError(err) {
        this.showMessage(ResultMessageType.ERROR);
    }

    onRequestEditSuccess() {
        this.showMessage(ResultMessageType.REQUEST_EDIT_SUCCESS);
    }

    onApproveRequestSuccess() {
        this.showMessage(ResultMessageType.APPROVE_REQUEST_SUCCESS);
    }


    showMessage(resultMessage: ResultMessageType) {
        const message = this.getResultMessageTypeLabel(resultMessage);
        this.layoutUtilsService.showActionNotification(message, AlertMessageType.Update, 5000, true, true);
    }

    getResultMessageTypeLabel(resultMessageType: number): string {
        return this.translate.instant("ENUM.ResultMessageType." + ResultMessageType[(<ResultMessageType>resultMessageType)]);
    }

    onSubmitFail() {
        this.cdr.detectChanges();
    }
}
