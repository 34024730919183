import { FormControl, FormGroup } from '@angular/forms';
import { BaseModel } from '../core/_base/crud';
import { ObjectPriceGroupListModel } from './objectpricegrouplist.model';

export class BookingProductPriceModel extends BaseModel {
    bookingProductPriceId: number;
    bookingProductSessionId: number;
    label: string;
    labelEnglish: string;
    quantity: number;
    amount: number;
    currencyId: number;
    currencyName: string;
    objectPriceId: number;
    quantityUsed: number;
    subGroupId: number;
    minQuantity: number;
    maxQuantity: number;
    refPrices: ObjectPriceGroupListModel[];

    clear() {
        this.bookingProductPriceId = 0;
        this.bookingProductSessionId = 0;
        this.label = '';
        this.labelEnglish = '';
        this.quantity = 0;
        this.amount = 0;
        this.currencyId = 0;
        this.currencyName = '';
        this.objectPriceId = 0;
        this.quantityUsed = 0;
        this.subGroupId = 0;
        this.minQuantity = 0;
        this.maxQuantity = 0;
        this.refPrices = null;
    }

    static asFormGroup(model: BookingProductPriceModel): FormGroup {
        const fg = new FormGroup({
            bookingProductPriceId: new FormControl(model.bookingProductPriceId),
            objectPriceId: new FormControl(model.objectPriceId),
            label: new FormControl(model.label),
            quantity: new FormControl(model.quantity),
            amount: new FormControl(model.amount),
            quantityUsed: new FormControl(model.quantityUsed),
            labelEnglish: new FormControl(model.labelEnglish),
            subGroupId: new FormControl(model.subGroupId),
            minQuantity: new FormControl(model.minQuantity),
            maxQuantity: new FormControl(model.maxQuantity),
            currencyId: new FormControl(model.currencyId)
        });
        return fg;
    }
}
