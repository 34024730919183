// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/_reducers';
// Auth
import { AuthNoticeService, AuthService, Login, AllPermissionsRequested, User } from '../../../../core/auth';
import { MatStepper } from '@angular/material/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthModel } from '../../../../models/auth.model';
import { AllRolesRequested } from '../../../../core/auth/_actions/role.actions';
import { LayoutConfigService } from '../../../../core/_base/layout';

/**
 * ! Just example => Should be removed in development
 */

@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    styles: [`
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
    `],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
    // Public params
    loginForm: FormGroup;
    loginForm1: FormGroup;
    loading = false;
    isLoggedIn$: Observable<boolean>;
    errors: any = [];
    deviceInfo = null;
    otps = new Array(6);
    otpError: string;
    notAutoTab = false;

    otp = "";

    private unsubscribe: Subject<any>;

    private returnUrl: any;

    // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param router: Router
     * @param auth: AuthService
     * @param authNoticeService: AuthNoticeService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param fb: FormBuilder
     * @param cdr
     * @param route
     */
    constructor(
        private router: Router,
        private auth: AuthService,
        private authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private deviceService: DeviceDetectorService,
        private layoutConfigService: LayoutConfigService
    ) {
        this.unsubscribe = new Subject();
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.initEmailForm();
        // redirect back to the returnUrl before login
        this.route.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'] || '/';
        });
        //this.authNoticeService.setNotice('Đăng ký thành công. Vui lòng đăng nhập.', 'success');
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.authNoticeService.setNotice(null);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initEmailForm() {
        // demo message to show
        //if (!this.authNoticeService.onNoticeChanged$.getValue()) {
        //	const initialNotice = `Use account
        //	<strong>${DEMO_PARAMS.EMAIL}</strong> and password
        //	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
        //	this.authNoticeService.setNotice(initialNotice, 'info');
        //}

        this.loginForm = this.fb.group({
            email: ['', Validators.compose([
                Validators.required,
                Validators.email,
                Validators.minLength(3),
                Validators.maxLength(320)
            ])]
        });
    }

    checkemail(stepper: MatStepper) {
        if (!this.loading) {
            this.authNoticeService.setNotice(null);
            const controls = this.loginForm.controls;
            /** check form */
            if (this.loginForm.invalid) {
                Object.keys(controls).forEach(controlName =>
                    controls[controlName].markAsTouched()
                );
                return;
            }
            this.loading = true;
            const loginUser = new User();
            loginUser.userEmail = controls['email'].value;
            this.auth.checkEmail(loginUser).pipe(
                tap(user => {
                    if (user) {
                        stepper.next();
                        //console.log('OTP: ' + user.userPassword);
                    } else {
                        controls['email'].setErrors({
                            serverError: this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN')
                        });
                    }
                }),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            ).subscribe();
        }
    }

    /**
     * Form Submit
     */
    submit() {
        if (!this.loading) {
            //if (this.otps.join("").length != 6) {
            //    this.otpError = this.translate.instant('AUTH.VALIDATION.OTP_REQUIRED');
            //    return;
            //}
            /** check form */
            if (!this.otp || this.otp.length != 6) {
                this.otpError = this.translate.instant('AUTH.VALIDATION.OTP_REQUIRED');
                return;
            }
            const formControls = this.loginForm.controls;
            if (this.loginForm.invalid) {
                Object.keys(formControls).forEach(controlName =>
                    formControls[controlName].markAsTouched()
                );
                return;
            }

            this.loading = true;

            const authData = {
                email: formControls['email'].value,
                password: this.otp
            };

            const authModel = new AuthModel();
            authModel.userEmail = authData.email;
            authModel.userPassword = authData.password;
            authModel.userAgent = this.deviceService.getDeviceInfo().userAgent;
            //this.auth.getIPAddress().toPromise().then((res: any) => {
            //    if (res) {
            //        authModel.ipAddress = res.ip;
            //    } else {
            //        authModel.ipAddress = 'N/A';
            //    }
            this.auth.login(authModel).pipe(
                tap(user => {
                    if (user) {
                        if (!user.isExpired && user.token != null) {
                            this.layoutConfigService.resetConfig();
                            this.store.dispatch(new Login({ authToken: user.token }));
                            this.router.navigateByUrl(this.returnUrl);
                        }
                        else {
                            this.otpError = this.translate.instant('AUTH.VALIDATION.OTP_EXPIRED');
                        }
                    } else {
                        this.otpError = this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN');
                    }
                }),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            ).subscribe();
            //}).catch(err => {
            //    console.log(err);
            //    this.loading = false;
            //    this.otpError = this.translate.instant('GENERAL.ERROR');
            //    this.cdr.markForCheck();
            //});
        }
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.loginForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        if (result) {
            var keyNames = Object.keys(control.errors);
            if (keyNames[0] != validationType) {
                return false;
            }
        }
        return result;
    }

    keyDownEvent(e) {
        this.notAutoTab = false;
        this.otpError = '';
        if ([46, 8].indexOf(e.keyCode) !== -1) {
            this.notAutoTab = true;
        }

        if (e.keyCode === 13) {
            let btElm: HTMLElement = document.getElementById('kt_login_signin_submit') as HTMLElement;
            btElm.click();
        }

        if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            return;
        }
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    }

    onInputEntry(e, id, nextInputIndex) {
        let nexInput = +nextInputIndex + 1;
        let newID = id + nexInput;
        if (document.getElementById(newID) && !this.notAutoTab)
            document.getElementById(newID).focus();
        if (this.notAutoTab && nextInputIndex > 0) {
            let prevInput = +nextInputIndex - 1;
            let prevID = id + prevInput;
            if (document.getElementById(prevID))
                document.getElementById(prevID).focus();
        }
    }

    onPaste(e: ClipboardEvent, id, index) {
        e.preventDefault();
        let clipboardData = e.clipboardData;
        let pastedText = clipboardData.getData('text').trim();
        if (pastedText.length > 0 && index == 0) {
            let pastedTextArr = [...pastedText];
            for (var i = 0; i < this.otps.length; i++) {
                this.otps[i] = pastedTextArr[i];
                if (i == this.otps.length - 1) {
                    let nextInput = +i;
                    let nextID = id + nextInput;
                    if (document.getElementById(nextID)) {
                        document.getElementById(nextID).focus();
                        if (pastedTextArr.length == this.otps.length)
                            this.submit();
                    }
                    break;
                }
            }
        }
    }

    trackByIndex(index) {
        return index;
    }

    retry(stepper: MatStepper) {
        this.otpError = '';
        this.otp = '';
        this.otps = new Array(6);
        stepper.previous();
    }
}
