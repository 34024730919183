import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, BaseModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Real REST API
@Injectable()
export class SupportTicketService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, null, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/supportticket';
    }


    getTicketComment(supportTicketId: number): Observable<any> {
        const url = this.API_URL + "/GetTicketComment/" + supportTicketId.toString();

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<any>(url, { headers: httpHeaders })
            .pipe(catchError(this.handleError("getTicketComment", []))
            );
    }
    
    updateTicketComment(ticketComments: BaseModel[], supportTicketId: number): Observable<any> {
        const url = this.API_URL + "/UpdateTicketComment/" + supportTicketId.toString();

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.put<any>(url, ticketComments, { headers: httpHeaders })
            .pipe(catchError(this.handleError("updateTicketComment", []))
            );
    }

    deleteTicketComment(ticketCommentDelete: BaseModel[], supportTicketId: number): Observable<any> {
        const url = this.API_URL + "/DeleteTicketComment/" + supportTicketId.toString();

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.put<any>(url, ticketCommentDelete, { headers: httpHeaders })
            .pipe(catchError(this.handleError("DeleteTicketComment", []))
            );
    }


}
