import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TypesUtilsService } from '../../../../core/_base/crud';
import { ComboItem, TimeUnit } from '../../../../core/_utils/define';

@Component({
    selector: 'mt-filter-remain-time',
    templateUrl: './mt-filter-remain-time.component.html',
    styleUrls: ['./mt-filter-remain-time.component.scss']
})

export class MtFilterRemainTimeComponent {
    @ViewChild('selectionList', { static: true }) selectionList: MatSelectionList;
    @ViewChild(NgbDropdown)
    dropdown: NgbDropdown;

    @Input() mtModel: any[];
    @Input() icon: string = 'schedule';
    @Input() iconPos: string = 'right';
    @Input() label: string = '';
    @Input() colorStyle: string = '';
    @Input() placement: string = 'bottom-left';
    @Input() multiple: boolean = true;
    @Input() labelToggle: boolean = false;
    @Input() hideCancelFilter: boolean = true;
    @Input() hideCancel: boolean = false;
    @Input() hideSelectAll: boolean = true;


    @Output() change = new EventEmitter<any[]>();
    @Output() mtModelChange = new EventEmitter<any[]>();

    remainTime = 1;
    remainTimeUnit = 2;
    timeUnitList: ComboItem[] = [];
    timeOptionList = [12, 24, 48];
    selectedLabel = '';
    selectedTime = -1;
    selectedTimeUnit = -1;

    constructor(protected translate: TranslateService, private cdr: ChangeDetectorRef) {
        var timeUnits = Object.keys(TimeUnit)
            .map(k => TimeUnit[k])
            .filter(v => typeof v === "number") as number[];
        for (let item of timeUnits) {
            this.timeUnitList.push({ Value: item, Text: this.getTimeUnitLabel(item) });
        }
    }

    getTimeUnitLabel(timeUnit: number): string {
        return this.translate.instant("ENUM.TimeUnit." + TimeUnit[(<TimeUnit>timeUnit)]);
    }

    cancelFilter() {
        this.selectedTimeUnit = -1;
        this.selectedTime = -1;
        this.selectedLabel = '';
        this.mtModel = [-1];
        this.mtModelChange.emit(this.mtModel);
        this.change.emit(this.mtModel);
        this.dropdown.toggle();
    }

    onOptionChange(selectValue) {
        //if (selectValue != '0') {
        //    this.applyFilter();
        //}
        //this.selectedOption = selectValue;
    }

    dropdownOpenChange(event) {
        if (event) {
            //this.focusOption = this.selectedOption;
        }
    }

    applyFilter(isInput = false) {
        //this.selectedOption = this.focusOption;
        //console.log("check");
        if (isInput) {
            this.selectedTime = this.remainTime;
            this.selectedTimeUnit = Number(this.remainTimeUnit);
        }
        switch (this.selectedTimeUnit) {
            case TimeUnit.Minute:
                this.mtModel = [this.selectedTime];
                break;
            case TimeUnit.Hour:
                this.mtModel = [this.selectedTime * 60];
                break;
            case TimeUnit.Day:
                this.mtModel = [this.selectedTime * 24 * 60];
                break;
        }
        this.mtModelChange.emit(this.mtModel);
        this.change.emit(this.mtModel);
        this.dropdown.toggle();
    }

    selectOption(hours) {
        this.selectedTime = hours;
        this.selectedTimeUnit = TimeUnit.Hour;
        this.applyFilter();
    }

    getSelectedLabel() {
        if (this.selectedTime > 0) {
            switch (this.selectedTimeUnit) {
                case TimeUnit.Minute:
                    this.selectedLabel = `<font color="primary">${this.translate.instant("ORDER_TO_CASH.BOOKING.WAITING_TIME_FILTER_LESS_THAN")} ${this.selectedTime} ${this.translate.instant("ORDER_TO_CASH.BOOKING.WAITING_TIME_MINUTE")}</font>`;
                    break;
                case TimeUnit.Hour:
                    this.selectedLabel = `<font color="primary">${this.translate.instant("ORDER_TO_CASH.BOOKING.WAITING_TIME_FILTER_LESS_THAN")} ${this.selectedTime} ${this.translate.instant("ORDER_TO_CASH.BOOKING.WAITING_TIME_HOUR")}</font>`;
                    break;
                case TimeUnit.Day:
                    this.selectedLabel = `<font color="primary">${this.translate.instant("ORDER_TO_CASH.BOOKING.WAITING_TIME_FILTER_LESS_THAN")} ${this.selectedTime} ${this.translate.instant("ORDER_TO_CASH.BOOKING.WAITING_TIME_DAY")}</font>`;
                    break;
            }
        } else {
            this.selectedLabel = '';
        }
        return `${this.label}: <font color="primary">${this.selectedLabel}</font>`;
    }

}