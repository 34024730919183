import { BaseModel, QueryParamsModel } from '../../_base/crud';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';

export enum BookingActionTypes {
    BookingPageLoaded = '[Booking] Booking Page Loaded',
    BookingStatusUpdated = '[Booking] Booking Status Updated',
    BookingWarningStatusUpdated = '[Booking] Booking Warning Status Updated',
    OneBookingDeleted = '[Booking] One Booking Deleted',
    BookingRepresentorUpdated = '[Booking] Booking Representor Updated',
    BookingSellerUpdated = '[Booking] Booking Seller Updated'
}

export class BookingPageLoaded implements MaytechBaseAction {
    readonly type = BookingActionTypes.BookingPageLoaded;
    constructor(public actionPrefix: string, public payload: { objs: BaseModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class BookingStatusUpdated implements MaytechBaseAction {
    readonly type = BookingActionTypes.BookingStatusUpdated;
    constructor(public actionPrefix: string, public payload: {
        obj: BaseModel,
        status: number
    }) { }
}

export class BookingWarningStatusUpdated implements MaytechBaseAction {
    readonly type = BookingActionTypes.BookingWarningStatusUpdated;
    constructor(public actionPrefix: string, public payload: {
        obj: BaseModel,
        status: number
    }) { }
}

export class OneBookingDeleted implements MaytechBaseAction {
    readonly type = BookingActionTypes.OneBookingDeleted;
    constructor(public actionPrefix: string, public payload: { id: number }) { }
}

export class BookingRepresentorUpdated implements MaytechBaseAction {
    readonly type = BookingActionTypes.BookingRepresentorUpdated;
    constructor(public actionPrefix: string, public payload: {
        obj: BaseModel,
        representedUser: string
    }) { }
}

export class BookingSellerUpdated implements MaytechBaseAction {
    readonly type = BookingActionTypes.BookingSellerUpdated;
    constructor(public actionPrefix: string, public payload: {
        obj: BaseModel,
        sellerUser: string
    }) { }
}

export type BookingActions =
    BookingPageLoaded
    | BookingStatusUpdated
    | BookingWarningStatusUpdated
    | OneBookingDeleted
    | BookingRepresentorUpdated
    | BookingSellerUpdated;
