import { BaseModel } from '../core/_base/crud';


export class TenantModel extends BaseModel {
    tenantId: number;
    tenantStatus: number;
    tenantName: string;
    tenantEmail: string;
    tenantFullName: string;
    permissions: number[];
    createdDate: string;
    createdBy: number;
    updatedDate: string;
    updatedBy: number;

    clear(): void {
        this.tenantId = 0;
        this.tenantEmail = '';
        this.tenantFullName = '';
        this.tenantStatus=0;
        this.tenantName = '';
        this.permissions = [];
        this.createdDate = '';
        this.createdBy = 0;
        this.updatedDate = '';
        this.updatedBy = 0;
    }
}
