import { BaseModel } from '../core/_base/crud';


export class RecentActionModel extends BaseModel {
    objectId: number;
    date: string;
    order: string;
    customerName: string;
    userName: string;
    productName: string;
    actionType: number;
    objectType: number;
    bookingId: number;
    customerId: number;
    bookingSource: number;
    clear(): void {
        this.bookingSource = 0;
        this.bookingId = 0;
        this.customerId = 0;
        this.objectId = 0;
        this.actionType = 0;
        this.objectType = 0;
        this.date = '';
        this.order = '';
        this.customerName = '';
        this.userName = '';
        this.productName = '';
    }
}
