import { Injectable } from '@angular/core';
import { Client } from '../../models/client.model';
import clientsData from '../../../assets/clients.json'

@Injectable({
    providedIn: 'root'
})
export class MaytechTenantService {
    private clients: Client[] = clientsData.clients;
    public currentClient: Client;
    constructor() {
    }

    getName(): string {
        return 'MaytechVN';
    }
    getClient(id: string): Client {
        if (!this.currentClient) {
            this.currentClient = this.clients.find(x => x.id.toLowerCase() == id.toLowerCase());
        }
        return this.currentClient;
    }
}
